@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Strength
========================================================== */
/* box-strength-intro
---------------------------------------------------------- */
.box-strength-intro {
  margin-bottom: 60px;
  padding: 60px 130px;
  background-image: url('/img/strength/bg_strength_intro.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include mq-sp {
    margin-bottom: 35px;
    padding: 15px;
  }

  .inner {
    .bar-top {
      position: relative;
      padding-left: 40px;
      top: 1px;

      @include mq-sp {
        padding-left: 20px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 41px solid transparent; 
        border-right: 41px solid #fff;

        @include mq-sp {
          border-top-width: 21px;
          border-right-width: 21px;
        }
      }

      span {
        display: block;
        height: 41px;
        background-color: #fff;

        @include mq-sp {
          height: 21px;
        }
      }
    }

    .bar-bottom {
      position: relative;
      padding-right: 40px;
      bottom: 1px;

      @include mq-sp {
        padding-right: 20px;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom: 41px solid transparent; 
        border-left: 41px solid #fff;

        @include mq-sp {
          border-bottom-width: 21px;
          border-left-width: 21px;
        }
      }

      span {
        display: block;
        height: 41px;
        background-color: #fff;

        @include mq-sp {
          height: 21px;
        }
      }
    }
  }

  .cont {
    padding: 15px 50px;
    background-color: #fff;
    transform: translateZ(0);

    @include mq-sp {
      padding: 5px 10px;
    }
  }

  .title {
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: 500;
    color: #000;
    line-height: 1.7;
    text-align: center;
    letter-spacing: -0.01em;

    @include mq-sp {
      margin-bottom: 15px;
      font-size: 1.6rem;
      line-height: 1.7;
      letter-spacing: -0.02em;
    }

    @include mq-mx(360) {
      font-size: 1.5rem;
    }
  }

  .text {
    text-align: center;

    @include mq-sp {
      padding: 0 5px;
      font-size: 1.2rem;
      text-align: left;
    }
  }
}

/* sec-strength
---------------------------------------------------------- */
.sec-strength {
  margin-bottom: 80px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 30px;
  }

  .box-detail {
    position: relative;
    z-index: 1;

    @include mq-pc {
      width: 600px;
    }

    @include mq-sp {
      margin-bottom: 10px;
    }

    .title {
      margin-bottom: 30px;

      img {
        max-width: 100%;
      }

      @include mq-sp {
        margin-bottom: 10px;
      }
    }

    .text {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .image {
    position: relative;

    @include mq-pc {
      width: 640px;
      padding-top: 30px;
    }

    @include mq-sp {
      .button {
        margin-top: -20px;
        padding: 0 20px;
      }
    }

    img {
      width: 100%;
    }
  }

  // -imageright
  &.-imageright {
    @include mq-pc {
      .box-detail {
        float: left;

        .text {
          padding-right: 290px;
        }
        
        .button {
          padding-right: 290px;
        }
      }

      .image {
        float: right;
        margin-left: -260px;
      }
    }
  }

  // -imageleft
  &.-imageleft {
    @include mq-pc {
      .box-detail {
        float: right;

        .title {
          text-align: right;
        }

        .text {
          padding-left: 290px;
        }
      }
      
      .image {
        float: left;
        margin-right: -260px;
      }
    }
  }
}
