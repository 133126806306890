@import '../../_variables.scss';
@import '../../_mixin.scss';

input, textarea, select, button {
  font-family: "Noto Sans JP","ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

/* ==========================================================
 form component
========================================================== */
/* c-input-text
---------------------------------------------------------- */
.c-input-text {
  display: block;
  width: 452px;
  max-width: 100%;
  height: 30px;
  padding-left: 10px;
  border: 0;
  border-radius: 5px;
  background-color: #f4f4f4;
  box-shadow: inset 0 1px rgba(0,0,0,.1);
  font-size: 1.2rem;
  line-height: 30px;
  -webkit-appearance: none;

  &.-error {
    background-color: #ffeded;
  }

  @include mq-sp {
    width: 100%;
  }
}

/* c-select
---------------------------------------------------------- */
.c-select {
  position: relative;
  width: 256px;

  @include mq-sp {
    width: 100%;
  }

  .box-select {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    border-radius: 5px;
    background-color: #f4f4f4;
    box-shadow: inset 0 1px rgba(0,0,0,.1);
    font-size: 1.2rem;
    line-height: 2.3;

    &:before {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 8px solid #333;
      top: 50%;
      right: 10px;
      margin-top: -4px;
    }
  }

  select {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: 30px;
    opacity: 0;
  }
}

/* c-radio
---------------------------------------------------------- */

/* c-checkbox
---------------------------------------------------------- */

/* c-textarea
---------------------------------------------------------- */
.c-textarea {
  display: block;
  width: 596px;
  max-width: 100%;
  height: 200px;
  padding-left: 10px;
  border: 0;
  border-radius: 5px;
  background-color: #f4f4f4;
  box-shadow: inset 0 1px rgba(0,0,0,.1);
  font-size: 1.2rem;
  line-height: 30px;
  -webkit-appearance: none;
  text-align: left;

  &.-error {
    background-color: #ffeded;
  }
}

/* ==========================================================
 form layout
========================================================== */
/* c-form-layout
---------------------------------------------------------- */
.c-form-layout {
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;

    @include mq-sp {
      display: block;
      padding-bottom: 20px;
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 60px;

      @include mq-sp {
        margin-bottom: 25px;
      }
    }

    .title {
      font-weight: 700;

      @include mq-pc {
        flex: 0 0 220px;
      }

      @include mq-sp {
        margin-bottom: 8px;
      }
    }

    .detail {
      @include mq-pc {
        flex: 1 1 auto;
      }
    }
  }

  .btn-group {
    display: flex;
    justify-content: center;

    @include mq-sp {
      display: block;
    }

    .item {
      padding: 0 20px;

      @include mq-sp {
        padding: 0;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .c-btn {
      @include mq-sp {
        width: 100%;
      }
    }
  }

  .box-name {
    display: flex;

    @include mq-sp {
      display: block;
    }

    .col {
      width: 215px;
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }

      @include mq-sp {
        width: 100%;
        margin-left: 0;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      input {
        width: 100%;
      }
    }
  }

  .txt-error {
    display: block;
    padding-top: 15px;
    color: #d50000;
    line-height: 1;

    @include mq-sp {
      padding-top: 10px;
    }

    + .txt-error {
      display: none;
    }
  }
}

/* box-post
---------------------------------------------------------- */
.box-post {
  display: flex;
  flex-wrap: wrap;

  @include mq-sp {
    margin: 0 -15px;
  }

  .col {
    position: relative;

    @include mq-pc {
      &:nth-child(1) {
        width: 85px;
        margin-right: 40px;

        &:after {
          content: "–";
          position: absolute;
          top: 50%;
          right: -23px;
          transform: translateY(-50%);
          color: #000;
        }
      }

      &:nth-child(2) {
        width: 95px;
        margin-right: 20px;
      }

      &:nth-child(3) {
        width: 115px;

        .c-btn {
          min-width: 100%;
          padding: 2px;
        }
      }
    }

    @include mq-sp {
      &:nth-child(1) {
        width: 50%;
        padding: 0 15px;

        &:after {
          content: "–";
          position: absolute;
          top: 50%;
          right: -3px;
          transform: translateY(-50%);
          color: #000;
        }
      }

      &:nth-child(2) {
        width: 50%;
        padding: 0 15px;
      }

      &:nth-child(3) {
        width: 100%;
        margin-top: 15px;
        padding: 0 15px;

        .c-btn {
          width: 100%;
          min-width: 100%;
        }
      }
    }
  }
}