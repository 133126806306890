@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 heading
========================================================== */
/* c-hdg-box
---------------------------------------------------------- */
.c-hdg-box {
  position: relative;
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 45px;
  }

  .bg {
    img {
      width: 100%;
    }
  }

  .title {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    font-family: $yumin;
    font-size: 3.6rem;
    font-weight: bold;
    color: #fff;
    line-height: 1.2;

    &.-black {
      color: #000;
    }

    small {
      font-size: 3rem;
    }

    @include mq-sp {
      left: 25px;
      font-size: 1.8rem;

      small {
        font-size: 1.5rem;
      }
    }
  }
}

/* c-hdg-01
---------------------------------------------------------- */
.c-hdg-01 {
  margin-bottom: 40px;
  font-family: $yumin;
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1.3;

  small {
    font-size: 2.4rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 20px;
    font-size: 2.4rem;

    small {
      font-size: 1.6rem;
    }
  }
}

/* c-hdg-02
---------------------------------------------------------- */
.c-hdg-02 {
  padding: 8px 14px;
  margin-bottom: 40px;
  background: #009dd3 url('/img/common/bg_title.jpg') no-repeat right center;
  background-size: 242px auto;
  font-family: $yumin;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.4;
  color: #fff;
  letter-spacing: -0.01em;

  @include mq-sp {
    margin-bottom: 30px;
    padding: 5px 10px;
    background-size: 121px auto;
    font-size: 1.8rem;
  }
}

/* c-hdg-03
---------------------------------------------------------- */
.c-hdg-03 {
  margin-bottom: 25px;
  font-family: $yumin;
  font-size: 2.6rem;
  line-height: 1.33;
  letter-spacing: -1px;
  font-weight: bold;

  @include mq-sp {
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
}

/* c-hdg-04
---------------------------------------------------------- */
.c-hdg-04 {
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #7ab9cf;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.22;

  &.-noborder {
    padding-bottom: 0;
    border-bottom: 0;
  }

  @include mq-sp {
    padding-bottom: 10px;
    font-size: 1.6rem;
  }
}

