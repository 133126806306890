@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Case
========================================================== */
/* box-case-info
---------------------------------------------------------- */
.box-case-info {
  position: relative;
  min-height: 305px;
  padding: 35px 30px 30px;
  border: 1px solid #ccc;
  border-top: 0;
  margin-bottom: 40px;
  overflow: hidden;

  @include mq-sp {
    padding: 25px 0 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 6px;
    background-color: $color-primary;

    @include mq-sp {
      height: 5px;
    }
  }

  .detail {
    @include mq-pc {
      width: 510px;
    }

    @include mq-sp {
      padding: 0 20px;
      margin-bottom: 15px;
    }

    .title {
      margin-bottom: 10px;
      font-size: 2.4rem;

      @include mq-sp {
        font-size: 1.8rem;
      }
    }
  
    .desc {
      margin-bottom: 25px;

      @include mq-sp {
        margin-bottom: 20px;
      }
    }

    .btn-group {
      display: flex;
      margin-bottom: 20px;

      @include mq-sp {
        display: block;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .button {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        @include mq-pc {
          width: 220px;
        }

        @include mq-sp {
          width: 100%;
          margin-left: 0;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .image {
    text-align: right;

    @include mq-pc {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    img {
      max-width: 100%;
    }

    &.-center {
      text-align: center;
    }
  }
}