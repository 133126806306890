@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Sitemap
========================================================== */
/* list-sitemap
---------------------------------------------------------- */
.list-sitemap {
  .item {
    padding-bottom: 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid #ccc;

    @include mq-sp {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    a {
      position: relative;
      padding-left: 12px;
      color: #666;
      transition: opacity .2s;

      &:hover {
        opacity: 0.7;
      }

      @include mq-pc {
      }

      &:before {
        content: "";
        position: absolute;
        top: 7px;
        left: 0;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 6px solid #199ed1;
      }
    }
  }

  >.item:last-child {
    margin-bottom: 0;

    @include mq-sp {
      padding-bottom: 12px;
    }
  }

  .box-sub {
    .list-sitemap {
      .item {
        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }

    @include mq-pc {
      display: flex;

      .link {
        flex: 0 0 170px;
      }

      .list-sub {
        flex: 1 1 auto;
      }
    }

    @include mq-sp {
      .link {
        padding-bottom: 10px;
      }

      .list-sub {
        padding-left: 20px;
      }
    }
  }

  // -level3
  &.-level3 {
    .item {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;

      @include mq-sp {
        margin-bottom: 3px;
      }
    }
  }
}