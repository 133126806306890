@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Contribution
========================================================== */
/* box-contribution-map
---------------------------------------------------------- */
.box-contribution-map {
  height: 500px;

  @include mq-sp {
    height: 300px;
  }
}

.card-infowindow {
  width: 300px;

  .image {
    margin-bottom: 12px;

    img {
      width: 100%;
    }
  }

  .title {
    margin-bottom: 5px;
    font-weight: 700;
  }
}