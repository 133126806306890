@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 section
========================================================== */
/* c-sec-parts
---------------------------------------------------------- */
.c-sec-parts {
  position: relative;
  margin-bottom: 50px;

  .heading-part {
    padding: 5px 30px;
    margin-bottom: 20px;
    background-color: #555;
    color: #fff;

    @include mq-sp {
      margin-bottom: 15px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 40px;
  }
}

/* c-sec-content
---------------------------------------------------------- */
.c-sec-content {
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 40px;
  }
}


