@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 list
========================================================== */
/* c-list-images
---------------------------------------------------------- */
.c-list-images {
  display: flex;
  flex-wrap: wrap;
  margin: -40px -20px 40px;

  &:last-child {
    margin-bottom: 0;
  }
  
  @include mq-sp {
    margin: -16px -7px 16px;
  }

  .item {
    width: 50%;
    margin-top: 40px;
    padding: 0 20px;

    @include mq-sp {
      margin-top: 16px;
      padding: 0 7px;
    }

    img {
      max-width: 100%;
    }

    &.-full {
      width: 100%;
    }

    @include mq-sp {
      &.-fullsp {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }

  .title {
    display: flex;
    margin-bottom: 10px;
    line-height: 1.3;
  }
}

/* c-list-imagesflex
---------------------------------------------------------- */
.c-list-imagesflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -30px -20px 30px;

  &:last-child {
    margin-bottom: 0;
  }
  
  @include mq-sp {
    margin: -16px -7px 16px;
  }

  .item {
    margin-top: 30px;
    padding: 0 20px;

    @include mq-sp {
      margin-top: 16px;
      padding: 0 7px;

      &.-col6sp {
        width: 50%;

        img {
          width: 100%;
        }
      }

      &.-fullsp {
        width: 100%;

        .c-box-image {
          width: 100%;
        }
        
        img {
          width: 100%;
        }
      }
    }
  }

  .c-box-image {
    display: table;
    width: 100%;

    .caption {
      display: table-caption;
      caption-side: bottom;
    }
  }
}

/* c-list-bullet
---------------------------------------------------------- */
.c-list-bullet {
  .item {
    position: relative;
    padding-left: 12px;

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #333;
    }
  }
}