/* ==========================================================
 fonts
========================================================== */
//@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700');
@import url('https://fonts.googleapis.com/earlyaccess/notosansjapanese.css');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700');


/* ==========================================================
 base
========================================================== */
@import './common/base/reset.scss';
@import './common/base/layout.scss';

/* ==========================================================
 components
========================================================== */
@import './common/components/header.scss';
@import './common/components/navglobal.scss';
@import './common/components/footer.scss';
@import './common/components/heading.scss';
@import './common/components/text.scss';
@import './common/components/link.scss';
@import './common/components/button.scss';
@import './common/components/list.scss';
@import './common/components/table.scss';
@import './common/components/box.scss';
@import './common/components/form.scss';
@import './common/components/section.scss';
@import './common/components/mainvisual.scss';
@import './common/components/module.scss';

/* ==========================================================
 pages
========================================================== */
@import './pages/top.scss';
@import './pages/strength.scss';
@import './pages/products.scss';
@import './pages/case.scss';
@import './pages/company.scss';
@import './pages/sitemap.scss';
@import './pages/contribution.scss';

/* ==========================================================
 utils
========================================================== */
@import './common/utils/display.scss';
@import './common/utils/flex.scss';
@import './common/utils/spacing.scss';
@import './common/utils/text-style.scss';