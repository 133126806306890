@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 box
========================================================== */
/* c-box-image
---------------------------------------------------------- */
.c-box-image {
  margin-bottom: 40px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 30px;
  }

  img {
    max-width: 100%;
  }

  .caption {
    margin-top: 12px;
    text-align: left;

    &.-center {
      text-align: center;
    }

    &.-right {
      text-align: right;
    }

    @include mq-sp {
      margin-top: 5px;
      font-size: 1.2rem;
    }
  }
}

/* c-box-card
---------------------------------------------------------- */
.c-box-card {
  overflow: hidden;
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 30px;
  }

  .image {
    img {
      width: 100%;
    }

    @include mq-pc {
      float: left;
      width: 470px;
      margin-right: 40px;
      padding-top: 8px;
    }

    @include mq-sp {
      margin-bottom: 15px;
    }

    .caption {
      margin-top: 12px;

      @include mq-sp {
        margin-top: 5px;
        font-size: 1.2rem;
      }
    }
  }

  .detail {
    overflow: hidden;

    .text {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      @include mq-sp {
        margin-bottom: 15px;
        font-size: 1.2rem;
      }
    }

    .c-table-basic {
      margin-bottom: 30px;

      @include mq-sp {
        margin-bottom: 25px;
      }

      table {
        @include mq-pc {
          th {
            width: 95px;
            padding: 10px;

            @include mq-pc {
              text-align: center;
            }
          }

          td {
            padding: 10px 15px;
          }
        }
      }
    }

    .button {
      @include mq-sp {
        display: block;
        width: 100%;
      }
    }
  }
}

/* c-box-article
---------------------------------------------------------- */
.c-box-article {
  padding: 15px 25px 22px 28px;
  background-color: #d5e3e9;
  border-radius: 5px;

  @include mq-sp {
    padding: 15px 20px;
    border-radius: 3px;
    font-size: 1.5rem;
  } 

  .title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 1.6rem;

    @include mq-sp {
      font-size: 1.5rem;
    }
  }
}

/* c-box-contact
---------------------------------------------------------- */
.c-box-contact {
  margin-bottom: 55px;
  padding: 45px 15px 50px;
  background-color: #d5e3e9;
  border-radius: 5px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 30px;
    padding: 25px 17px 30px;
  }

  .intro {
    margin-bottom: 20px;
    font-size: 1.6rem;
    line-height: 2;
    
    @include mq-sp {
      font-size: 1.4rem;
      line-height: 1.78;
    }
  }

  .box-tel {
    display: inline-block;
    padding-left: 48px;
    margin: 3px 0 20px;
    background-image: url('/img/common/ico_tel_01.png');
    background-position: 0 center;
    background-repeat: no-repeat;
    color: #009dd3;
    text-align: left;

    @include mq-sp {
      padding-left: 35px;
      background-size: 25px auto;
    }

    .number {
      display: block;
      margin-bottom: 6px;
      font-family: $roboto;
      font-size: 3.7rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.08em;

      @include mq-sp {
        font-size: 3rem;
        letter-spacing: 0.04em;
      }
    }

    .time {
      display: block;
      font-size: 1.6rem;
      line-height: 1.5;
      letter-spacing: 0.08em;

      @include mq-sp {
        font-size: 1.4rem;
      }
    }
  }

  .button {
    display: inline-block;
    position: relative;
    width: 375px;
    min-width: 235px;
    padding: 11px 30px;
    background-color: #009dd3;
    box-shadow: inset 0 -1px rgba(0,0,0,.35);
    border-radius: 50px;
    font-size: 1.8rem;
    color: #fff;
    line-height: 1.5;
    text-align: center;
    transition: background-color .2s linear;

    &:hover {
      background-color: #2ac0f3;
      box-shadow: inset 0 -1px rgba(30, 176, 239, 1);
    }

    @include mq-sp {
      width: 295px;
      max-width: 100%;
      padding: 8px 15px;
      font-size: 1.5rem;
    }
  }
}

/* c-box-detail
---------------------------------------------------------- */
.c-box-detail {
  .heading {
    border-bottom: 1px solid #ccc;
    padding-bottom: 1em;
    margin-bottom: 1em;

    @include mq-sp {
      padding-bottom: 0.8em;
      margin-bottom: 0.8em;
    }
  }

  .detail {
  }
}


