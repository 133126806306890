@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 header
========================================================== */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 20px;
  background-color: #fff;

  @include mq-sp {
    height: 50px;
  }

  .logo {
    a {
      display: block;
    }

    img {
      width: 235px;
    }

    @include mq-sp {
      img {
        width: 138px;
      }
    }
  }

  .utility {
    display: flex;
    align-items: center;
  }

  // btn-contact
  .btn-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 145px;
    height: 30px;
    margin-right: 30px;
    background-color: #2f3334;
    border-bottom: 1px solid #000;
    border-radius: 15px;
    text-align: center;
    font-size: 1.2rem;
    color: #fff;
  
    .txt {
      display: inline-block;
      padding-left: 26px;
      background: url('/img/common/ico_contact_01.png') 0 center no-repeat;
    }
  
    &:hover {
      background-color: #66777d;
  
      .txt {
        background: url('/img/common/ico_contact_01_on.png') 0 center no-repeat;
      }
    }

    @include mq-sp {
      display: none;
    }
  }

  // header-tel
  .header-tel {
    padding-left: 30px;
    margin-right: 35px;
    background: url('/img/common/ico_tel_01.png') 0 center no-repeat;
    background-size: 19px 25px;
    color: #009dd3;

    .number {
      display: block;
      margin-bottom: 4px;
      font-family: $roboto;
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.08em;
    }
    
    .time {
      display: block;
      font-size: 1.2rem;
      line-height: 1;
      letter-spacing: 0.1em;
    }

    @include mq-sp {
      display: none;
    }
  }

  // header-lang
  .header-lang {
    width: 70px;
    padding: 3px;
    background-color: #ccc;
    border-radius: 5px;

    .item {
      &:first-child {
        margin-bottom: 1px;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      font-size: 1.2rem;
      color: #fff;
      line-height: 1;
      text-align: center;

      &:hover {
        border-radius: 3px;
        background-color: #2ac0f3;
        box-shadow: 0 1px rgba(0,0,0,.2);
      }

      &.is-active {
        border-radius: 3px;
        background-color: #009dd3;
        box-shadow: 0 1px rgba(0,0,0,.2);
      }
    }

    @include mq-sp {
      display: none;
    }
  }

  // btn-menu
  .btn-menu {

    .bar {
      position: relative;
      display: block;
      width: 25px;
      height: 5px;
      background-color: $color-primary;
      margin-bottom: 5px;
      transition: all .2s linear;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.is-open {
      .bar {
        &:nth-child(1) {
          top: 10px;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          visibility: hidden;
          opacity: 0;
        }
        &:nth-child(3) {
          top: -10px;
          transform: rotate(-45deg);
        }
      }
    }

    @include mq-pc {
      display: none;
    }
    
  }
}

