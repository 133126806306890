@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 module
========================================================== */
/* c-mod-project
---------------------------------------------------------- */
.c-mod-project {
  border: 1px solid #ccc;
  padding: 25px 30px 30px;

  @include mq-sp {
    padding: 15px 20px 20px;
  }

  .article {
    margin-bottom: 20px;

    .title {
      margin-bottom: 15px;
      font-size: 1.6rem;

      @include mq-sp {
        margin-bottom: 15px;
      }
    }

    .desc {
      @include mq-pc {
        line-height: 1.6;
      }
    }

    .note {
      font-size: 1.2rem;
    }
  }

  .list-flow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @include mq-sp {
      margin: -15px -7px 0;
    }

    .item {
      position: relative;
      width: 25%;
      padding: 0 15px;

      @include mq-sp {
        width: 50%;
        padding: 0 7px;
        margin-top: 15px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: -7px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 14px solid $color-primary;

        @include mq-sp {
          right: -4px;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-left: 8px solid $color-primary;
        }
      }

      &:last-child:after {
        display: none;
      }

      img {
        width: 100%;
      }
    }
  }
}

/* c-modal
---------------------------------------------------------- */
.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  overflow-y: auto;
  width: 100%;
  padding: 150px 20px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: all .3s;

  &.is-show {
    z-index: 1000;
    visibility: visible;
    opacity: 1;
  }

  .modal-content {
    position: relative;
    padding: 20px;
    background-color: #fff;

    .btn-close {
      content: "";
      position: absolute;
      top: -30px;
      right: 0;
      width: 18px;
      height: 18px;
      overflow: hidden;
      background: url('/img/common/ico_close_modal.png') 0 0 no-repeat;
      background-size: 100%;
      text-indent: -9999px;
    }
  }

  .image {
    margin-bottom: 15px;

    img {
      width: 100%;
    }
  }

  .title {
    margin-bottom: 5px;
    font-weight: 700;

    &:last-child {
      margin-bottom: 0;
    }
  }
}