
@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 layout
========================================================== */
html {

}
body {
  font-size: 1.4rem;
  line-height: 1.86;
  color: #333;
  -webkit-text-size-adjust: 100%;
  //font-family: "Noto Sans JP","ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-family: "Noto Sans Japanese","ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

  @include mq-sp {
    min-width: 1px;
  }
}

/* wrapper
---------------------------------------------------------- */
.wrapper {
  overflow: hidden;
  padding-top: 80px;

  @include mq-pc {
    display: flex;
    min-width: 1280px;

    .nav-global {
      flex: 0 0 200px;
    }

    .contents {
      flex: 1 1 auto;
    }
  }

  @include mq-sp {
    padding-top: 50px;
  }
}

/* nav-breadcrumb
---------------------------------------------------------- */
.nav-breadcrumb {
  overflow: hidden;
  padding: 8px 50px;
  background-color: #2f3334;

  @include mq-sp {
    display: none;
  }

  .list-nav {
    display: flex;
  }

  .item {
    position: relative;
    float: left;
    margin-right: 44px;
    color: #fff;

    &:before {
      position: absolute;
      content: "/";
      font-size: 1.2rem;
      line-height: 1;
      color: #fff;
      top: 8px;
      right: -24px;
    }

    &:last-child:before { display: none; }

    a {
      display: inline-block;
      color: #009dd3;
      line-height: 1;
      padding-right: 1px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

/* contents
---------------------------------------------------------- */
.contents {
}

/* contents-main
---------------------------------------------------------- */
.contents-main {
  position: relative;
  padding: 50px 0 100px;

  

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 380px;
    height: 266px;
    background: url('/img/common/bg_contents_01.jpg') 0 0 no-repeat;
    background-size: 100% auto;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 380px;
    height: 250px;
    background: url('/img/common/bg_contents_02.jpg') 0 0 no-repeat;
    background-size: 100% auto;
  }

  @include mq-sp {
    padding: 35px 0 70px;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}

/* container
---------------------------------------------------------- */
.container {
  position: relative;
  z-index: 1;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;

  @include mq-pc {
    width: 1020px;
  }
}

/* grid
---------------------------------------------------------- */
.c-grid {
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col {

  }

  .col-6 { width: 50%; }
  .col-4 { width: 33.33%; }
  .col-3 { width: 25%; }

  @include mq-pc {
    .col-pc-6 { width: 50%; }
    .col-pc-4 { width: 33.33%; }
    .col-pc-3 { width: 25%; }
  }

  @include mq-sp {
    .col-sp-6 { width: 50%; }
    .col-sp-4 { width: 33.33%; }
    .col-sp-3 { width: 25%; }
  }
}
