@import '../../_variables.scss';
@import '../../_mixin.scss';

.s-hide {
  display: none;
}
.s-show {
  display: block;
}

/* ==========================================================
 rwd switch
========================================================== */
@include mq-pc {
  .only-sp {
    display: none !important;
  }
}
@include mq-sp {
  .only-pc {
    display: none !important;
  }
}

.d-none         { display: none !important; }
.d-inline       { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block        { display: block !important; }
.d-table        { display: table !important; }
.d-table-row    { display: table-row !important; }
.d-table-cell   { display: table-cell !important; }
.d-flex         { display: flex !important; }
.d-inline-flex  { display: inline-flex !important; }

@include mq-sp {
  .d-sp-none         { display: none !important; }
  .d-sp-inline       { display: inline !important; }
  .d-sp-inline-block { display: inline-block !important; }
  .d-sp-block        { display: block !important; }
  .d-sp-table        { display: table !important; }
  .d-sp-table-row    { display: table-row !important; }
  .d-sp-table-cell   { display: table-cell !important; }
  .d-sp-flex         { display: flex !important; }
  .d-sp-inline-flex  { display: inline-flex !important; }
}