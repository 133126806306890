@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Company
========================================================== */
/* sec-comp-info
---------------------------------------------------------- */
.sec-comp-info {
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 50px;
  }

  .heading {
    margin-bottom: 25px;

    @include mq-sp {
      margin-bottom: 15px;
    }
  }

  .row {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;

    @include mq-pc {
      display: flex;
    }

    @include mq-sp {

    }
  }

  .title {
    font-weight: 500;

    @include mq-pc {
      flex: 0 0 130px;
    }

    @include mq-sp {
    }
  }

  .detail {
    @include mq-pc {
      flex: 1 1 auto;
    }

    a {
      color: #333;
    }
  }
}

/* ol comp
---------------------------------------------------------- */
// ol-comp-01
.ol-comp-01 {
  > .item {
    position: relative;
    counter-increment: number01;
    padding-left: 25px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      position: absolute;
      content: counter(number01)" -";
      top: 0;
      left: 0;
      font-weight: bold;
      color: #96a1a5;
    }

    > .title {
      font-weight: 700;
    }
  }
}

// ol-comp-02
.ol-comp-02 {
  > .item {
    position: relative;
    counter-increment: number02;
    padding-left: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      position: absolute;
      content: counter(number02)".";
      top: 0;
      left: 0;
    }
  }
}

// ol-comp-03
.ol-comp-03 {

  > .item {
    margin-bottom: 12px;
    counter-increment: number03;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }

    > .title {
      position: relative;
      display: block;
      padding-left: 18px;
      font-weight: 700;

      &:before {
        content: counter(number03)".";
        position: absolute;
        top: 0;
        left: 0;
        font-weight: bold;
      }
    }
  }
}

/* box-comp-address
---------------------------------------------------------- */
.box-comp-address {
  @include mq-pc {
    display: flex;
    align-items: flex-start;
  }

  .box-address {

    @include mq-pc {
      flex: 0 0 340px;
      padding-right: 40px;
    }

    @include mq-sp {
      margin-bottom: 15px;
    }

    .row {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      @include mq-sp {
        margin-bottom: 15px;
      }
    }
  
    .title {
      margin-bottom: 10px;
    }
  
    .detail {
  
    }
  }

  .box-map {
    position: relative;
    padding-top: 42%;
    overflow: hidden;

    @include mq-pc {
      flex: 1 1 auto;
    }

    @include mq-sp {
      padding-top: 60%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      @include mq-pc {
        height: 100%;
      }

      @include mq-sp {
        bottom: 0;
      }
    }
  }
}

/* box-comp-content
---------------------------------------------------------- */
.box-comp-content {
  overflow: hidden;

  .image {
    float: right;

    @include mq-sp {
      float: none;
      margin-top: 20px;
    }
  }
}