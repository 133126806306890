@import '../_variables.scss';
@import '../_mixin.scss';

// reset min-width of wrapper
#top .wrapper, #top .footer {
  @include mq-pc {
    min-width: 1320px;
  }
}

/* ==========================================================
 Top
========================================================== */
/* ttl-top
---------------------------------------------------------- */
.ttl-top {
  margin-bottom: 20px;
  font-family: $yumin;
  font-size: 2.2rem;
  font-weight: 500;
  color: #fff;

  @include mq-sp {
    margin-bottom: 15px;
    font-size: 2rem;
    text-align: center;
  }
}

/* contents-toppage
---------------------------------------------------------- */
.contents-toppage {
  background-image: url('/img/top/bg_top_body.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  .wrap {
    @include mq-pc {
      display: flex;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
  
      .mainvisual {
        width: 50%;
      }
    
      .main {
        width: 50%;

        .sec-top-products {
          height: 55%;
        }
        
        .sec-top-solution {
          height: 45%;
        }
      }
    }
  }
}

/* mainvisual
---------------------------------------------------------- */
.mainvisual {
  position: relative;

  .bg {
    position: relative;
    padding-top: 157%;

    @include mq-sp {
      padding-top: 0;
      height: 460px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      
    }
  }

  .box-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 0 15px;

    @include mq-sp {
      width: 345px;
      padding: 0 20px;
    }

    .title {
      display: inline-block;
      padding: 0 65px 18px;
      margin-bottom: 18px;
      border-bottom: 1px solid rgba(255,255,255,0.3);
      font-family: $roboto;
      font-size: 5.9rem;
      color: #fff;
      line-height: 1.1;

      @include mq-sp {
        display: block;
        padding: 0 20px 10px;
        margin-bottom: 15px;
        font-size: 5.2rem;
      }

      .blue {
        font-size: 6.6rem;
        color: $color-primary;

        @include mq-sp {
          font-size: 5.5rem;
        }
      }

      // english version
      &.-english {
        padding-bottom: 0;
        border-bottom: 0;

        @include mq-pc {
          font-size: 7.2rem;

          .blue {
            font-size: 8rem;
          }          
        }
      }
    }

    .desc {
      font-family: $yumin;
      font-weight: 500;
      font-size: 2.7rem;
      color: #fff;
      line-height: 1.5;
      letter-spacing: -0.05em;

      @include mq-sp {
        font-size: 2rem;
      }
    }
  }
}

/* sec-top-products
---------------------------------------------------------- */
.sec-top-products {
  position: relative;
  background-color: $color-primary;

  @include mq-sp {
    padding: 25px 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 442px;
    height: 270px;
    background: url('/img/top/bg_polygon_top_pc.jpg') 0 0 no-repeat;
    background-size: 100% auto;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 522px;
    height: 280px;
    background: url('/img/top/bg_polygon_bottom_pc.jpg') 0 bottom no-repeat;
    background-size: 100% auto;
  }

  .cont {
    position: relative;
    z-index: 1;
    
    @include mq-pc {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .ttl-top {
    padding-left: 20px;

    @include mq-sp {
      padding-left: 0;
    }
  }
}

/* mod-slider-products
---------------------------------------------------------- */
.mod-slider-products {
  position: relative;
  z-index: 1;
  width: 560px;
  max-width: 100%;
  min-height: 370px;
  padding: 0 20px;
  visibility: hidden;

  @include mq-sp {
    width: 375px;
    min-height: 460px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .card-products {
    position: relative;
    background-color: #fff;

    @include mq-sp {
      height: 400px;
    }

    .image {
      img {
        width: 100%;
      }
    }

    .detail {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 1;
      transform: translateY(-50%);
      width: 245px;
      padding-right: 20px;
      padding-bottom: 60px;

      @include mq-sp {
        top: inherit;
        right: inherit;
        bottom: 0;
        transform: none;
        width: 100%;
        padding: 20px 20px 75px;
      }
    }

    .title {
      margin-bottom: 10px;
      font-size: 2.2rem;
      font-weight: 700;
      color: #000;
      line-height: 1.3;

      @include mq-sp {
        margin-bottom: 8px;
        font-size: 2rem;
      }
    }

    .text {
      font-weight: 300;

      &.-english {
        font-size: 1.3rem;
        line-height: 1.7;
      }

      @include mq-sp {
        line-height: 1.78;
      }
    }

    .button {
      position: absolute;
      bottom: 20px;
      right: 20px;
      z-index: 2;

      @include mq-sp {
        left: 0;
        right: inherit;
        width: 100%;
        padding: 0 20px;
      }
    }
  }

  .slider-wrap {
    position: relative;
  }

  .slider {
    display: flex;
    background-color: #fff;

    .item {
      transform: translateZ(0);
      backface-visibility: hidden;
      outline: none;
    }
  }

  .slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 90px;
    background-size: 100%;
    background-position: 0;
    background-repeat: no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    cursor: pointer;

    @include mq-pc {
      visibility: hidden;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255,0.1);
      opacity: 0;
      transition: opacity .2s;
    }

    &.-prev {
      left: -20px;
      background-image: url('/img/top/btn_prev.png');
    }

    &.-next {
      right: -20px;
      background-image: url('/img/top/btn_next.png');
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  .slider-thumb {
    .slick-dots {
      display: flex;
    }

    li {
      position: relative;
      width: 16.67%;
      margin-left: 1px;
      padding-top: 30px;

      &:first-child {
        margin-left: 0;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background-color: #000;
        opacity: 0;
        backface-visibility: hidden;
        transition: opacity .2s;
      }

      .thumb {
        position: relative;
        cursor: pointer;

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.5);
          bottom: 0;
          left: 0;
          opacity: 1;
          backface-visibility: hidden;
          transition: opacity .2s;
        }

        img {
          width: 100%;
        }
      }

      // hover and active
      &.slick-active, &:hover {
        &:before {
          opacity: 1;
        }

        .thumb:before {
          opacity: 0;
        }
      }
    }
  }
}


/* sec-top-solution
---------------------------------------------------------- */
.sec-top-solution {
  position: relative;
  background: url('/img/top/bg_sec_solution.jpg') 0 0 no-repeat;
  background-size: cover;

  @include mq-sp {
    padding: 30px 20px;
  }

  .cont {
    @include mq-pc {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 520px;
    }

    @include mq-mx(1280) {
      width: 100%;
      padding: 0 20px;

      &.-english {
        padding: 0 10px;
        margin-top: -3px;
      }
    }

    @include mq-sp {
      padding: 0;
    }
  }

  .ttl-top {
    margin-bottom: 10px;
  }

  .list-solution {
    .item {
      margin-bottom: 1px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      position: relative;
      display: block;
      padding: 6px 10px 6px 15px;
      background-color: rgba(0,0,0,0.1);
      border-left: 20px solid $color-primary;
      color: #fff;
      line-height: 1.7;
      font-weight: 200;
      letter-spacing: 0.08em;

      @include mq-sp {
        border-width: 15px;
        padding: 13px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -13px;
        width: 0;
        height: 0;
        transform: translateY(-50%);
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 6px solid #fff;

        @include mq-sp {
          left: -9px;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-left: 4px solid #fff;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.1);
        opacity: 0;
        transition: opacity 0.2s;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

/* sec-top-news
---------------------------------------------------------- */
.sec-top-news {
  display: flex;

  @include mq-sp {
    display: block;
    padding: 30px 20px;
  }

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 200px;
    background-color: #d5e3e9;
    font-size: 2.4rem;
    color: #009dd3;
    line-height: 1;
    text-align: center;

    @include mq-sp {
      margin-bottom: 25px;
      display: block;
      background-color: transparent;
      font-size: 2rem;
      color: #000;
    }
  }

  .detail {
    flex: 1 1 auto;
    padding: 12px 20px;

    @include mq-sp {
      padding: 0;
    }
  }

  .list-news {

    .item {
      @include mq-sp {
        padding-bottom: 15px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 15px;

        &:last-child {
          padding-bottom: 0;
          border-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    .date {
      flex: 0 0 100px;
      letter-spacing: 0.1em;
    }

    .text {
      flex: 1 0 auto;
    }

    .row {
      display: inline-flex;
      color: #333;
      font-weight: 300;
      transition: opacity .2s;

      @include mq-sp {
        display: block;
      }
    }
  }
}
