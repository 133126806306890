@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 table
========================================================== */
/* c-table-basic
---------------------------------------------------------- */
.c-table-basic {
  margin-bottom: 45px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-sp {
    margin-bottom: 35px;
  }

  table {
    width: 100%;

    @include mq-sp {
      display: block;
    }

    tbody {
      @include mq-sp {
        display: block;
      }
    }

    tr {

      @include mq-sp {
        display: block;

        &:last-child {
          td {
            border-bottom: 1px solid #cccccc;
          }
        }
      }
    }

    th {
      width: 20%;
      padding: 7px 13px 8px;
      background-color: #fbfbfb;
      border: 1px solid #cccccc;

      &.-darker {
        background-color: #eee;
      }

      @include mq-sp {
        display: block;
        width: 100%;
        padding: 4px 10px;
        font-weight: 500;
      }
    }

    td {
      padding: 8px 13px 8px;
      border: 1px solid #cccccc;

      @include mq-sp {
        display: block;
        padding: 4px 10px;
        border-top: 0;
        border-bottom: 0;
      }
    }
  }

  .note {
    margin-top: 15px;
  }
}

/* c-table-tanker
---------------------------------------------------------- */
.c-table-tanker {
  margin-bottom: 45px;

  @include mq-sp {
    margin-bottom: 30px;
    padding-bottom: 10px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 12px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: #dadada;
      border-radius: 10px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: #b8b8b8;
      border-radius: 10px;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;

    @include mq-sp {
      width: 980px;
    }

    thead {
      th {
        padding: 10px 15px;
        background-color: #eee;
        border: 1px solid #ccc;
        text-align: center;

        &:first-child {
          width: 22%;
        }
      }
    }
    
    tbody {
      th {
        padding: 10px 15px;
        border: 1px solid #ccc;
      }

      td {
        padding: 10px 15px;
        border: 1px solid #ccc;
      }

      .bg-green {
        background-color: #f0f6f8;
      }
    }
  }
}