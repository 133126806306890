@import '../../_variables.scss';
@import '../../_mixin.scss';

$spaceamounts: (0, 5, 8, 10, 12, 13, 14, 15, 17, 20, 22, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120); // Adjust this to include the pixel amounts you need.

$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
    .ma-#{$space} {
      margin : #{$space}px !important;
    }
    .pa-#{$space} {
      padding : #{$space}px !important;
    }
  }
}

@include mq-pc {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-pc-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }
    
      .p#{str-slice($side, 0, 1)}-pc-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
      .ma-pc-#{$space} {
        margin : #{$space}px !important;
      }
      .pa-pc-#{$space} {
        padding : #{$space}px !important;
      }
    }
  }
}

@include mq-sp {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-sp-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }
    
      .p#{str-slice($side, 0, 1)}-sp-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
      .ma-sp-#{$space} {
        margin : #{$space}px !important;
      }
      .pa-sp-#{$space} {
        padding : #{$space}px !important;
      }
    }
  }
}

.ma-0 {
  margin: 0 auto !important;
}

@include mq-sp {
  .ma-sp-0 {
    margin: 0 auto !important;
  }
} 