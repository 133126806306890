@charset "UTF-8";
/* ==========================================================
 fonts
========================================================== */
@import url("https://fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700");
/* ==========================================================
 base
========================================================== */
/* ==========================================================
 html5 elements
========================================================== */
article, aside, figure, figcaption, footer, header, nav, section {
  display: block;
}

/* ==========================================================
 reseting
========================================================== */
html {
  font-size: 62.5%;
}

body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea {
  margin: 0;
  font-size: 100%;
}

input, textarea, select {
  /*-webkit-appearance: none;*/
}

textarea {
  resize: none;
}

button {
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font-size: 100%;
  color: inherit;
  cursor: pointer;
  -webkit-appearance: none;
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset {
  border: 0;
}

img {
  border: 0;
  height: auto;
  vertical-align: top;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, strong, em {
  font-size: 100%;
  font-weight: normal;
}

a, input {
  /* outline: none; -webkit-tap-highlight-color:rgba(0,0,0,0);*/
}

sup, sub {
  font-size: 63%;
}

sup {
  vertical-align: top;
}

sub {
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* ==========================================================
 base Link
========================================================== */
a {
  text-decoration: none;
  color: #009dd3;
}

a:hover, a:active, a:focus, a:visited {
  text-decoration: none;
}

/* ==========================================================
 clearfix
========================================================== */
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.contents:before, .contents:after {
  content: "";
  display: table;
}

.clearfix:after, .contents:after {
  clear: both;
}

/* For IE 6/7
---------------------------------------------------------- */
.clearfix, .contents {
  *zoom: 1;
}

/* ==========================================================
 layout
========================================================== */
body {
  font-size: 1.4rem;
  line-height: 1.86;
  color: #333;
  -webkit-text-size-adjust: 100%;
  font-family: "Noto Sans Japanese","ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

@media (max-width: 768px) {
  body {
    min-width: 1px;
  }
}

/* wrapper
---------------------------------------------------------- */
.wrapper {
  overflow: hidden;
  padding-top: 80px;
}

@media (min-width: 769px) {
  .wrapper {
    display: flex;
    min-width: 1280px;
  }
  .wrapper .nav-global {
    flex: 0 0 200px;
  }
  .wrapper .contents {
    flex: 1 1 auto;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding-top: 50px;
  }
}

/* nav-breadcrumb
---------------------------------------------------------- */
.nav-breadcrumb {
  overflow: hidden;
  padding: 8px 50px;
  background-color: #2f3334;
}

@media (max-width: 768px) {
  .nav-breadcrumb {
    display: none;
  }
}

.nav-breadcrumb .list-nav {
  display: flex;
}

.nav-breadcrumb .item {
  position: relative;
  float: left;
  margin-right: 44px;
  color: #fff;
}

.nav-breadcrumb .item:before {
  position: absolute;
  content: "/";
  font-size: 1.2rem;
  line-height: 1;
  color: #fff;
  top: 8px;
  right: -24px;
}

.nav-breadcrumb .item:last-child:before {
  display: none;
}

.nav-breadcrumb .item a {
  display: inline-block;
  color: #009dd3;
  line-height: 1;
  padding-right: 1px;
  transition: opacity 0.2s;
}

.nav-breadcrumb .item a:hover {
  opacity: 0.7;
}

/* contents
---------------------------------------------------------- */
/* contents-main
---------------------------------------------------------- */
.contents-main {
  position: relative;
  padding: 50px 0 100px;
}

.contents-main:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 380px;
  height: 266px;
  background: url("/img/common/bg_contents_01.jpg") 0 0 no-repeat;
  background-size: 100% auto;
}

.contents-main:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 380px;
  height: 250px;
  background: url("/img/common/bg_contents_02.jpg") 0 0 no-repeat;
  background-size: 100% auto;
}

@media (max-width: 768px) {
  .contents-main {
    padding: 35px 0 70px;
  }
  .contents-main:before {
    display: none;
  }
  .contents-main:after {
    display: none;
  }
}

/* container
---------------------------------------------------------- */
.container {
  position: relative;
  z-index: 1;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 769px) {
  .container {
    width: 1020px;
  }
}

/* grid
---------------------------------------------------------- */
.c-grid .row {
  display: flex;
  flex-wrap: wrap;
}

.c-grid .col-6 {
  width: 50%;
}

.c-grid .col-4 {
  width: 33.33%;
}

.c-grid .col-3 {
  width: 25%;
}

@media (min-width: 769px) {
  .c-grid .col-pc-6 {
    width: 50%;
  }
  .c-grid .col-pc-4 {
    width: 33.33%;
  }
  .c-grid .col-pc-3 {
    width: 25%;
  }
}

@media (max-width: 768px) {
  .c-grid .col-sp-6 {
    width: 50%;
  }
  .c-grid .col-sp-4 {
    width: 33.33%;
  }
  .c-grid .col-sp-3 {
    width: 25%;
  }
}

/* ==========================================================
 components
========================================================== */
/* ==========================================================
 header
========================================================== */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 20px;
  background-color: #fff;
}

@media (max-width: 768px) {
  .header {
    height: 50px;
  }
}

.header .logo a {
  display: block;
}

.header .logo img {
  width: 235px;
}

@media (max-width: 768px) {
  .header .logo img {
    width: 138px;
  }
}

.header .utility {
  display: flex;
  align-items: center;
}

.header .btn-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  height: 30px;
  margin-right: 30px;
  background-color: #2f3334;
  border-bottom: 1px solid #000;
  border-radius: 15px;
  text-align: center;
  font-size: 1.2rem;
  color: #fff;
}

.header .btn-contact .txt {
  display: inline-block;
  padding-left: 26px;
  background: url("/img/common/ico_contact_01.png") 0 center no-repeat;
}

.header .btn-contact:hover {
  background-color: #66777d;
}

.header .btn-contact:hover .txt {
  background: url("/img/common/ico_contact_01_on.png") 0 center no-repeat;
}

@media (max-width: 768px) {
  .header .btn-contact {
    display: none;
  }
}

.header .header-tel {
  padding-left: 30px;
  margin-right: 35px;
  background: url("/img/common/ico_tel_01.png") 0 center no-repeat;
  background-size: 19px 25px;
  color: #009dd3;
}

.header .header-tel .number {
  display: block;
  margin-bottom: 4px;
  font-family: "Roboto Slab", serif;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.08em;
}

.header .header-tel .time {
  display: block;
  font-size: 1.2rem;
  line-height: 1;
  letter-spacing: 0.1em;
}

@media (max-width: 768px) {
  .header .header-tel {
    display: none;
  }
}

.header .header-lang {
  width: 70px;
  padding: 3px;
  background-color: #ccc;
  border-radius: 5px;
}

.header .header-lang .item:first-child {
  margin-bottom: 1px;
}

.header .header-lang a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 1.2rem;
  color: #fff;
  line-height: 1;
  text-align: center;
}

.header .header-lang a:hover {
  border-radius: 3px;
  background-color: #2ac0f3;
  box-shadow: 0 1px rgba(0, 0, 0, 0.2);
}

.header .header-lang a.is-active {
  border-radius: 3px;
  background-color: #009dd3;
  box-shadow: 0 1px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .header .header-lang {
    display: none;
  }
}

.header .btn-menu .bar {
  position: relative;
  display: block;
  width: 25px;
  height: 5px;
  background-color: #009dd3;
  margin-bottom: 5px;
  transition: all .2s linear;
}

.header .btn-menu .bar:last-child {
  margin-bottom: 0;
}

.header .btn-menu.is-open .bar:nth-child(1) {
  top: 10px;
  transform: rotate(45deg);
}

.header .btn-menu.is-open .bar:nth-child(2) {
  visibility: hidden;
  opacity: 0;
}

.header .btn-menu.is-open .bar:nth-child(3) {
  top: -10px;
  transform: rotate(-45deg);
}

@media (min-width: 769px) {
  .header .btn-menu {
    display: none;
  }
}

/* nav-global
---------------------------------------------------------- */
.nav-global {
  background-color: #009dd3;
}

@media (max-width: 768px) {
  .nav-global {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: -1;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    transition: all .2s linear;
  }
  .nav-global.is-visible {
    z-index: 98;
    visibility: visible;
    opacity: 1;
  }
}

.nav-global .list-lang {
  display: none;
}

@media (max-width: 768px) {
  .nav-global .list-lang {
    display: flex;
  }
  .nav-global .list-lang .item {
    position: relative;
    width: 50%;
    border-bottom: 1px solid #008dbd;
  }
  .nav-global .list-lang .item:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 35px;
    background-color: #008dbd;
  }
  .nav-global .list-lang a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff;
    text-align: center;
    letter-spacing: 0.1em;
  }
  .nav-global .list-lang a.is-current {
    opacity: 0.5;
  }
}

@media (min-width: 769px) {
  .nav-global .c-link-banner a {
    position: relative;
    background-size: auto 100%;
  }
  .nav-global .c-link-banner a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transition: opacity .2s;
    opacity: 0;
  }
  .nav-global .c-link-banner a:hover {
    opacity: 1;
  }
  .nav-global .c-link-banner a:hover:before {
    opacity: 1;
  }
  .nav-global .c-link-banner small {
    font-size: 1.7rem;
    letter-spacing: 0.2em;
  }
}

.nav-global.-expand .list-gnav-sub-01, .nav-global.-expand .list-gnav-sub-02 {
  display: block !important;
}

.list-nav-global > .item > a, .list-nav-global > .item > .btn-accd {
  display: flex;
  align-items: center;
  height: 65px;
  padding: 15px 0 15px 60px;
  background-position: 15px center;
  background-repeat: no-repeat;
  border-bottom: 1px solid #008dbd;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #fff;
  transition: background-color 0.2s;
}

.list-nav-global > .item > a.ico-home, .list-nav-global > .item > .btn-accd.ico-home {
  background-image: url("/img/common/ico_home.png");
}

.list-nav-global > .item > a.ico-strength, .list-nav-global > .item > .btn-accd.ico-strength {
  background-image: url("/img/common/ico_strength.png");
}

.list-nav-global > .item > a.ico-products, .list-nav-global > .item > .btn-accd.ico-products {
  background-image: url("/img/common/ico_products.png");
}

.list-nav-global > .item > a.ico-case, .list-nav-global > .item > .btn-accd.ico-case {
  background-image: url("/img/common/ico_case.png");
}

.list-nav-global > .item > a.ico-company, .list-nav-global > .item > .btn-accd.ico-company {
  background-image: url("/img/common/ico_company.png");
}

.list-nav-global > .item > a.ico-mail, .list-nav-global > .item > .btn-accd.ico-mail {
  background-image: url("/img/common/ico_mail.png");
}

.list-nav-global > .item > a.ico-tel, .list-nav-global > .item > .btn-accd.ico-tel {
  background-image: url("/img/common/ico_tel.png");
  background-size: 27px auto;
}

@media (min-width: 769px) {
  .list-nav-global > .item > a:hover, .list-nav-global > .item > a.is-current, .list-nav-global > .item > .btn-accd:hover, .list-nav-global > .item > .btn-accd.is-current {
    background-color: #007faa;
    border-color: #007299;
  }
  .list-nav-global > .item > a:hover.ico-home, .list-nav-global > .item > a.is-current.ico-home, .list-nav-global > .item > .btn-accd:hover.ico-home, .list-nav-global > .item > .btn-accd.is-current.ico-home {
    background-image: url("/img/common/ico_home_on.png");
  }
  .list-nav-global > .item > a:hover.ico-strength, .list-nav-global > .item > a.is-current.ico-strength, .list-nav-global > .item > .btn-accd:hover.ico-strength, .list-nav-global > .item > .btn-accd.is-current.ico-strength {
    background-image: url("/img/common/ico_strength_on.png");
  }
  .list-nav-global > .item > a:hover.ico-products, .list-nav-global > .item > a.is-current.ico-products, .list-nav-global > .item > .btn-accd:hover.ico-products, .list-nav-global > .item > .btn-accd.is-current.ico-products {
    background-image: url("/img/common/ico_products_on.png");
  }
  .list-nav-global > .item > a:hover.ico-case, .list-nav-global > .item > a.is-current.ico-case, .list-nav-global > .item > .btn-accd:hover.ico-case, .list-nav-global > .item > .btn-accd.is-current.ico-case {
    background-image: url("/img/common/ico_case_on.png");
  }
  .list-nav-global > .item > a:hover.ico-company, .list-nav-global > .item > a.is-current.ico-company, .list-nav-global > .item > .btn-accd:hover.ico-company, .list-nav-global > .item > .btn-accd.is-current.ico-company {
    background-image: url("/img/common/ico_company_on.png");
  }
  .list-nav-global > .item > a:hover.ico-mail, .list-nav-global > .item > a.is-current.ico-mail, .list-nav-global > .item > .btn-accd:hover.ico-mail, .list-nav-global > .item > .btn-accd.is-current.ico-mail {
    background-image: url("/img/common/ico_mail_on.png");
  }
}

@media (max-width: 768px) {
  .list-nav-global > .item > a, .list-nav-global > .item > .btn-accd {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 10px 0 10px 65px;
    background-position: 20px center;
    border-bottom: 2px solid #008dbd;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .list-nav-global > .item > a .tel, .list-nav-global > .item > .btn-accd .tel {
    display: block;
    margin-bottom: 2px;
    font-family: "Roboto Slab", serif;
    font-size: 2.6rem;
    font-weight: bold;
    letter-spacing: 0.08em;
  }
  .list-nav-global > .item > a .time, .list-nav-global > .item > .btn-accd .time {
    display: block;
    font-size: 1.3rem;
  }
  .list-nav-global > .item > a.is-current, .list-nav-global > .item > .btn-accd.is-current {
    background-color: #fff;
    border-color: #e5e5e5;
    color: #009dd3;
  }
}

.list-nav-global .list-gnav-sub-01 {
  display: none;
  position: relative;
  padding: 10px;
}

@media (min-width: 769px) {
  .list-nav-global .list-gnav-sub-01 {
    margin-top: -1px;
    background-color: #007faa;
    border-bottom: 1px solid #007299;
  }
  .list-nav-global .list-gnav-sub-01:before {
    content: "";
    position: absolute;
    top: 0;
    width: 180px;
    height: 1px;
    background-color: #00739a;
  }
}

@media (max-width: 768px) {
  .list-nav-global .list-gnav-sub-01 {
    padding: 0;
  }
  .list-nav-global .list-gnav-sub-01:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 30px;
    height: 100%;
    background-color: #007faa;
  }
}

.list-nav-global .list-gnav-sub-01.is-show {
  display: block;
}

.list-nav-global .list-gnav-sub-01 > .item {
  position: relative;
  margin-bottom: 1px;
}

@media (max-width: 768px) {
  .list-nav-global .list-gnav-sub-01 > .item {
    margin-bottom: 0;
  }
}

.list-nav-global .list-gnav-sub-01 > .item > a, .list-nav-global .list-gnav-sub-01 > .item > .btn-accd {
  position: relative;
  display: block;
  padding: 7px 10px;
  border-radius: 6px;
  font-size: 1.4rem;
  line-height: 1.3;
  color: #fff;
  transition: background-color .2s linear;
}

@media (max-width: 768px) {
  .list-nav-global .list-gnav-sub-01 > .item > a, .list-nav-global .list-gnav-sub-01 > .item > .btn-accd {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 10px 0 10px 65px;
    background-position: 20px center;
    border-bottom: 2px solid #008dbd;
    border-radius: 0;
    font-size: 1.5rem;
    font-weight: 500;
  }
}

@media (min-width: 769px) {
  .list-nav-global .list-gnav-sub-01 > .item > a:hover, .list-nav-global .list-gnav-sub-01 > .item > a.is-current, .list-nav-global .list-gnav-sub-01 > .item > .btn-accd:hover, .list-nav-global .list-gnav-sub-01 > .item > .btn-accd.is-current {
    background-color: #005f80;
  }
}

@media (max-width: 768px) {
  .list-nav-global .list-gnav-sub-01 > .item > a.is-current, .list-nav-global .list-gnav-sub-01 > .item > .btn-accd.is-current {
    background-color: #fff;
    border-color: #e5e5e5;
    color: #009dd3;
  }
}

.list-nav-global .list-gnav-sub-02 {
  display: none;
  margin: 1px 0;
}

@media (max-width: 768px) {
  .list-nav-global .list-gnav-sub-02 {
    margin: 0;
    padding-left: 30px;
  }
}

.list-nav-global .list-gnav-sub-02.is-show {
  display: block;
}

.list-nav-global .list-gnav-sub-02 .item {
  margin-bottom: 1px;
}

.list-nav-global .list-gnav-sub-02 .item:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .list-nav-global .list-gnav-sub-02 .item {
    margin-bottom: 0;
  }
}

.list-nav-global .list-gnav-sub-02 .item a, .list-nav-global .list-gnav-sub-02 .item .btn-accd {
  position: relative;
  display: block;
  padding: 7px 10px 7px 40px;
  border-radius: 6px;
  font-size: 1.4rem;
  line-height: 1.3;
  color: #fff;
  transition: background-color .2s linear;
}

@media (max-width: 768px) {
  .list-nav-global .list-gnav-sub-02 .item a, .list-nav-global .list-gnav-sub-02 .item .btn-accd {
    display: flex;
    align-items: center;
    height: 55px;
    padding-left: 45px;
    background-color: #32abda;
    border-radius: 0;
    border-bottom: 2px solid #199ed1;
    font-size: 1.5rem;
  }
}

.list-nav-global .list-gnav-sub-02 .item a:before, .list-nav-global .list-gnav-sub-02 .item .btn-accd:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 20px;
  width: 13px;
  height: 13px;
  background: url("/img/common/ico_sub_lv2.png") 0 0 no-repeat;
  background-size: 100%;
}

@media (max-width: 768px) {
  .list-nav-global .list-gnav-sub-02 .item a:before, .list-nav-global .list-gnav-sub-02 .item .btn-accd:before {
    top: 20px;
    left: 20px;
  }
}

@media (min-width: 769px) {
  .list-nav-global .list-gnav-sub-02 .item a:hover, .list-nav-global .list-gnav-sub-02 .item a.is-current, .list-nav-global .list-gnav-sub-02 .item .btn-accd:hover, .list-nav-global .list-gnav-sub-02 .item .btn-accd.is-current {
    background-color: #005f80;
  }
}

@media (max-width: 768px) {
  .list-nav-global .list-gnav-sub-02 .item:last-child a {
    border-bottom: 0;
  }
}

/* ==========================================================
 footer
========================================================== */
.footer {
  position: relative;
  padding-bottom: 40px;
  background-color: #2f3334;
  overflow: hidden;
}

@media (min-width: 769px) {
  .footer {
    min-width: 1280px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding-bottom: 20px;
  }
}

.footer .btn-pagetop {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 97;
  display: block;
  width: 80px;
  height: 80px;
  background-color: #009dd3;
  background-image: url("/img/common/ico_pagetop_01.png");
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -9999px;
  overflow: hidden;
  transition: all .2s linear;
  opacity: 0;
  visibility: hidden;
}

.footer .btn-pagetop:hover {
  opacity: 0.7;
}

.footer .btn-pagetop.is-visible {
  visibility: visible;
  opacity: 1;
}

.footer .btn-pagetop.is-lock {
  position: absolute;
  bottom: inherit;
  top: 0;
}

@media (max-width: 768px) {
  .footer .btn-pagetop.is-lock {
    top: inherit;
    bottom: 0;
  }
}

@media (max-width: 768px) {
  .footer .btn-pagetop {
    width: 40px;
    height: 40px;
    background-size: 22px auto;
  }
}

.footer .breakpoint {
  position: absolute;
  top: 80px;
  right: 1px;
  width: 1px;
  height: 1px;
}

@media (max-width: 768px) {
  .footer .breakpoint {
    top: inherit;
    bottom: 1px;
  }
}

.footer .footer-link {
  padding: 28px 0 25px;
  border-bottom: 1px solid #3a3d3e;
}

@media (max-width: 768px) {
  .footer .footer-link {
    padding: 0;
    margin: 0;
    border-bottom: 0;
  }
}

.footer .list-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -10px;
}

@media (max-width: 768px) {
  .footer .list-links {
    margin: 0;
  }
}

.footer .list-links .item {
  margin-top: 10px;
  margin-left: 40px;
}

.footer .list-links .item:first-child {
  margin-left: 0;
}

@media (max-width: 768px) {
  .footer .list-links .item {
    width: 50%;
    margin: 0;
    border-bottom: 1px solid #3a3d3e;
  }
  .footer .list-links .item:nth-child(odd) {
    border-right: 1px solid #3a3d3e;
  }
  .footer .list-links .item.-wfull {
    width: 100%;
    border-right: 0;
  }
}

.footer .list-links a {
  line-height: 1.9;
  color: #fff;
  transition: opacity .2s linear;
}

.footer .list-links a:hover {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .footer .list-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 1.2rem;
    text-align: center;
  }
}

@media (min-width: 769px) {
  .footer .c-link-banner {
    display: none;
  }
}

.footer .logo {
  padding-top: 35px;
  margin-bottom: 25px;
  line-height: 1;
  text-align: center;
}

.footer .logo a {
  display: inline-block;
}

.footer .logo img {
  width: 230px;
}

@media (max-width: 768px) {
  .footer .logo {
    padding-top: 30px;
  }
  .footer .logo img {
    width: 208px;
  }
}

.footer .copyright {
  font-size: 1.2rem;
  line-height: 1;
  color: #fff;
  text-align: center;
  letter-spacing: 0.07em;
}

@media (max-width: 768px) {
  .footer .copyright {
    padding: 0 40px;
    font-size: 1.2rem;
    line-height: 2.2;
  }
}

/* ==========================================================
 heading
========================================================== */
/* c-hdg-box
---------------------------------------------------------- */
.c-hdg-box {
  position: relative;
  margin-bottom: 60px;
}

.c-hdg-box:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-hdg-box {
    margin-bottom: 45px;
  }
}

.c-hdg-box .bg img {
  width: 100%;
}

.c-hdg-box .title {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  font-family: YuMincho, "游明朝", "Times New Roman", "Hiragino Mincho Pro", "ヒラギノ明朝 Pro W3", "HG明朝B", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 3.6rem;
  font-weight: bold;
  color: #fff;
  line-height: 1.2;
}

.c-hdg-box .title.-black {
  color: #000;
}

.c-hdg-box .title small {
  font-size: 3rem;
}

@media (max-width: 768px) {
  .c-hdg-box .title {
    left: 25px;
    font-size: 1.8rem;
  }
  .c-hdg-box .title small {
    font-size: 1.5rem;
  }
}

/* c-hdg-01
---------------------------------------------------------- */
.c-hdg-01 {
  margin-bottom: 40px;
  font-family: YuMincho, "游明朝", "Times New Roman", "Hiragino Mincho Pro", "ヒラギノ明朝 Pro W3", "HG明朝B", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1.3;
}

.c-hdg-01 small {
  font-size: 2.4rem;
}

.c-hdg-01:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-hdg-01 {
    margin-bottom: 20px;
    font-size: 2.4rem;
  }
  .c-hdg-01 small {
    font-size: 1.6rem;
  }
}

/* c-hdg-02
---------------------------------------------------------- */
.c-hdg-02 {
  padding: 8px 14px;
  margin-bottom: 40px;
  background: #009dd3 url("/img/common/bg_title.jpg") no-repeat right center;
  background-size: 242px auto;
  font-family: YuMincho, "游明朝", "Times New Roman", "Hiragino Mincho Pro", "ヒラギノ明朝 Pro W3", "HG明朝B", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.4;
  color: #fff;
  letter-spacing: -0.01em;
}

@media (max-width: 768px) {
  .c-hdg-02 {
    margin-bottom: 30px;
    padding: 5px 10px;
    background-size: 121px auto;
    font-size: 1.8rem;
  }
}

/* c-hdg-03
---------------------------------------------------------- */
.c-hdg-03 {
  margin-bottom: 25px;
  font-family: YuMincho, "游明朝", "Times New Roman", "Hiragino Mincho Pro", "ヒラギノ明朝 Pro W3", "HG明朝B", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 2.6rem;
  line-height: 1.33;
  letter-spacing: -1px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .c-hdg-03 {
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
}

/* c-hdg-04
---------------------------------------------------------- */
.c-hdg-04 {
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #7ab9cf;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.22;
}

.c-hdg-04.-noborder {
  padding-bottom: 0;
  border-bottom: 0;
}

@media (max-width: 768px) {
  .c-hdg-04 {
    padding-bottom: 10px;
    font-size: 1.6rem;
  }
}

/* ==========================================================
 text comp
========================================================== */
/* c-txt-read
---------------------------------------------------------- */
.c-txt-read {
  margin-bottom: 40px;
}

.c-txt-read:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-txt-read {
    margin-bottom: 20px;
  }
}

/* c-txt-small
---------------------------------------------------------- */
.c-txt-small {
  font-size: 1.2rem;
  line-height: 1.83;
}

/* c-txt-note
---------------------------------------------------------- */
.c-txt-note {
  position: relative;
  padding-left: 15px;
  font-size: 1.2rem;
  line-height: 1.83;
}

.c-txt-note:before {
  position: absolute;
  content: "※";
  font-size: 12px;
  line-height: 1.83;
  top: 0;
  left: 0;
}

/* ==========================================================
 link
========================================================== */
/* c-link-banner
---------------------------------------------------------- */
.c-link-banner a {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 7px;
  background-color: #e2e6eb;
  background-image: url("/img/common/bg_bnr.jpg");
  background-position: right center;
  background-repeat: no-repeat;
  transition: opacity .2s linear;
}

.c-link-banner a:hover {
  opacity: 0.8;
  text-decoration: none;
}

.c-link-banner .image {
  width: 64px;
  margin-right: 20px;
}

.c-link-banner .image img {
  width: 100%;
}

.c-link-banner .text {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #009dd3;
}

/* c-link-text
---------------------------------------------------------- */
.c-link-text {
  position: relative;
  color: #009dd3;
  transition: opacity .2s;
}

.c-link-text:hover {
  opacity: 0.7;
}

.c-link-text.-newwindow {
  padding-right: 22px;
}

.c-link-text.-newwindow:before {
  content: "";
  position: absolute;
  top: 5px;
  right: 0;
  width: 15px;
  height: 15px;
  background-image: url("/img/common/ico_new_window.png");
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.c-link-text.-underline {
  text-decoration: underline;
}

.c-link-text.-underline:hover {
  opacity: 1;
  text-decoration: none;
}

/* ==========================================================
 button
========================================================== */
/* c-btn
---------------------------------------------------------- */
.c-btn {
  display: inline-block;
  position: relative;
  min-width: 200px;
  padding: 7px;
  border-radius: 5px;
  background-color: #009dd3;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.35);
  color: #fff;
  text-align: center;
  line-height: 1.86;
  transition: background-color .2s linear;
  cursor: pointer;
}

.c-btn:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #fff;
}

.c-btn:hover {
  background-color: #2ac0f3;
  box-shadow: inset 0 -1px #1eb0ef;
}

.c-btn.-gray {
  background-color: #96a1a5;
}

.c-btn.-gray:hover {
  background-color: #a2b6bd;
  box-shadow: inset 0 -1px #8b9fa6;
}

.c-btn.-expanding:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border: 0;
  background: url("/img/common/ico_plus.png") 0 0 no-repeat;
  background-size: 100%;
}

/* c-btn-download
---------------------------------------------------------- */
.c-btn-download a {
  display: block;
  padding: 15px;
  background-color: #2e3434;
  font-weight: bold;
  font-size: 1.4rem;
  color: #fff;
}

@media (min-width: 769px) {
  .c-btn-download a {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }
  .c-btn-download a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .c-btn-download a {
    padding: 25px 20px;
    border-bottom: 2px solid #232627;
    font-size: 1.5rem;
  }
}

.c-btn-download a .text {
  position: relative;
  display: inline-block;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .c-btn-download a .text {
    padding-left: 40px;
  }
}

.c-btn-download a .text:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 12px;
  height: 15px;
  background-image: url("/img/common/ico_document.png");
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .c-btn-download a .text:before {
    top: 0;
    width: 20px;
    height: 26px;
  }
}

@media (max-width: 768px) {
  .c-btn-download.-footer a {
    padding: 14px 20px;
    border-bottom: 1px solid #444849;
    font-size: 1.2rem;
    text-align: center;
  }
  .c-btn-download.-footer a .text {
    padding-left: 18px;
  }
  .c-btn-download.-footer a .text:before {
    top: 4px;
    width: 12px;
    height: 15px;
  }
}

/* ==========================================================
 list
========================================================== */
/* c-list-images
---------------------------------------------------------- */
.c-list-images {
  display: flex;
  flex-wrap: wrap;
  margin: -40px -20px 40px;
}

.c-list-images:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-list-images {
    margin: -16px -7px 16px;
  }
}

.c-list-images .item {
  width: 50%;
  margin-top: 40px;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .c-list-images .item {
    margin-top: 16px;
    padding: 0 7px;
  }
}

.c-list-images .item img {
  max-width: 100%;
}

.c-list-images .item.-full {
  width: 100%;
}

@media (max-width: 768px) {
  .c-list-images .item.-fullsp {
    width: 100%;
  }
  .c-list-images .item.-fullsp img {
    width: 100%;
  }
}

.c-list-images .title {
  display: flex;
  margin-bottom: 10px;
  line-height: 1.3;
}

/* c-list-imagesflex
---------------------------------------------------------- */
.c-list-imagesflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -30px -20px 30px;
}

.c-list-imagesflex:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-list-imagesflex {
    margin: -16px -7px 16px;
  }
}

.c-list-imagesflex .item {
  margin-top: 30px;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .c-list-imagesflex .item {
    margin-top: 16px;
    padding: 0 7px;
  }
  .c-list-imagesflex .item.-col6sp {
    width: 50%;
  }
  .c-list-imagesflex .item.-col6sp img {
    width: 100%;
  }
  .c-list-imagesflex .item.-fullsp {
    width: 100%;
  }
  .c-list-imagesflex .item.-fullsp .c-box-image {
    width: 100%;
  }
  .c-list-imagesflex .item.-fullsp img {
    width: 100%;
  }
}

.c-list-imagesflex .c-box-image {
  display: table;
  width: 100%;
}

.c-list-imagesflex .c-box-image .caption {
  display: table-caption;
  caption-side: bottom;
}

/* c-list-bullet
---------------------------------------------------------- */
.c-list-bullet .item {
  position: relative;
  padding-left: 12px;
}

.c-list-bullet .item:before {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #333;
}

/* ==========================================================
 table
========================================================== */
/* c-table-basic
---------------------------------------------------------- */
.c-table-basic {
  margin-bottom: 45px;
}

.c-table-basic:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-table-basic {
    margin-bottom: 35px;
  }
}

.c-table-basic table {
  width: 100%;
}

@media (max-width: 768px) {
  .c-table-basic table {
    display: block;
  }
}

@media (max-width: 768px) {
  .c-table-basic table tbody {
    display: block;
  }
}

@media (max-width: 768px) {
  .c-table-basic table tr {
    display: block;
  }
  .c-table-basic table tr:last-child td {
    border-bottom: 1px solid #cccccc;
  }
}

.c-table-basic table th {
  width: 20%;
  padding: 7px 13px 8px;
  background-color: #fbfbfb;
  border: 1px solid #cccccc;
}

.c-table-basic table th.-darker {
  background-color: #eee;
}

@media (max-width: 768px) {
  .c-table-basic table th {
    display: block;
    width: 100%;
    padding: 4px 10px;
    font-weight: 500;
  }
}

.c-table-basic table td {
  padding: 8px 13px 8px;
  border: 1px solid #cccccc;
}

@media (max-width: 768px) {
  .c-table-basic table td {
    display: block;
    padding: 4px 10px;
    border-top: 0;
    border-bottom: 0;
  }
}

.c-table-basic .note {
  margin-top: 15px;
}

/* c-table-tanker
---------------------------------------------------------- */
.c-table-tanker {
  margin-bottom: 45px;
}

@media (max-width: 768px) {
  .c-table-tanker {
    margin-bottom: 30px;
    padding-bottom: 10px;
    overflow-x: scroll;
    /* Track */
    /* Handle */
  }
  .c-table-tanker::-webkit-scrollbar {
    height: 12px;
  }
  .c-table-tanker::-webkit-scrollbar-track {
    background-color: #dadada;
    border-radius: 10px;
  }
  .c-table-tanker::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 10px;
  }
}

.c-table-tanker table {
  width: 100%;
  table-layout: fixed;
}

@media (max-width: 768px) {
  .c-table-tanker table {
    width: 980px;
  }
}

.c-table-tanker table thead th {
  padding: 10px 15px;
  background-color: #eee;
  border: 1px solid #ccc;
  text-align: center;
}

.c-table-tanker table thead th:first-child {
  width: 22%;
}

.c-table-tanker table tbody th {
  padding: 10px 15px;
  border: 1px solid #ccc;
}

.c-table-tanker table tbody td {
  padding: 10px 15px;
  border: 1px solid #ccc;
}

.c-table-tanker table tbody .bg-green {
  background-color: #f0f6f8;
}

/* ==========================================================
 box
========================================================== */
/* c-box-image
---------------------------------------------------------- */
.c-box-image {
  margin-bottom: 40px;
  text-align: center;
}

.c-box-image:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-box-image {
    margin-bottom: 30px;
  }
}

.c-box-image img {
  max-width: 100%;
}

.c-box-image .caption {
  margin-top: 12px;
  text-align: left;
}

.c-box-image .caption.-center {
  text-align: center;
}

.c-box-image .caption.-right {
  text-align: right;
}

@media (max-width: 768px) {
  .c-box-image .caption {
    margin-top: 5px;
    font-size: 1.2rem;
  }
}

/* c-box-card
---------------------------------------------------------- */
.c-box-card {
  overflow: hidden;
  margin-bottom: 60px;
}

.c-box-card:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-box-card {
    margin-bottom: 30px;
  }
}

.c-box-card .image img {
  width: 100%;
}

@media (min-width: 769px) {
  .c-box-card .image {
    float: left;
    width: 470px;
    margin-right: 40px;
    padding-top: 8px;
  }
}

@media (max-width: 768px) {
  .c-box-card .image {
    margin-bottom: 15px;
  }
}

.c-box-card .image .caption {
  margin-top: 12px;
}

@media (max-width: 768px) {
  .c-box-card .image .caption {
    margin-top: 5px;
    font-size: 1.2rem;
  }
}

.c-box-card .detail {
  overflow: hidden;
}

.c-box-card .detail .text {
  margin-bottom: 25px;
}

.c-box-card .detail .text:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-box-card .detail .text {
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
}

.c-box-card .detail .c-table-basic {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .c-box-card .detail .c-table-basic {
    margin-bottom: 25px;
  }
}

@media (min-width: 769px) {
  .c-box-card .detail .c-table-basic table th {
    width: 95px;
    padding: 10px;
  }
}

@media (min-width: 769px) and (min-width: 769px) {
  .c-box-card .detail .c-table-basic table th {
    text-align: center;
  }
}

@media (min-width: 769px) {
  .c-box-card .detail .c-table-basic table td {
    padding: 10px 15px;
  }
}

@media (max-width: 768px) {
  .c-box-card .detail .button {
    display: block;
    width: 100%;
  }
}

/* c-box-article
---------------------------------------------------------- */
.c-box-article {
  padding: 15px 25px 22px 28px;
  background-color: #d5e3e9;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .c-box-article {
    padding: 15px 20px;
    border-radius: 3px;
    font-size: 1.5rem;
  }
}

.c-box-article .title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 1.6rem;
}

@media (max-width: 768px) {
  .c-box-article .title {
    font-size: 1.5rem;
  }
}

/* c-box-contact
---------------------------------------------------------- */
.c-box-contact {
  margin-bottom: 55px;
  padding: 45px 15px 50px;
  background-color: #d5e3e9;
  border-radius: 5px;
  text-align: center;
}

.c-box-contact:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-box-contact {
    margin-bottom: 30px;
    padding: 25px 17px 30px;
  }
}

.c-box-contact .intro {
  margin-bottom: 20px;
  font-size: 1.6rem;
  line-height: 2;
}

@media (max-width: 768px) {
  .c-box-contact .intro {
    font-size: 1.4rem;
    line-height: 1.78;
  }
}

.c-box-contact .box-tel {
  display: inline-block;
  padding-left: 48px;
  margin: 3px 0 20px;
  background-image: url("/img/common/ico_tel_01.png");
  background-position: 0 center;
  background-repeat: no-repeat;
  color: #009dd3;
  text-align: left;
}

@media (max-width: 768px) {
  .c-box-contact .box-tel {
    padding-left: 35px;
    background-size: 25px auto;
  }
}

.c-box-contact .box-tel .number {
  display: block;
  margin-bottom: 6px;
  font-family: "Roboto Slab", serif;
  font-size: 3.7rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.08em;
}

@media (max-width: 768px) {
  .c-box-contact .box-tel .number {
    font-size: 3rem;
    letter-spacing: 0.04em;
  }
}

.c-box-contact .box-tel .time {
  display: block;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.08em;
}

@media (max-width: 768px) {
  .c-box-contact .box-tel .time {
    font-size: 1.4rem;
  }
}

.c-box-contact .button {
  display: inline-block;
  position: relative;
  width: 375px;
  min-width: 235px;
  padding: 11px 30px;
  background-color: #009dd3;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.35);
  border-radius: 50px;
  font-size: 1.8rem;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  transition: background-color .2s linear;
}

.c-box-contact .button:hover {
  background-color: #2ac0f3;
  box-shadow: inset 0 -1px #1eb0ef;
}

@media (max-width: 768px) {
  .c-box-contact .button {
    width: 295px;
    max-width: 100%;
    padding: 8px 15px;
    font-size: 1.5rem;
  }
}

/* c-box-detail
---------------------------------------------------------- */
.c-box-detail .heading {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

@media (max-width: 768px) {
  .c-box-detail .heading {
    padding-bottom: 0.8em;
    margin-bottom: 0.8em;
  }
}

input, textarea, select, button {
  font-family: "Noto Sans JP","ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

/* ==========================================================
 form component
========================================================== */
/* c-input-text
---------------------------------------------------------- */
.c-input-text {
  display: block;
  width: 452px;
  max-width: 100%;
  height: 30px;
  padding-left: 10px;
  border: 0;
  border-radius: 5px;
  background-color: #f4f4f4;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
  line-height: 30px;
  -webkit-appearance: none;
}

.c-input-text.-error {
  background-color: #ffeded;
}

@media (max-width: 768px) {
  .c-input-text {
    width: 100%;
  }
}

/* c-select
---------------------------------------------------------- */
.c-select {
  position: relative;
  width: 256px;
}

@media (max-width: 768px) {
  .c-select {
    width: 100%;
  }
}

.c-select .box-select {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  border-radius: 5px;
  background-color: #f4f4f4;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
  line-height: 2.3;
}

.c-select .box-select:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #333;
  top: 50%;
  right: 10px;
  margin-top: -4px;
}

.c-select select {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  height: 30px;
  opacity: 0;
}

/* c-radio
---------------------------------------------------------- */
/* c-checkbox
---------------------------------------------------------- */
/* c-textarea
---------------------------------------------------------- */
.c-textarea {
  display: block;
  width: 596px;
  max-width: 100%;
  height: 200px;
  padding-left: 10px;
  border: 0;
  border-radius: 5px;
  background-color: #f4f4f4;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
  line-height: 30px;
  -webkit-appearance: none;
  text-align: left;
}

.c-textarea.-error {
  background-color: #ffeded;
}

/* ==========================================================
 form layout
========================================================== */
/* c-form-layout
---------------------------------------------------------- */
.c-form-layout .row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

@media (max-width: 768px) {
  .c-form-layout .row {
    display: block;
    padding-bottom: 20px;
    margin-bottom: 15px;
  }
}

.c-form-layout .row:last-child {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .c-form-layout .row:last-child {
    margin-bottom: 25px;
  }
}

.c-form-layout .row .title {
  font-weight: 700;
}

@media (min-width: 769px) {
  .c-form-layout .row .title {
    flex: 0 0 220px;
  }
}

@media (max-width: 768px) {
  .c-form-layout .row .title {
    margin-bottom: 8px;
  }
}

@media (min-width: 769px) {
  .c-form-layout .row .detail {
    flex: 1 1 auto;
  }
}

.c-form-layout .btn-group {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .c-form-layout .btn-group {
    display: block;
  }
}

.c-form-layout .btn-group .item {
  padding: 0 20px;
}

@media (max-width: 768px) {
  .c-form-layout .btn-group .item {
    padding: 0;
    margin-bottom: 20px;
  }
  .c-form-layout .btn-group .item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .c-form-layout .btn-group .c-btn {
    width: 100%;
  }
}

.c-form-layout .box-name {
  display: flex;
}

@media (max-width: 768px) {
  .c-form-layout .box-name {
    display: block;
  }
}

.c-form-layout .box-name .col {
  width: 215px;
  margin-left: 20px;
}

.c-form-layout .box-name .col:first-child {
  margin-left: 0;
}

@media (max-width: 768px) {
  .c-form-layout .box-name .col {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .c-form-layout .box-name .col:last-child {
    margin-bottom: 0;
  }
}

.c-form-layout .box-name .col input {
  width: 100%;
}

.c-form-layout .txt-error {
  display: block;
  padding-top: 15px;
  color: #d50000;
  line-height: 1;
}

@media (max-width: 768px) {
  .c-form-layout .txt-error {
    padding-top: 10px;
  }
}

.c-form-layout .txt-error + .txt-error {
  display: none;
}

/* box-post
---------------------------------------------------------- */
.box-post {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .box-post {
    margin: 0 -15px;
  }
}

.box-post .col {
  position: relative;
}

@media (min-width: 769px) {
  .box-post .col:nth-child(1) {
    width: 85px;
    margin-right: 40px;
  }
  .box-post .col:nth-child(1):after {
    content: "–";
    position: absolute;
    top: 50%;
    right: -23px;
    transform: translateY(-50%);
    color: #000;
  }
  .box-post .col:nth-child(2) {
    width: 95px;
    margin-right: 20px;
  }
  .box-post .col:nth-child(3) {
    width: 115px;
  }
  .box-post .col:nth-child(3) .c-btn {
    min-width: 100%;
    padding: 2px;
  }
}

@media (max-width: 768px) {
  .box-post .col:nth-child(1) {
    width: 50%;
    padding: 0 15px;
  }
  .box-post .col:nth-child(1):after {
    content: "–";
    position: absolute;
    top: 50%;
    right: -3px;
    transform: translateY(-50%);
    color: #000;
  }
  .box-post .col:nth-child(2) {
    width: 50%;
    padding: 0 15px;
  }
  .box-post .col:nth-child(3) {
    width: 100%;
    margin-top: 15px;
    padding: 0 15px;
  }
  .box-post .col:nth-child(3) .c-btn {
    width: 100%;
    min-width: 100%;
  }
}

/* ==========================================================
 section
========================================================== */
/* c-sec-parts
---------------------------------------------------------- */
.c-sec-parts {
  position: relative;
  margin-bottom: 50px;
}

.c-sec-parts .heading-part {
  padding: 5px 30px;
  margin-bottom: 20px;
  background-color: #555;
  color: #fff;
}

@media (max-width: 768px) {
  .c-sec-parts .heading-part {
    margin-bottom: 15px;
  }
}

.c-sec-parts:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-sec-parts {
    margin-bottom: 40px;
  }
}

/* c-sec-content
---------------------------------------------------------- */
.c-sec-content {
  margin-bottom: 60px;
}

.c-sec-content:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .c-sec-content {
    margin-bottom: 40px;
  }
}

/* ==========================================================
 mainvisual
========================================================== */
/* c-mainvisual
---------------------------------------------------------- */
.c-mainvisual {
  position: relative;
}

.c-mainvisual .bg {
  height: 300px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.c-mainvisual .bg img {
  opacity: 0;
}

@media (max-width: 768px) {
  .c-mainvisual .bg {
    height: 105px;
  }
}

@media (min-width: 769px) {
  .c-mainvisual.-short .bg {
    height: 200px;
  }
}

.c-mainvisual .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-mainvisual .title .jp {
  display: block;
  margin-bottom: 15px;
  font-family: YuMincho, "游明朝", "Times New Roman", "Hiragino Mincho Pro", "ヒラギノ明朝 Pro W3", "HG明朝B", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 3.2rem;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  letter-spacing: 0.08em;
  text-align: center;
}

.c-mainvisual .title .jp.-white {
  color: #fff;
}

@media (max-width: 768px) {
  .c-mainvisual .title .jp {
    margin-bottom: 8px;
    font-size: 2.2rem;
  }
}

.c-mainvisual .title .en {
  display: block;
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  color: #009dd3;
  line-height: 1;
  letter-spacing: 0.08em;
  text-align: center;
}

.c-mainvisual .title .en.-white {
  color: #fff;
}

@media (max-width: 768px) {
  .c-mainvisual .title .en {
    font-size: 1.2rem;
  }
}

/* ==========================================================
 module
========================================================== */
/* c-mod-project
---------------------------------------------------------- */
.c-mod-project {
  border: 1px solid #ccc;
  padding: 25px 30px 30px;
}

@media (max-width: 768px) {
  .c-mod-project {
    padding: 15px 20px 20px;
  }
}

.c-mod-project .article {
  margin-bottom: 20px;
}

.c-mod-project .article .title {
  margin-bottom: 15px;
  font-size: 1.6rem;
}

@media (max-width: 768px) {
  .c-mod-project .article .title {
    margin-bottom: 15px;
  }
}

@media (min-width: 769px) {
  .c-mod-project .article .desc {
    line-height: 1.6;
  }
}

.c-mod-project .article .note {
  font-size: 1.2rem;
}

.c-mod-project .list-flow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

@media (max-width: 768px) {
  .c-mod-project .list-flow {
    margin: -15px -7px 0;
  }
}

.c-mod-project .list-flow .item {
  position: relative;
  width: 25%;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .c-mod-project .list-flow .item {
    width: 50%;
    padding: 0 7px;
    margin-top: 15px;
  }
}

.c-mod-project .list-flow .item:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -7px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 14px solid #009dd3;
}

@media (max-width: 768px) {
  .c-mod-project .list-flow .item:after {
    right: -4px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid #009dd3;
  }
}

.c-mod-project .list-flow .item:last-child:after {
  display: none;
}

.c-mod-project .list-flow .item img {
  width: 100%;
}

/* c-modal
---------------------------------------------------------- */
.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  overflow-y: auto;
  width: 100%;
  padding: 150px 20px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
}

.c-modal.is-show {
  z-index: 1000;
  visibility: visible;
  opacity: 1;
}

.c-modal .modal-content {
  position: relative;
  padding: 20px;
  background-color: #fff;
}

.c-modal .modal-content .btn-close {
  content: "";
  position: absolute;
  top: -30px;
  right: 0;
  width: 18px;
  height: 18px;
  overflow: hidden;
  background: url("/img/common/ico_close_modal.png") 0 0 no-repeat;
  background-size: 100%;
  text-indent: -9999px;
}

.c-modal .image {
  margin-bottom: 15px;
}

.c-modal .image img {
  width: 100%;
}

.c-modal .title {
  margin-bottom: 5px;
  font-weight: 700;
}

.c-modal .title:last-child {
  margin-bottom: 0;
}

/* ==========================================================
 pages
========================================================== */
@media (min-width: 769px) {
  #top .wrapper, #top .footer {
    min-width: 1320px;
  }
}

/* ==========================================================
 Top
========================================================== */
/* ttl-top
---------------------------------------------------------- */
.ttl-top {
  margin-bottom: 20px;
  font-family: YuMincho, "游明朝", "Times New Roman", "Hiragino Mincho Pro", "ヒラギノ明朝 Pro W3", "HG明朝B", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 2.2rem;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 768px) {
  .ttl-top {
    margin-bottom: 15px;
    font-size: 2rem;
    text-align: center;
  }
}

/* contents-toppage
---------------------------------------------------------- */
.contents-toppage {
  background-image: url("/img/top/bg_top_body.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 769px) {
  .contents-toppage .wrap {
    display: flex;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  .contents-toppage .wrap .mainvisual {
    width: 50%;
  }
  .contents-toppage .wrap .main {
    width: 50%;
  }
  .contents-toppage .wrap .main .sec-top-products {
    height: 55%;
  }
  .contents-toppage .wrap .main .sec-top-solution {
    height: 45%;
  }
}

/* mainvisual
---------------------------------------------------------- */
.mainvisual {
  position: relative;
}

.mainvisual .bg {
  position: relative;
  padding-top: 157%;
}

@media (max-width: 768px) {
  .mainvisual .bg {
    padding-top: 0;
    height: 460px;
  }
}

.mainvisual .bg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.mainvisual .box-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .mainvisual .box-text {
    width: 345px;
    padding: 0 20px;
  }
}

.mainvisual .box-text .title {
  display: inline-block;
  padding: 0 65px 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  font-family: "Roboto Slab", serif;
  font-size: 5.9rem;
  color: #fff;
  line-height: 1.1;
}

@media (max-width: 768px) {
  .mainvisual .box-text .title {
    display: block;
    padding: 0 20px 10px;
    margin-bottom: 15px;
    font-size: 5.2rem;
  }
}

.mainvisual .box-text .title .blue {
  font-size: 6.6rem;
  color: #009dd3;
}

@media (max-width: 768px) {
  .mainvisual .box-text .title .blue {
    font-size: 5.5rem;
  }
}

.mainvisual .box-text .title.-english {
  padding-bottom: 0;
  border-bottom: 0;
}

@media (min-width: 769px) {
  .mainvisual .box-text .title.-english {
    font-size: 7.2rem;
  }
  .mainvisual .box-text .title.-english .blue {
    font-size: 8rem;
  }
}

.mainvisual .box-text .desc {
  font-family: YuMincho, "游明朝", "Times New Roman", "Hiragino Mincho Pro", "ヒラギノ明朝 Pro W3", "HG明朝B", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: 500;
  font-size: 2.7rem;
  color: #fff;
  line-height: 1.5;
  letter-spacing: -0.05em;
}

@media (max-width: 768px) {
  .mainvisual .box-text .desc {
    font-size: 2rem;
  }
}

/* sec-top-products
---------------------------------------------------------- */
.sec-top-products {
  position: relative;
  background-color: #009dd3;
}

@media (max-width: 768px) {
  .sec-top-products {
    padding: 25px 0;
  }
}

.sec-top-products:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 442px;
  height: 270px;
  background: url("/img/top/bg_polygon_top_pc.jpg") 0 0 no-repeat;
  background-size: 100% auto;
}

.sec-top-products:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 522px;
  height: 280px;
  background: url("/img/top/bg_polygon_bottom_pc.jpg") 0 bottom no-repeat;
  background-size: 100% auto;
}

.sec-top-products .cont {
  position: relative;
  z-index: 1;
}

@media (min-width: 769px) {
  .sec-top-products .cont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.sec-top-products .ttl-top {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .sec-top-products .ttl-top {
    padding-left: 0;
  }
}

/* mod-slider-products
---------------------------------------------------------- */
.mod-slider-products {
  position: relative;
  z-index: 1;
  width: 560px;
  max-width: 100%;
  min-height: 370px;
  padding: 0 20px;
  visibility: hidden;
}

@media (max-width: 768px) {
  .mod-slider-products {
    width: 375px;
    min-height: 460px;
    margin-left: auto;
    margin-right: auto;
  }
}

.mod-slider-products .card-products {
  position: relative;
  background-color: #fff;
}

@media (max-width: 768px) {
  .mod-slider-products .card-products {
    height: 400px;
  }
}

.mod-slider-products .card-products .image img {
  width: 100%;
}

.mod-slider-products .card-products .detail {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
  width: 245px;
  padding-right: 20px;
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  .mod-slider-products .card-products .detail {
    top: inherit;
    right: inherit;
    bottom: 0;
    transform: none;
    width: 100%;
    padding: 20px 20px 75px;
  }
}

.mod-slider-products .card-products .title {
  margin-bottom: 10px;
  font-size: 2.2rem;
  font-weight: 700;
  color: #000;
  line-height: 1.3;
}

@media (max-width: 768px) {
  .mod-slider-products .card-products .title {
    margin-bottom: 8px;
    font-size: 2rem;
  }
}

.mod-slider-products .card-products .text {
  font-weight: 300;
}

.mod-slider-products .card-products .text.-english {
  font-size: 1.3rem;
  line-height: 1.7;
}

@media (max-width: 768px) {
  .mod-slider-products .card-products .text {
    line-height: 1.78;
  }
}

.mod-slider-products .card-products .button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 2;
}

@media (max-width: 768px) {
  .mod-slider-products .card-products .button {
    left: 0;
    right: inherit;
    width: 100%;
    padding: 0 20px;
  }
}

.mod-slider-products .slider-wrap {
  position: relative;
}

.mod-slider-products .slider {
  display: flex;
  background-color: #fff;
}

.mod-slider-products .slider .item {
  transform: translateZ(0);
  backface-visibility: hidden;
  outline: none;
}

.mod-slider-products .slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 90px;
  background-size: 100%;
  background-position: 0;
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  cursor: pointer;
}

@media (min-width: 769px) {
  .mod-slider-products .slider-btn {
    visibility: hidden;
  }
}

.mod-slider-products .slider-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity .2s;
}

.mod-slider-products .slider-btn.-prev {
  left: -20px;
  background-image: url("/img/top/btn_prev.png");
}

.mod-slider-products .slider-btn.-next {
  right: -20px;
  background-image: url("/img/top/btn_next.png");
}

.mod-slider-products .slider-btn:hover:after {
  opacity: 1;
}

.mod-slider-products .slider-thumb .slick-dots {
  display: flex;
}

.mod-slider-products .slider-thumb li {
  position: relative;
  width: 16.67%;
  margin-left: 1px;
  padding-top: 30px;
}

.mod-slider-products .slider-thumb li:first-child {
  margin-left: 0;
}

.mod-slider-products .slider-thumb li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #000;
  opacity: 0;
  backface-visibility: hidden;
  transition: opacity .2s;
}

.mod-slider-products .slider-thumb li .thumb {
  position: relative;
  cursor: pointer;
}

.mod-slider-products .slider-thumb li .thumb:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  opacity: 1;
  backface-visibility: hidden;
  transition: opacity .2s;
}

.mod-slider-products .slider-thumb li .thumb img {
  width: 100%;
}

.mod-slider-products .slider-thumb li.slick-active:before, .mod-slider-products .slider-thumb li:hover:before {
  opacity: 1;
}

.mod-slider-products .slider-thumb li.slick-active .thumb:before, .mod-slider-products .slider-thumb li:hover .thumb:before {
  opacity: 0;
}

/* sec-top-solution
---------------------------------------------------------- */
.sec-top-solution {
  position: relative;
  background: url("/img/top/bg_sec_solution.jpg") 0 0 no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .sec-top-solution {
    padding: 30px 20px;
  }
}

@media (min-width: 769px) {
  .sec-top-solution .cont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 520px;
  }
}

@media (max-width: 1280px) {
  .sec-top-solution .cont {
    width: 100%;
    padding: 0 20px;
  }
  .sec-top-solution .cont.-english {
    padding: 0 10px;
    margin-top: -3px;
  }
}

@media (max-width: 768px) {
  .sec-top-solution .cont {
    padding: 0;
  }
}

.sec-top-solution .ttl-top {
  margin-bottom: 10px;
}

.sec-top-solution .list-solution .item {
  margin-bottom: 1px;
}

.sec-top-solution .list-solution .item:last-child {
  margin-bottom: 0;
}

.sec-top-solution .list-solution a {
  position: relative;
  display: block;
  padding: 6px 10px 6px 15px;
  background-color: rgba(0, 0, 0, 0.1);
  border-left: 20px solid #009dd3;
  color: #fff;
  line-height: 1.7;
  font-weight: 200;
  letter-spacing: 0.08em;
}

@media (max-width: 768px) {
  .sec-top-solution .list-solution a {
    border-width: 15px;
    padding: 13px;
  }
}

.sec-top-solution .list-solution a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -13px;
  width: 0;
  height: 0;
  transform: translateY(-50%);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #fff;
}

@media (max-width: 768px) {
  .sec-top-solution .list-solution a:before {
    left: -9px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 4px solid #fff;
  }
}

.sec-top-solution .list-solution a:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity 0.2s;
}

.sec-top-solution .list-solution a:hover:after {
  opacity: 1;
}

/* sec-top-news
---------------------------------------------------------- */
.sec-top-news {
  display: flex;
}

@media (max-width: 768px) {
  .sec-top-news {
    display: block;
    padding: 30px 20px;
  }
}

.sec-top-news .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 200px;
  background-color: #d5e3e9;
  font-size: 2.4rem;
  color: #009dd3;
  line-height: 1;
  text-align: center;
}

@media (max-width: 768px) {
  .sec-top-news .heading {
    margin-bottom: 25px;
    display: block;
    background-color: transparent;
    font-size: 2rem;
    color: #000;
  }
}

.sec-top-news .detail {
  flex: 1 1 auto;
  padding: 12px 20px;
}

@media (max-width: 768px) {
  .sec-top-news .detail {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .sec-top-news .list-news .item {
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
  }
  .sec-top-news .list-news .item:last-child {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.sec-top-news .list-news .date {
  flex: 0 0 100px;
  letter-spacing: 0.1em;
}

.sec-top-news .list-news .text {
  flex: 1 0 auto;
}

.sec-top-news .list-news .row {
  display: inline-flex;
  color: #333;
  font-weight: 300;
  transition: opacity .2s;
}

@media (max-width: 768px) {
  .sec-top-news .list-news .row {
    display: block;
  }
}

/* ==========================================================
 Strength
========================================================== */
/* box-strength-intro
---------------------------------------------------------- */
.box-strength-intro {
  margin-bottom: 60px;
  padding: 60px 130px;
  background-image: url("/img/strength/bg_strength_intro.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .box-strength-intro {
    margin-bottom: 35px;
    padding: 15px;
  }
}

.box-strength-intro .inner .bar-top {
  position: relative;
  padding-left: 40px;
  top: 1px;
}

@media (max-width: 768px) {
  .box-strength-intro .inner .bar-top {
    padding-left: 20px;
  }
}

.box-strength-intro .inner .bar-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 41px solid transparent;
  border-right: 41px solid #fff;
}

@media (max-width: 768px) {
  .box-strength-intro .inner .bar-top:before {
    border-top-width: 21px;
    border-right-width: 21px;
  }
}

.box-strength-intro .inner .bar-top span {
  display: block;
  height: 41px;
  background-color: #fff;
}

@media (max-width: 768px) {
  .box-strength-intro .inner .bar-top span {
    height: 21px;
  }
}

.box-strength-intro .inner .bar-bottom {
  position: relative;
  padding-right: 40px;
  bottom: 1px;
}

@media (max-width: 768px) {
  .box-strength-intro .inner .bar-bottom {
    padding-right: 20px;
  }
}

.box-strength-intro .inner .bar-bottom:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 41px solid transparent;
  border-left: 41px solid #fff;
}

@media (max-width: 768px) {
  .box-strength-intro .inner .bar-bottom:before {
    border-bottom-width: 21px;
    border-left-width: 21px;
  }
}

.box-strength-intro .inner .bar-bottom span {
  display: block;
  height: 41px;
  background-color: #fff;
}

@media (max-width: 768px) {
  .box-strength-intro .inner .bar-bottom span {
    height: 21px;
  }
}

.box-strength-intro .cont {
  padding: 15px 50px;
  background-color: #fff;
  transform: translateZ(0);
}

@media (max-width: 768px) {
  .box-strength-intro .cont {
    padding: 5px 10px;
  }
}

.box-strength-intro .title {
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 500;
  color: #000;
  line-height: 1.7;
  text-align: center;
  letter-spacing: -0.01em;
}

@media (max-width: 768px) {
  .box-strength-intro .title {
    margin-bottom: 15px;
    font-size: 1.6rem;
    line-height: 1.7;
    letter-spacing: -0.02em;
  }
}

@media (max-width: 360px) {
  .box-strength-intro .title {
    font-size: 1.5rem;
  }
}

.box-strength-intro .text {
  text-align: center;
}

@media (max-width: 768px) {
  .box-strength-intro .text {
    padding: 0 5px;
    font-size: 1.2rem;
    text-align: left;
  }
}

/* sec-strength
---------------------------------------------------------- */
.sec-strength {
  margin-bottom: 80px;
  overflow: hidden;
}

.sec-strength:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .sec-strength {
    margin-bottom: 30px;
  }
}

.sec-strength .box-detail {
  position: relative;
  z-index: 1;
}

@media (min-width: 769px) {
  .sec-strength .box-detail {
    width: 600px;
  }
}

@media (max-width: 768px) {
  .sec-strength .box-detail {
    margin-bottom: 10px;
  }
}

.sec-strength .box-detail .title {
  margin-bottom: 30px;
}

.sec-strength .box-detail .title img {
  max-width: 100%;
}

@media (max-width: 768px) {
  .sec-strength .box-detail .title {
    margin-bottom: 10px;
  }
}

.sec-strength .box-detail .text {
  margin-bottom: 25px;
}

.sec-strength .box-detail .text:last-child {
  margin-bottom: 0;
}

.sec-strength .image {
  position: relative;
}

@media (min-width: 769px) {
  .sec-strength .image {
    width: 640px;
    padding-top: 30px;
  }
}

@media (max-width: 768px) {
  .sec-strength .image .button {
    margin-top: -20px;
    padding: 0 20px;
  }
}

.sec-strength .image img {
  width: 100%;
}

@media (min-width: 769px) {
  .sec-strength.-imageright .box-detail {
    float: left;
  }
  .sec-strength.-imageright .box-detail .text {
    padding-right: 290px;
  }
  .sec-strength.-imageright .box-detail .button {
    padding-right: 290px;
  }
  .sec-strength.-imageright .image {
    float: right;
    margin-left: -260px;
  }
}

@media (min-width: 769px) {
  .sec-strength.-imageleft .box-detail {
    float: right;
  }
  .sec-strength.-imageleft .box-detail .title {
    text-align: right;
  }
  .sec-strength.-imageleft .box-detail .text {
    padding-left: 290px;
  }
  .sec-strength.-imageleft .image {
    float: left;
    margin-right: -260px;
  }
}

/* ==========================================================
 Products
========================================================== */
/* list-products-nav
---------------------------------------------------------- */
@media (min-width: 769px) {
  .list-products-nav {
    display: flex;
    flex-wrap: wrap;
    margin: -40px -20px 0;
  }
}

@media (min-width: 769px) {
  .list-products-nav .item {
    width: 33.33%;
    padding: 0 20px;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .list-products-nav .item {
    margin-bottom: 30px;
  }
  .list-products-nav .item:last-child {
    margin-bottom: 0;
  }
}

.list-products-nav .image img {
  width: 100%;
}

.list-products-nav .button {
  margin-top: -20px;
  margin-bottom: 15px;
  text-align: center;
}

@media (max-width: 768px) {
  .list-products-nav .button {
    padding: 0 20px;
  }
}

/* list-images-forgings
---------------------------------------------------------- */
.list-images-forgings {
  display: flex;
  justify-content: center;
  margin: -30px -20px 30px;
}

.list-images-forgings:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .list-images-forgings {
    flex-wrap: wrap;
    margin: -16px -7px 16px;
  }
}

.list-images-forgings .item {
  margin-top: 30px;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .list-images-forgings .item {
    width: 50%;
    margin-top: 16px;
    padding: 0 7px;
  }
  .list-images-forgings .item:nth-child(1) {
    width: 68.7%;
  }
  .list-images-forgings .item:nth-child(2) {
    width: 31.3%;
  }
  .list-images-forgings .item .c-box-image img {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .list-images-forgings .c-box-image {
    display: table;
  }
  .list-images-forgings .c-box-image .caption {
    display: table-caption;
    caption-side: bottom;
  }
}

/* table-powders
---------------------------------------------------------- */
@media (max-width: 768px) {
  .table-powders {
    padding-bottom: 10px;
    overflow-x: scroll;
    /* Track */
    /* Handle */
  }
  .table-powders::-webkit-scrollbar {
    height: 12px;
  }
  .table-powders::-webkit-scrollbar-track {
    background-color: #dadada;
    border-radius: 10px;
  }
  .table-powders::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 10px;
  }
}

.table-powders table {
  width: 100%;
}

@media (max-width: 768px) {
  .table-powders table {
    width: 980px;
  }
}

.table-powders table th, .table-powders table td {
  padding: 10px 14px;
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
}

.table-powders table th {
  background-color: #eee;
}

/* ==========================================================
 Case
========================================================== */
/* box-case-info
---------------------------------------------------------- */
.box-case-info {
  position: relative;
  min-height: 305px;
  padding: 35px 30px 30px;
  border: 1px solid #ccc;
  border-top: 0;
  margin-bottom: 40px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .box-case-info {
    padding: 25px 0 0;
  }
}

.box-case-info:last-child {
  margin-bottom: 0;
}

.box-case-info:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 6px;
  background-color: #009dd3;
}

@media (max-width: 768px) {
  .box-case-info:before {
    height: 5px;
  }
}

@media (min-width: 769px) {
  .box-case-info .detail {
    width: 510px;
  }
}

@media (max-width: 768px) {
  .box-case-info .detail {
    padding: 0 20px;
    margin-bottom: 15px;
  }
}

.box-case-info .detail .title {
  margin-bottom: 10px;
  font-size: 2.4rem;
}

@media (max-width: 768px) {
  .box-case-info .detail .title {
    font-size: 1.8rem;
  }
}

.box-case-info .detail .desc {
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .box-case-info .detail .desc {
    margin-bottom: 20px;
  }
}

.box-case-info .detail .btn-group {
  display: flex;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .box-case-info .detail .btn-group {
    display: block;
    margin-bottom: 15px;
  }
  .box-case-info .detail .btn-group:last-child {
    margin-bottom: 0;
  }
}

.box-case-info .detail .btn-group .button {
  margin-left: 20px;
}

.box-case-info .detail .btn-group .button:first-child {
  margin-left: 0;
}

@media (min-width: 769px) {
  .box-case-info .detail .btn-group .button {
    width: 220px;
  }
}

@media (max-width: 768px) {
  .box-case-info .detail .btn-group .button {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .box-case-info .detail .btn-group .button:last-child {
    margin-bottom: 0;
  }
}

.box-case-info .image {
  text-align: right;
}

@media (min-width: 769px) {
  .box-case-info .image {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.box-case-info .image img {
  max-width: 100%;
}

.box-case-info .image.-center {
  text-align: center;
}

/* ==========================================================
 Company
========================================================== */
/* sec-comp-info
---------------------------------------------------------- */
.sec-comp-info {
  margin-bottom: 60px;
}

.sec-comp-info:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .sec-comp-info {
    margin-bottom: 50px;
  }
}

.sec-comp-info .heading {
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .sec-comp-info .heading {
    margin-bottom: 15px;
  }
}

.sec-comp-info .row {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
}

@media (min-width: 769px) {
  .sec-comp-info .row {
    display: flex;
  }
}

.sec-comp-info .title {
  font-weight: 500;
}

@media (min-width: 769px) {
  .sec-comp-info .title {
    flex: 0 0 130px;
  }
}

@media (min-width: 769px) {
  .sec-comp-info .detail {
    flex: 1 1 auto;
  }
}

.sec-comp-info .detail a {
  color: #333;
}

/* ol comp
---------------------------------------------------------- */
.ol-comp-01 > .item {
  position: relative;
  counter-increment: number01;
  padding-left: 25px;
  margin-bottom: 10px;
}

.ol-comp-01 > .item:last-child {
  margin-bottom: 0;
}

.ol-comp-01 > .item:before {
  position: absolute;
  content: counter(number01) " -";
  top: 0;
  left: 0;
  font-weight: bold;
  color: #96a1a5;
}

.ol-comp-01 > .item > .title {
  font-weight: 700;
}

.ol-comp-02 > .item {
  position: relative;
  counter-increment: number02;
  padding-left: 15px;
}

.ol-comp-02 > .item:last-child {
  margin-bottom: 0;
}

.ol-comp-02 > .item:before {
  position: absolute;
  content: counter(number02) ".";
  top: 0;
  left: 0;
}

.ol-comp-03 > .item {
  margin-bottom: 12px;
  counter-increment: number03;
  overflow: hidden;
}

.ol-comp-03 > .item:last-child {
  margin-bottom: 0;
}

.ol-comp-03 > .item > .title {
  position: relative;
  display: block;
  padding-left: 18px;
  font-weight: 700;
}

.ol-comp-03 > .item > .title:before {
  content: counter(number03) ".";
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
}

/* box-comp-address
---------------------------------------------------------- */
@media (min-width: 769px) {
  .box-comp-address {
    display: flex;
    align-items: flex-start;
  }
}

@media (min-width: 769px) {
  .box-comp-address .box-address {
    flex: 0 0 340px;
    padding-right: 40px;
  }
}

@media (max-width: 768px) {
  .box-comp-address .box-address {
    margin-bottom: 15px;
  }
}

.box-comp-address .box-address .row {
  margin-bottom: 25px;
}

.box-comp-address .box-address .row:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .box-comp-address .box-address .row {
    margin-bottom: 15px;
  }
}

.box-comp-address .box-address .title {
  margin-bottom: 10px;
}

.box-comp-address .box-map {
  position: relative;
  padding-top: 42%;
  overflow: hidden;
}

@media (min-width: 769px) {
  .box-comp-address .box-map {
    flex: 1 1 auto;
  }
}

@media (max-width: 768px) {
  .box-comp-address .box-map {
    padding-top: 60%;
  }
}

.box-comp-address .box-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

@media (min-width: 769px) {
  .box-comp-address .box-map iframe {
    height: 100%;
  }
}

@media (max-width: 768px) {
  .box-comp-address .box-map iframe {
    bottom: 0;
  }
}

/* box-comp-content
---------------------------------------------------------- */
.box-comp-content {
  overflow: hidden;
}

.box-comp-content .image {
  float: right;
}

@media (max-width: 768px) {
  .box-comp-content .image {
    float: none;
    margin-top: 20px;
  }
}

/* ==========================================================
 Sitemap
========================================================== */
/* list-sitemap
---------------------------------------------------------- */
.list-sitemap .item {
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 1px solid #ccc;
}

@media (max-width: 768px) {
  .list-sitemap .item {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.list-sitemap .item a {
  position: relative;
  padding-left: 12px;
  color: #666;
  transition: opacity .2s;
}

.list-sitemap .item a:hover {
  opacity: 0.7;
}

.list-sitemap .item a:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 6px solid #199ed1;
}

.list-sitemap > .item:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .list-sitemap > .item:last-child {
    padding-bottom: 12px;
  }
}

.list-sitemap .box-sub .list-sitemap .item:first-child {
  padding-top: 0;
}

.list-sitemap .box-sub .list-sitemap .item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

@media (min-width: 769px) {
  .list-sitemap .box-sub {
    display: flex;
  }
  .list-sitemap .box-sub .link {
    flex: 0 0 170px;
  }
  .list-sitemap .box-sub .list-sub {
    flex: 1 1 auto;
  }
}

@media (max-width: 768px) {
  .list-sitemap .box-sub .link {
    padding-bottom: 10px;
  }
  .list-sitemap .box-sub .list-sub {
    padding-left: 20px;
  }
}

.list-sitemap.-level3 .item {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

@media (max-width: 768px) {
  .list-sitemap.-level3 .item {
    margin-bottom: 3px;
  }
}

/* ==========================================================
 Contribution
========================================================== */
/* box-contribution-map
---------------------------------------------------------- */
.box-contribution-map {
  height: 500px;
}

@media (max-width: 768px) {
  .box-contribution-map {
    height: 300px;
  }
}

.card-infowindow {
  width: 300px;
}

.card-infowindow .image {
  margin-bottom: 12px;
}

.card-infowindow .image img {
  width: 100%;
}

.card-infowindow .title {
  margin-bottom: 5px;
  font-weight: 700;
}

/* ==========================================================
 utils
========================================================== */
.s-hide {
  display: none;
}

.s-show {
  display: block;
}

/* ==========================================================
 rwd switch
========================================================== */
@media (min-width: 769px) {
  .only-sp {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .only-pc {
    display: none !important;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (max-width: 768px) {
  .d-sp-none {
    display: none !important;
  }
  .d-sp-inline {
    display: inline !important;
  }
  .d-sp-inline-block {
    display: inline-block !important;
  }
  .d-sp-block {
    display: block !important;
  }
  .d-sp-table {
    display: table !important;
  }
  .d-sp-table-row {
    display: table-row !important;
  }
  .d-sp-table-cell {
    display: table-cell !important;
  }
  .d-sp-flex {
    display: flex !important;
  }
  .d-sp-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (max-width: 768px) {
  .flex-sp-row {
    flex-direction: row !important;
  }
  .flex-sp-column {
    flex-direction: column !important;
  }
  .flex-sp-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sp-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sp-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sp-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sp-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sp-fill {
    flex: 1 1 auto !important;
  }
  .flex-sp-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sp-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sp-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sp-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sp-start {
    justify-content: flex-start !important;
  }
  .justify-content-sp-end {
    justify-content: flex-end !important;
  }
  .justify-content-sp-center {
    justify-content: center !important;
  }
  .justify-content-sp-between {
    justify-content: space-between !important;
  }
  .justify-content-sp-around {
    justify-content: space-around !important;
  }
  .align-items-sp-start {
    align-items: flex-start !important;
  }
  .align-items-sp-end {
    align-items: flex-end !important;
  }
  .align-items-sp-center {
    align-items: center !important;
  }
  .align-items-sp-baseline {
    align-items: baseline !important;
  }
  .align-items-sp-stretch {
    align-items: stretch !important;
  }
  .align-content-sp-start {
    align-content: flex-start !important;
  }
  .align-content-sp-end {
    align-content: flex-end !important;
  }
  .align-content-sp-center {
    align-content: center !important;
  }
  .align-content-sp-between {
    align-content: space-between !important;
  }
  .align-content-sp-around {
    align-content: space-around !important;
  }
  .align-content-sp-stretch {
    align-content: stretch !important;
  }
  .align-self-sp-auto {
    align-self: auto !important;
  }
  .align-self-sp-start {
    align-self: flex-start !important;
  }
  .align-self-sp-end {
    align-self: flex-end !important;
  }
  .align-self-sp-center {
    align-self: center !important;
  }
  .align-self-sp-baseline {
    align-self: baseline !important;
  }
  .align-self-sp-stretch {
    align-self: stretch !important;
  }
  .flex-grow-sp-auto {
    flex: 0 0 auto !important;
  }
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.ma-0 {
  margin: 0px !important;
}

.pa-0 {
  padding: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.ma-0 {
  margin: 0px !important;
}

.pa-0 {
  padding: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.ma-0 {
  margin: 0px !important;
}

.pa-0 {
  padding: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.ma-0 {
  margin: 0px !important;
}

.pa-0 {
  padding: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.ma-5 {
  margin: 5px !important;
}

.pa-5 {
  padding: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.ma-5 {
  margin: 5px !important;
}

.pa-5 {
  padding: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.ma-5 {
  margin: 5px !important;
}

.pa-5 {
  padding: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.ma-5 {
  margin: 5px !important;
}

.pa-5 {
  padding: 5px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.ma-8 {
  margin: 8px !important;
}

.pa-8 {
  padding: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.ma-8 {
  margin: 8px !important;
}

.pa-8 {
  padding: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.ma-8 {
  margin: 8px !important;
}

.pa-8 {
  padding: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.ma-8 {
  margin: 8px !important;
}

.pa-8 {
  padding: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.ma-10 {
  margin: 10px !important;
}

.pa-10 {
  padding: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.ma-10 {
  margin: 10px !important;
}

.pa-10 {
  padding: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.ma-10 {
  margin: 10px !important;
}

.pa-10 {
  padding: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.ma-10 {
  margin: 10px !important;
}

.pa-10 {
  padding: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.ma-12 {
  margin: 12px !important;
}

.pa-12 {
  padding: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.ma-12 {
  margin: 12px !important;
}

.pa-12 {
  padding: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.ma-12 {
  margin: 12px !important;
}

.pa-12 {
  padding: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.ma-12 {
  margin: 12px !important;
}

.pa-12 {
  padding: 12px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.pt-13 {
  padding-top: 13px !important;
}

.ma-13 {
  margin: 13px !important;
}

.pa-13 {
  padding: 13px !important;
}

.mb-13 {
  margin-bottom: 13px !important;
}

.pb-13 {
  padding-bottom: 13px !important;
}

.ma-13 {
  margin: 13px !important;
}

.pa-13 {
  padding: 13px !important;
}

.ml-13 {
  margin-left: 13px !important;
}

.pl-13 {
  padding-left: 13px !important;
}

.ma-13 {
  margin: 13px !important;
}

.pa-13 {
  padding: 13px !important;
}

.mr-13 {
  margin-right: 13px !important;
}

.pr-13 {
  padding-right: 13px !important;
}

.ma-13 {
  margin: 13px !important;
}

.pa-13 {
  padding: 13px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.pt-14 {
  padding-top: 14px !important;
}

.ma-14 {
  margin: 14px !important;
}

.pa-14 {
  padding: 14px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.pb-14 {
  padding-bottom: 14px !important;
}

.ma-14 {
  margin: 14px !important;
}

.pa-14 {
  padding: 14px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.pl-14 {
  padding-left: 14px !important;
}

.ma-14 {
  margin: 14px !important;
}

.pa-14 {
  padding: 14px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.pr-14 {
  padding-right: 14px !important;
}

.ma-14 {
  margin: 14px !important;
}

.pa-14 {
  padding: 14px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.ma-15 {
  margin: 15px !important;
}

.pa-15 {
  padding: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.ma-15 {
  margin: 15px !important;
}

.pa-15 {
  padding: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.ma-15 {
  margin: 15px !important;
}

.pa-15 {
  padding: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.ma-15 {
  margin: 15px !important;
}

.pa-15 {
  padding: 15px !important;
}

.mt-17 {
  margin-top: 17px !important;
}

.pt-17 {
  padding-top: 17px !important;
}

.ma-17 {
  margin: 17px !important;
}

.pa-17 {
  padding: 17px !important;
}

.mb-17 {
  margin-bottom: 17px !important;
}

.pb-17 {
  padding-bottom: 17px !important;
}

.ma-17 {
  margin: 17px !important;
}

.pa-17 {
  padding: 17px !important;
}

.ml-17 {
  margin-left: 17px !important;
}

.pl-17 {
  padding-left: 17px !important;
}

.ma-17 {
  margin: 17px !important;
}

.pa-17 {
  padding: 17px !important;
}

.mr-17 {
  margin-right: 17px !important;
}

.pr-17 {
  padding-right: 17px !important;
}

.ma-17 {
  margin: 17px !important;
}

.pa-17 {
  padding: 17px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.ma-20 {
  margin: 20px !important;
}

.pa-20 {
  padding: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.ma-20 {
  margin: 20px !important;
}

.pa-20 {
  padding: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.ma-20 {
  margin: 20px !important;
}

.pa-20 {
  padding: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.ma-20 {
  margin: 20px !important;
}

.pa-20 {
  padding: 20px !important;
}

.mt-22 {
  margin-top: 22px !important;
}

.pt-22 {
  padding-top: 22px !important;
}

.ma-22 {
  margin: 22px !important;
}

.pa-22 {
  padding: 22px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.pb-22 {
  padding-bottom: 22px !important;
}

.ma-22 {
  margin: 22px !important;
}

.pa-22 {
  padding: 22px !important;
}

.ml-22 {
  margin-left: 22px !important;
}

.pl-22 {
  padding-left: 22px !important;
}

.ma-22 {
  margin: 22px !important;
}

.pa-22 {
  padding: 22px !important;
}

.mr-22 {
  margin-right: 22px !important;
}

.pr-22 {
  padding-right: 22px !important;
}

.ma-22 {
  margin: 22px !important;
}

.pa-22 {
  padding: 22px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.ma-25 {
  margin: 25px !important;
}

.pa-25 {
  padding: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.ma-25 {
  margin: 25px !important;
}

.pa-25 {
  padding: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.ma-25 {
  margin: 25px !important;
}

.pa-25 {
  padding: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.ma-25 {
  margin: 25px !important;
}

.pa-25 {
  padding: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.ma-30 {
  margin: 30px !important;
}

.pa-30 {
  padding: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.ma-30 {
  margin: 30px !important;
}

.pa-30 {
  padding: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.ma-30 {
  margin: 30px !important;
}

.pa-30 {
  padding: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.ma-30 {
  margin: 30px !important;
}

.pa-30 {
  padding: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.ma-35 {
  margin: 35px !important;
}

.pa-35 {
  padding: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.ma-35 {
  margin: 35px !important;
}

.pa-35 {
  padding: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.ma-35 {
  margin: 35px !important;
}

.pa-35 {
  padding: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.ma-35 {
  margin: 35px !important;
}

.pa-35 {
  padding: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.ma-40 {
  margin: 40px !important;
}

.pa-40 {
  padding: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.ma-40 {
  margin: 40px !important;
}

.pa-40 {
  padding: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.ma-40 {
  margin: 40px !important;
}

.pa-40 {
  padding: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.ma-40 {
  margin: 40px !important;
}

.pa-40 {
  padding: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.ma-45 {
  margin: 45px !important;
}

.pa-45 {
  padding: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.ma-45 {
  margin: 45px !important;
}

.pa-45 {
  padding: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.ma-45 {
  margin: 45px !important;
}

.pa-45 {
  padding: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.ma-45 {
  margin: 45px !important;
}

.pa-45 {
  padding: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.ma-50 {
  margin: 50px !important;
}

.pa-50 {
  padding: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.ma-50 {
  margin: 50px !important;
}

.pa-50 {
  padding: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.ma-50 {
  margin: 50px !important;
}

.pa-50 {
  padding: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.ma-50 {
  margin: 50px !important;
}

.pa-50 {
  padding: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.ma-55 {
  margin: 55px !important;
}

.pa-55 {
  padding: 55px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.ma-55 {
  margin: 55px !important;
}

.pa-55 {
  padding: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.ma-55 {
  margin: 55px !important;
}

.pa-55 {
  padding: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.ma-55 {
  margin: 55px !important;
}

.pa-55 {
  padding: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.ma-60 {
  margin: 60px !important;
}

.pa-60 {
  padding: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.ma-60 {
  margin: 60px !important;
}

.pa-60 {
  padding: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.ma-60 {
  margin: 60px !important;
}

.pa-60 {
  padding: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.ma-60 {
  margin: 60px !important;
}

.pa-60 {
  padding: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.ma-65 {
  margin: 65px !important;
}

.pa-65 {
  padding: 65px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.ma-65 {
  margin: 65px !important;
}

.pa-65 {
  padding: 65px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.ma-65 {
  margin: 65px !important;
}

.pa-65 {
  padding: 65px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.ma-65 {
  margin: 65px !important;
}

.pa-65 {
  padding: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.ma-70 {
  margin: 70px !important;
}

.pa-70 {
  padding: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.ma-70 {
  margin: 70px !important;
}

.pa-70 {
  padding: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.ma-70 {
  margin: 70px !important;
}

.pa-70 {
  padding: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.ma-70 {
  margin: 70px !important;
}

.pa-70 {
  padding: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.ma-75 {
  margin: 75px !important;
}

.pa-75 {
  padding: 75px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.ma-75 {
  margin: 75px !important;
}

.pa-75 {
  padding: 75px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.ma-75 {
  margin: 75px !important;
}

.pa-75 {
  padding: 75px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.ma-75 {
  margin: 75px !important;
}

.pa-75 {
  padding: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.ma-80 {
  margin: 80px !important;
}

.pa-80 {
  padding: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.ma-80 {
  margin: 80px !important;
}

.pa-80 {
  padding: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.ma-80 {
  margin: 80px !important;
}

.pa-80 {
  padding: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.ma-80 {
  margin: 80px !important;
}

.pa-80 {
  padding: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.ma-85 {
  margin: 85px !important;
}

.pa-85 {
  padding: 85px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.ma-85 {
  margin: 85px !important;
}

.pa-85 {
  padding: 85px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.ma-85 {
  margin: 85px !important;
}

.pa-85 {
  padding: 85px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.ma-85 {
  margin: 85px !important;
}

.pa-85 {
  padding: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.ma-90 {
  margin: 90px !important;
}

.pa-90 {
  padding: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.ma-90 {
  margin: 90px !important;
}

.pa-90 {
  padding: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.ma-90 {
  margin: 90px !important;
}

.pa-90 {
  padding: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.ma-90 {
  margin: 90px !important;
}

.pa-90 {
  padding: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.ma-95 {
  margin: 95px !important;
}

.pa-95 {
  padding: 95px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.ma-95 {
  margin: 95px !important;
}

.pa-95 {
  padding: 95px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.ma-95 {
  margin: 95px !important;
}

.pa-95 {
  padding: 95px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.ma-95 {
  margin: 95px !important;
}

.pa-95 {
  padding: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.ma-100 {
  margin: 100px !important;
}

.pa-100 {
  padding: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.ma-100 {
  margin: 100px !important;
}

.pa-100 {
  padding: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.ma-100 {
  margin: 100px !important;
}

.pa-100 {
  padding: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.ma-100 {
  margin: 100px !important;
}

.pa-100 {
  padding: 100px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.ma-110 {
  margin: 110px !important;
}

.pa-110 {
  padding: 110px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.ma-110 {
  margin: 110px !important;
}

.pa-110 {
  padding: 110px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.ma-110 {
  margin: 110px !important;
}

.pa-110 {
  padding: 110px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.ma-110 {
  margin: 110px !important;
}

.pa-110 {
  padding: 110px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.ma-120 {
  margin: 120px !important;
}

.pa-120 {
  padding: 120px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.ma-120 {
  margin: 120px !important;
}

.pa-120 {
  padding: 120px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.ma-120 {
  margin: 120px !important;
}

.pa-120 {
  padding: 120px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.ma-120 {
  margin: 120px !important;
}

.pa-120 {
  padding: 120px !important;
}

@media (min-width: 769px) {
  .mt-pc-0 {
    margin-top: 0px !important;
  }
  .pt-pc-0 {
    padding-top: 0px !important;
  }
  .ma-pc-0 {
    margin: 0px !important;
  }
  .pa-pc-0 {
    padding: 0px !important;
  }
  .mb-pc-0 {
    margin-bottom: 0px !important;
  }
  .pb-pc-0 {
    padding-bottom: 0px !important;
  }
  .ma-pc-0 {
    margin: 0px !important;
  }
  .pa-pc-0 {
    padding: 0px !important;
  }
  .ml-pc-0 {
    margin-left: 0px !important;
  }
  .pl-pc-0 {
    padding-left: 0px !important;
  }
  .ma-pc-0 {
    margin: 0px !important;
  }
  .pa-pc-0 {
    padding: 0px !important;
  }
  .mr-pc-0 {
    margin-right: 0px !important;
  }
  .pr-pc-0 {
    padding-right: 0px !important;
  }
  .ma-pc-0 {
    margin: 0px !important;
  }
  .pa-pc-0 {
    padding: 0px !important;
  }
  .mt-pc-5 {
    margin-top: 5px !important;
  }
  .pt-pc-5 {
    padding-top: 5px !important;
  }
  .ma-pc-5 {
    margin: 5px !important;
  }
  .pa-pc-5 {
    padding: 5px !important;
  }
  .mb-pc-5 {
    margin-bottom: 5px !important;
  }
  .pb-pc-5 {
    padding-bottom: 5px !important;
  }
  .ma-pc-5 {
    margin: 5px !important;
  }
  .pa-pc-5 {
    padding: 5px !important;
  }
  .ml-pc-5 {
    margin-left: 5px !important;
  }
  .pl-pc-5 {
    padding-left: 5px !important;
  }
  .ma-pc-5 {
    margin: 5px !important;
  }
  .pa-pc-5 {
    padding: 5px !important;
  }
  .mr-pc-5 {
    margin-right: 5px !important;
  }
  .pr-pc-5 {
    padding-right: 5px !important;
  }
  .ma-pc-5 {
    margin: 5px !important;
  }
  .pa-pc-5 {
    padding: 5px !important;
  }
  .mt-pc-8 {
    margin-top: 8px !important;
  }
  .pt-pc-8 {
    padding-top: 8px !important;
  }
  .ma-pc-8 {
    margin: 8px !important;
  }
  .pa-pc-8 {
    padding: 8px !important;
  }
  .mb-pc-8 {
    margin-bottom: 8px !important;
  }
  .pb-pc-8 {
    padding-bottom: 8px !important;
  }
  .ma-pc-8 {
    margin: 8px !important;
  }
  .pa-pc-8 {
    padding: 8px !important;
  }
  .ml-pc-8 {
    margin-left: 8px !important;
  }
  .pl-pc-8 {
    padding-left: 8px !important;
  }
  .ma-pc-8 {
    margin: 8px !important;
  }
  .pa-pc-8 {
    padding: 8px !important;
  }
  .mr-pc-8 {
    margin-right: 8px !important;
  }
  .pr-pc-8 {
    padding-right: 8px !important;
  }
  .ma-pc-8 {
    margin: 8px !important;
  }
  .pa-pc-8 {
    padding: 8px !important;
  }
  .mt-pc-10 {
    margin-top: 10px !important;
  }
  .pt-pc-10 {
    padding-top: 10px !important;
  }
  .ma-pc-10 {
    margin: 10px !important;
  }
  .pa-pc-10 {
    padding: 10px !important;
  }
  .mb-pc-10 {
    margin-bottom: 10px !important;
  }
  .pb-pc-10 {
    padding-bottom: 10px !important;
  }
  .ma-pc-10 {
    margin: 10px !important;
  }
  .pa-pc-10 {
    padding: 10px !important;
  }
  .ml-pc-10 {
    margin-left: 10px !important;
  }
  .pl-pc-10 {
    padding-left: 10px !important;
  }
  .ma-pc-10 {
    margin: 10px !important;
  }
  .pa-pc-10 {
    padding: 10px !important;
  }
  .mr-pc-10 {
    margin-right: 10px !important;
  }
  .pr-pc-10 {
    padding-right: 10px !important;
  }
  .ma-pc-10 {
    margin: 10px !important;
  }
  .pa-pc-10 {
    padding: 10px !important;
  }
  .mt-pc-12 {
    margin-top: 12px !important;
  }
  .pt-pc-12 {
    padding-top: 12px !important;
  }
  .ma-pc-12 {
    margin: 12px !important;
  }
  .pa-pc-12 {
    padding: 12px !important;
  }
  .mb-pc-12 {
    margin-bottom: 12px !important;
  }
  .pb-pc-12 {
    padding-bottom: 12px !important;
  }
  .ma-pc-12 {
    margin: 12px !important;
  }
  .pa-pc-12 {
    padding: 12px !important;
  }
  .ml-pc-12 {
    margin-left: 12px !important;
  }
  .pl-pc-12 {
    padding-left: 12px !important;
  }
  .ma-pc-12 {
    margin: 12px !important;
  }
  .pa-pc-12 {
    padding: 12px !important;
  }
  .mr-pc-12 {
    margin-right: 12px !important;
  }
  .pr-pc-12 {
    padding-right: 12px !important;
  }
  .ma-pc-12 {
    margin: 12px !important;
  }
  .pa-pc-12 {
    padding: 12px !important;
  }
  .mt-pc-13 {
    margin-top: 13px !important;
  }
  .pt-pc-13 {
    padding-top: 13px !important;
  }
  .ma-pc-13 {
    margin: 13px !important;
  }
  .pa-pc-13 {
    padding: 13px !important;
  }
  .mb-pc-13 {
    margin-bottom: 13px !important;
  }
  .pb-pc-13 {
    padding-bottom: 13px !important;
  }
  .ma-pc-13 {
    margin: 13px !important;
  }
  .pa-pc-13 {
    padding: 13px !important;
  }
  .ml-pc-13 {
    margin-left: 13px !important;
  }
  .pl-pc-13 {
    padding-left: 13px !important;
  }
  .ma-pc-13 {
    margin: 13px !important;
  }
  .pa-pc-13 {
    padding: 13px !important;
  }
  .mr-pc-13 {
    margin-right: 13px !important;
  }
  .pr-pc-13 {
    padding-right: 13px !important;
  }
  .ma-pc-13 {
    margin: 13px !important;
  }
  .pa-pc-13 {
    padding: 13px !important;
  }
  .mt-pc-14 {
    margin-top: 14px !important;
  }
  .pt-pc-14 {
    padding-top: 14px !important;
  }
  .ma-pc-14 {
    margin: 14px !important;
  }
  .pa-pc-14 {
    padding: 14px !important;
  }
  .mb-pc-14 {
    margin-bottom: 14px !important;
  }
  .pb-pc-14 {
    padding-bottom: 14px !important;
  }
  .ma-pc-14 {
    margin: 14px !important;
  }
  .pa-pc-14 {
    padding: 14px !important;
  }
  .ml-pc-14 {
    margin-left: 14px !important;
  }
  .pl-pc-14 {
    padding-left: 14px !important;
  }
  .ma-pc-14 {
    margin: 14px !important;
  }
  .pa-pc-14 {
    padding: 14px !important;
  }
  .mr-pc-14 {
    margin-right: 14px !important;
  }
  .pr-pc-14 {
    padding-right: 14px !important;
  }
  .ma-pc-14 {
    margin: 14px !important;
  }
  .pa-pc-14 {
    padding: 14px !important;
  }
  .mt-pc-15 {
    margin-top: 15px !important;
  }
  .pt-pc-15 {
    padding-top: 15px !important;
  }
  .ma-pc-15 {
    margin: 15px !important;
  }
  .pa-pc-15 {
    padding: 15px !important;
  }
  .mb-pc-15 {
    margin-bottom: 15px !important;
  }
  .pb-pc-15 {
    padding-bottom: 15px !important;
  }
  .ma-pc-15 {
    margin: 15px !important;
  }
  .pa-pc-15 {
    padding: 15px !important;
  }
  .ml-pc-15 {
    margin-left: 15px !important;
  }
  .pl-pc-15 {
    padding-left: 15px !important;
  }
  .ma-pc-15 {
    margin: 15px !important;
  }
  .pa-pc-15 {
    padding: 15px !important;
  }
  .mr-pc-15 {
    margin-right: 15px !important;
  }
  .pr-pc-15 {
    padding-right: 15px !important;
  }
  .ma-pc-15 {
    margin: 15px !important;
  }
  .pa-pc-15 {
    padding: 15px !important;
  }
  .mt-pc-17 {
    margin-top: 17px !important;
  }
  .pt-pc-17 {
    padding-top: 17px !important;
  }
  .ma-pc-17 {
    margin: 17px !important;
  }
  .pa-pc-17 {
    padding: 17px !important;
  }
  .mb-pc-17 {
    margin-bottom: 17px !important;
  }
  .pb-pc-17 {
    padding-bottom: 17px !important;
  }
  .ma-pc-17 {
    margin: 17px !important;
  }
  .pa-pc-17 {
    padding: 17px !important;
  }
  .ml-pc-17 {
    margin-left: 17px !important;
  }
  .pl-pc-17 {
    padding-left: 17px !important;
  }
  .ma-pc-17 {
    margin: 17px !important;
  }
  .pa-pc-17 {
    padding: 17px !important;
  }
  .mr-pc-17 {
    margin-right: 17px !important;
  }
  .pr-pc-17 {
    padding-right: 17px !important;
  }
  .ma-pc-17 {
    margin: 17px !important;
  }
  .pa-pc-17 {
    padding: 17px !important;
  }
  .mt-pc-20 {
    margin-top: 20px !important;
  }
  .pt-pc-20 {
    padding-top: 20px !important;
  }
  .ma-pc-20 {
    margin: 20px !important;
  }
  .pa-pc-20 {
    padding: 20px !important;
  }
  .mb-pc-20 {
    margin-bottom: 20px !important;
  }
  .pb-pc-20 {
    padding-bottom: 20px !important;
  }
  .ma-pc-20 {
    margin: 20px !important;
  }
  .pa-pc-20 {
    padding: 20px !important;
  }
  .ml-pc-20 {
    margin-left: 20px !important;
  }
  .pl-pc-20 {
    padding-left: 20px !important;
  }
  .ma-pc-20 {
    margin: 20px !important;
  }
  .pa-pc-20 {
    padding: 20px !important;
  }
  .mr-pc-20 {
    margin-right: 20px !important;
  }
  .pr-pc-20 {
    padding-right: 20px !important;
  }
  .ma-pc-20 {
    margin: 20px !important;
  }
  .pa-pc-20 {
    padding: 20px !important;
  }
  .mt-pc-22 {
    margin-top: 22px !important;
  }
  .pt-pc-22 {
    padding-top: 22px !important;
  }
  .ma-pc-22 {
    margin: 22px !important;
  }
  .pa-pc-22 {
    padding: 22px !important;
  }
  .mb-pc-22 {
    margin-bottom: 22px !important;
  }
  .pb-pc-22 {
    padding-bottom: 22px !important;
  }
  .ma-pc-22 {
    margin: 22px !important;
  }
  .pa-pc-22 {
    padding: 22px !important;
  }
  .ml-pc-22 {
    margin-left: 22px !important;
  }
  .pl-pc-22 {
    padding-left: 22px !important;
  }
  .ma-pc-22 {
    margin: 22px !important;
  }
  .pa-pc-22 {
    padding: 22px !important;
  }
  .mr-pc-22 {
    margin-right: 22px !important;
  }
  .pr-pc-22 {
    padding-right: 22px !important;
  }
  .ma-pc-22 {
    margin: 22px !important;
  }
  .pa-pc-22 {
    padding: 22px !important;
  }
  .mt-pc-25 {
    margin-top: 25px !important;
  }
  .pt-pc-25 {
    padding-top: 25px !important;
  }
  .ma-pc-25 {
    margin: 25px !important;
  }
  .pa-pc-25 {
    padding: 25px !important;
  }
  .mb-pc-25 {
    margin-bottom: 25px !important;
  }
  .pb-pc-25 {
    padding-bottom: 25px !important;
  }
  .ma-pc-25 {
    margin: 25px !important;
  }
  .pa-pc-25 {
    padding: 25px !important;
  }
  .ml-pc-25 {
    margin-left: 25px !important;
  }
  .pl-pc-25 {
    padding-left: 25px !important;
  }
  .ma-pc-25 {
    margin: 25px !important;
  }
  .pa-pc-25 {
    padding: 25px !important;
  }
  .mr-pc-25 {
    margin-right: 25px !important;
  }
  .pr-pc-25 {
    padding-right: 25px !important;
  }
  .ma-pc-25 {
    margin: 25px !important;
  }
  .pa-pc-25 {
    padding: 25px !important;
  }
  .mt-pc-30 {
    margin-top: 30px !important;
  }
  .pt-pc-30 {
    padding-top: 30px !important;
  }
  .ma-pc-30 {
    margin: 30px !important;
  }
  .pa-pc-30 {
    padding: 30px !important;
  }
  .mb-pc-30 {
    margin-bottom: 30px !important;
  }
  .pb-pc-30 {
    padding-bottom: 30px !important;
  }
  .ma-pc-30 {
    margin: 30px !important;
  }
  .pa-pc-30 {
    padding: 30px !important;
  }
  .ml-pc-30 {
    margin-left: 30px !important;
  }
  .pl-pc-30 {
    padding-left: 30px !important;
  }
  .ma-pc-30 {
    margin: 30px !important;
  }
  .pa-pc-30 {
    padding: 30px !important;
  }
  .mr-pc-30 {
    margin-right: 30px !important;
  }
  .pr-pc-30 {
    padding-right: 30px !important;
  }
  .ma-pc-30 {
    margin: 30px !important;
  }
  .pa-pc-30 {
    padding: 30px !important;
  }
  .mt-pc-35 {
    margin-top: 35px !important;
  }
  .pt-pc-35 {
    padding-top: 35px !important;
  }
  .ma-pc-35 {
    margin: 35px !important;
  }
  .pa-pc-35 {
    padding: 35px !important;
  }
  .mb-pc-35 {
    margin-bottom: 35px !important;
  }
  .pb-pc-35 {
    padding-bottom: 35px !important;
  }
  .ma-pc-35 {
    margin: 35px !important;
  }
  .pa-pc-35 {
    padding: 35px !important;
  }
  .ml-pc-35 {
    margin-left: 35px !important;
  }
  .pl-pc-35 {
    padding-left: 35px !important;
  }
  .ma-pc-35 {
    margin: 35px !important;
  }
  .pa-pc-35 {
    padding: 35px !important;
  }
  .mr-pc-35 {
    margin-right: 35px !important;
  }
  .pr-pc-35 {
    padding-right: 35px !important;
  }
  .ma-pc-35 {
    margin: 35px !important;
  }
  .pa-pc-35 {
    padding: 35px !important;
  }
  .mt-pc-40 {
    margin-top: 40px !important;
  }
  .pt-pc-40 {
    padding-top: 40px !important;
  }
  .ma-pc-40 {
    margin: 40px !important;
  }
  .pa-pc-40 {
    padding: 40px !important;
  }
  .mb-pc-40 {
    margin-bottom: 40px !important;
  }
  .pb-pc-40 {
    padding-bottom: 40px !important;
  }
  .ma-pc-40 {
    margin: 40px !important;
  }
  .pa-pc-40 {
    padding: 40px !important;
  }
  .ml-pc-40 {
    margin-left: 40px !important;
  }
  .pl-pc-40 {
    padding-left: 40px !important;
  }
  .ma-pc-40 {
    margin: 40px !important;
  }
  .pa-pc-40 {
    padding: 40px !important;
  }
  .mr-pc-40 {
    margin-right: 40px !important;
  }
  .pr-pc-40 {
    padding-right: 40px !important;
  }
  .ma-pc-40 {
    margin: 40px !important;
  }
  .pa-pc-40 {
    padding: 40px !important;
  }
  .mt-pc-45 {
    margin-top: 45px !important;
  }
  .pt-pc-45 {
    padding-top: 45px !important;
  }
  .ma-pc-45 {
    margin: 45px !important;
  }
  .pa-pc-45 {
    padding: 45px !important;
  }
  .mb-pc-45 {
    margin-bottom: 45px !important;
  }
  .pb-pc-45 {
    padding-bottom: 45px !important;
  }
  .ma-pc-45 {
    margin: 45px !important;
  }
  .pa-pc-45 {
    padding: 45px !important;
  }
  .ml-pc-45 {
    margin-left: 45px !important;
  }
  .pl-pc-45 {
    padding-left: 45px !important;
  }
  .ma-pc-45 {
    margin: 45px !important;
  }
  .pa-pc-45 {
    padding: 45px !important;
  }
  .mr-pc-45 {
    margin-right: 45px !important;
  }
  .pr-pc-45 {
    padding-right: 45px !important;
  }
  .ma-pc-45 {
    margin: 45px !important;
  }
  .pa-pc-45 {
    padding: 45px !important;
  }
  .mt-pc-50 {
    margin-top: 50px !important;
  }
  .pt-pc-50 {
    padding-top: 50px !important;
  }
  .ma-pc-50 {
    margin: 50px !important;
  }
  .pa-pc-50 {
    padding: 50px !important;
  }
  .mb-pc-50 {
    margin-bottom: 50px !important;
  }
  .pb-pc-50 {
    padding-bottom: 50px !important;
  }
  .ma-pc-50 {
    margin: 50px !important;
  }
  .pa-pc-50 {
    padding: 50px !important;
  }
  .ml-pc-50 {
    margin-left: 50px !important;
  }
  .pl-pc-50 {
    padding-left: 50px !important;
  }
  .ma-pc-50 {
    margin: 50px !important;
  }
  .pa-pc-50 {
    padding: 50px !important;
  }
  .mr-pc-50 {
    margin-right: 50px !important;
  }
  .pr-pc-50 {
    padding-right: 50px !important;
  }
  .ma-pc-50 {
    margin: 50px !important;
  }
  .pa-pc-50 {
    padding: 50px !important;
  }
  .mt-pc-55 {
    margin-top: 55px !important;
  }
  .pt-pc-55 {
    padding-top: 55px !important;
  }
  .ma-pc-55 {
    margin: 55px !important;
  }
  .pa-pc-55 {
    padding: 55px !important;
  }
  .mb-pc-55 {
    margin-bottom: 55px !important;
  }
  .pb-pc-55 {
    padding-bottom: 55px !important;
  }
  .ma-pc-55 {
    margin: 55px !important;
  }
  .pa-pc-55 {
    padding: 55px !important;
  }
  .ml-pc-55 {
    margin-left: 55px !important;
  }
  .pl-pc-55 {
    padding-left: 55px !important;
  }
  .ma-pc-55 {
    margin: 55px !important;
  }
  .pa-pc-55 {
    padding: 55px !important;
  }
  .mr-pc-55 {
    margin-right: 55px !important;
  }
  .pr-pc-55 {
    padding-right: 55px !important;
  }
  .ma-pc-55 {
    margin: 55px !important;
  }
  .pa-pc-55 {
    padding: 55px !important;
  }
  .mt-pc-60 {
    margin-top: 60px !important;
  }
  .pt-pc-60 {
    padding-top: 60px !important;
  }
  .ma-pc-60 {
    margin: 60px !important;
  }
  .pa-pc-60 {
    padding: 60px !important;
  }
  .mb-pc-60 {
    margin-bottom: 60px !important;
  }
  .pb-pc-60 {
    padding-bottom: 60px !important;
  }
  .ma-pc-60 {
    margin: 60px !important;
  }
  .pa-pc-60 {
    padding: 60px !important;
  }
  .ml-pc-60 {
    margin-left: 60px !important;
  }
  .pl-pc-60 {
    padding-left: 60px !important;
  }
  .ma-pc-60 {
    margin: 60px !important;
  }
  .pa-pc-60 {
    padding: 60px !important;
  }
  .mr-pc-60 {
    margin-right: 60px !important;
  }
  .pr-pc-60 {
    padding-right: 60px !important;
  }
  .ma-pc-60 {
    margin: 60px !important;
  }
  .pa-pc-60 {
    padding: 60px !important;
  }
  .mt-pc-65 {
    margin-top: 65px !important;
  }
  .pt-pc-65 {
    padding-top: 65px !important;
  }
  .ma-pc-65 {
    margin: 65px !important;
  }
  .pa-pc-65 {
    padding: 65px !important;
  }
  .mb-pc-65 {
    margin-bottom: 65px !important;
  }
  .pb-pc-65 {
    padding-bottom: 65px !important;
  }
  .ma-pc-65 {
    margin: 65px !important;
  }
  .pa-pc-65 {
    padding: 65px !important;
  }
  .ml-pc-65 {
    margin-left: 65px !important;
  }
  .pl-pc-65 {
    padding-left: 65px !important;
  }
  .ma-pc-65 {
    margin: 65px !important;
  }
  .pa-pc-65 {
    padding: 65px !important;
  }
  .mr-pc-65 {
    margin-right: 65px !important;
  }
  .pr-pc-65 {
    padding-right: 65px !important;
  }
  .ma-pc-65 {
    margin: 65px !important;
  }
  .pa-pc-65 {
    padding: 65px !important;
  }
  .mt-pc-70 {
    margin-top: 70px !important;
  }
  .pt-pc-70 {
    padding-top: 70px !important;
  }
  .ma-pc-70 {
    margin: 70px !important;
  }
  .pa-pc-70 {
    padding: 70px !important;
  }
  .mb-pc-70 {
    margin-bottom: 70px !important;
  }
  .pb-pc-70 {
    padding-bottom: 70px !important;
  }
  .ma-pc-70 {
    margin: 70px !important;
  }
  .pa-pc-70 {
    padding: 70px !important;
  }
  .ml-pc-70 {
    margin-left: 70px !important;
  }
  .pl-pc-70 {
    padding-left: 70px !important;
  }
  .ma-pc-70 {
    margin: 70px !important;
  }
  .pa-pc-70 {
    padding: 70px !important;
  }
  .mr-pc-70 {
    margin-right: 70px !important;
  }
  .pr-pc-70 {
    padding-right: 70px !important;
  }
  .ma-pc-70 {
    margin: 70px !important;
  }
  .pa-pc-70 {
    padding: 70px !important;
  }
  .mt-pc-75 {
    margin-top: 75px !important;
  }
  .pt-pc-75 {
    padding-top: 75px !important;
  }
  .ma-pc-75 {
    margin: 75px !important;
  }
  .pa-pc-75 {
    padding: 75px !important;
  }
  .mb-pc-75 {
    margin-bottom: 75px !important;
  }
  .pb-pc-75 {
    padding-bottom: 75px !important;
  }
  .ma-pc-75 {
    margin: 75px !important;
  }
  .pa-pc-75 {
    padding: 75px !important;
  }
  .ml-pc-75 {
    margin-left: 75px !important;
  }
  .pl-pc-75 {
    padding-left: 75px !important;
  }
  .ma-pc-75 {
    margin: 75px !important;
  }
  .pa-pc-75 {
    padding: 75px !important;
  }
  .mr-pc-75 {
    margin-right: 75px !important;
  }
  .pr-pc-75 {
    padding-right: 75px !important;
  }
  .ma-pc-75 {
    margin: 75px !important;
  }
  .pa-pc-75 {
    padding: 75px !important;
  }
  .mt-pc-80 {
    margin-top: 80px !important;
  }
  .pt-pc-80 {
    padding-top: 80px !important;
  }
  .ma-pc-80 {
    margin: 80px !important;
  }
  .pa-pc-80 {
    padding: 80px !important;
  }
  .mb-pc-80 {
    margin-bottom: 80px !important;
  }
  .pb-pc-80 {
    padding-bottom: 80px !important;
  }
  .ma-pc-80 {
    margin: 80px !important;
  }
  .pa-pc-80 {
    padding: 80px !important;
  }
  .ml-pc-80 {
    margin-left: 80px !important;
  }
  .pl-pc-80 {
    padding-left: 80px !important;
  }
  .ma-pc-80 {
    margin: 80px !important;
  }
  .pa-pc-80 {
    padding: 80px !important;
  }
  .mr-pc-80 {
    margin-right: 80px !important;
  }
  .pr-pc-80 {
    padding-right: 80px !important;
  }
  .ma-pc-80 {
    margin: 80px !important;
  }
  .pa-pc-80 {
    padding: 80px !important;
  }
  .mt-pc-85 {
    margin-top: 85px !important;
  }
  .pt-pc-85 {
    padding-top: 85px !important;
  }
  .ma-pc-85 {
    margin: 85px !important;
  }
  .pa-pc-85 {
    padding: 85px !important;
  }
  .mb-pc-85 {
    margin-bottom: 85px !important;
  }
  .pb-pc-85 {
    padding-bottom: 85px !important;
  }
  .ma-pc-85 {
    margin: 85px !important;
  }
  .pa-pc-85 {
    padding: 85px !important;
  }
  .ml-pc-85 {
    margin-left: 85px !important;
  }
  .pl-pc-85 {
    padding-left: 85px !important;
  }
  .ma-pc-85 {
    margin: 85px !important;
  }
  .pa-pc-85 {
    padding: 85px !important;
  }
  .mr-pc-85 {
    margin-right: 85px !important;
  }
  .pr-pc-85 {
    padding-right: 85px !important;
  }
  .ma-pc-85 {
    margin: 85px !important;
  }
  .pa-pc-85 {
    padding: 85px !important;
  }
  .mt-pc-90 {
    margin-top: 90px !important;
  }
  .pt-pc-90 {
    padding-top: 90px !important;
  }
  .ma-pc-90 {
    margin: 90px !important;
  }
  .pa-pc-90 {
    padding: 90px !important;
  }
  .mb-pc-90 {
    margin-bottom: 90px !important;
  }
  .pb-pc-90 {
    padding-bottom: 90px !important;
  }
  .ma-pc-90 {
    margin: 90px !important;
  }
  .pa-pc-90 {
    padding: 90px !important;
  }
  .ml-pc-90 {
    margin-left: 90px !important;
  }
  .pl-pc-90 {
    padding-left: 90px !important;
  }
  .ma-pc-90 {
    margin: 90px !important;
  }
  .pa-pc-90 {
    padding: 90px !important;
  }
  .mr-pc-90 {
    margin-right: 90px !important;
  }
  .pr-pc-90 {
    padding-right: 90px !important;
  }
  .ma-pc-90 {
    margin: 90px !important;
  }
  .pa-pc-90 {
    padding: 90px !important;
  }
  .mt-pc-95 {
    margin-top: 95px !important;
  }
  .pt-pc-95 {
    padding-top: 95px !important;
  }
  .ma-pc-95 {
    margin: 95px !important;
  }
  .pa-pc-95 {
    padding: 95px !important;
  }
  .mb-pc-95 {
    margin-bottom: 95px !important;
  }
  .pb-pc-95 {
    padding-bottom: 95px !important;
  }
  .ma-pc-95 {
    margin: 95px !important;
  }
  .pa-pc-95 {
    padding: 95px !important;
  }
  .ml-pc-95 {
    margin-left: 95px !important;
  }
  .pl-pc-95 {
    padding-left: 95px !important;
  }
  .ma-pc-95 {
    margin: 95px !important;
  }
  .pa-pc-95 {
    padding: 95px !important;
  }
  .mr-pc-95 {
    margin-right: 95px !important;
  }
  .pr-pc-95 {
    padding-right: 95px !important;
  }
  .ma-pc-95 {
    margin: 95px !important;
  }
  .pa-pc-95 {
    padding: 95px !important;
  }
  .mt-pc-100 {
    margin-top: 100px !important;
  }
  .pt-pc-100 {
    padding-top: 100px !important;
  }
  .ma-pc-100 {
    margin: 100px !important;
  }
  .pa-pc-100 {
    padding: 100px !important;
  }
  .mb-pc-100 {
    margin-bottom: 100px !important;
  }
  .pb-pc-100 {
    padding-bottom: 100px !important;
  }
  .ma-pc-100 {
    margin: 100px !important;
  }
  .pa-pc-100 {
    padding: 100px !important;
  }
  .ml-pc-100 {
    margin-left: 100px !important;
  }
  .pl-pc-100 {
    padding-left: 100px !important;
  }
  .ma-pc-100 {
    margin: 100px !important;
  }
  .pa-pc-100 {
    padding: 100px !important;
  }
  .mr-pc-100 {
    margin-right: 100px !important;
  }
  .pr-pc-100 {
    padding-right: 100px !important;
  }
  .ma-pc-100 {
    margin: 100px !important;
  }
  .pa-pc-100 {
    padding: 100px !important;
  }
  .mt-pc-110 {
    margin-top: 110px !important;
  }
  .pt-pc-110 {
    padding-top: 110px !important;
  }
  .ma-pc-110 {
    margin: 110px !important;
  }
  .pa-pc-110 {
    padding: 110px !important;
  }
  .mb-pc-110 {
    margin-bottom: 110px !important;
  }
  .pb-pc-110 {
    padding-bottom: 110px !important;
  }
  .ma-pc-110 {
    margin: 110px !important;
  }
  .pa-pc-110 {
    padding: 110px !important;
  }
  .ml-pc-110 {
    margin-left: 110px !important;
  }
  .pl-pc-110 {
    padding-left: 110px !important;
  }
  .ma-pc-110 {
    margin: 110px !important;
  }
  .pa-pc-110 {
    padding: 110px !important;
  }
  .mr-pc-110 {
    margin-right: 110px !important;
  }
  .pr-pc-110 {
    padding-right: 110px !important;
  }
  .ma-pc-110 {
    margin: 110px !important;
  }
  .pa-pc-110 {
    padding: 110px !important;
  }
  .mt-pc-120 {
    margin-top: 120px !important;
  }
  .pt-pc-120 {
    padding-top: 120px !important;
  }
  .ma-pc-120 {
    margin: 120px !important;
  }
  .pa-pc-120 {
    padding: 120px !important;
  }
  .mb-pc-120 {
    margin-bottom: 120px !important;
  }
  .pb-pc-120 {
    padding-bottom: 120px !important;
  }
  .ma-pc-120 {
    margin: 120px !important;
  }
  .pa-pc-120 {
    padding: 120px !important;
  }
  .ml-pc-120 {
    margin-left: 120px !important;
  }
  .pl-pc-120 {
    padding-left: 120px !important;
  }
  .ma-pc-120 {
    margin: 120px !important;
  }
  .pa-pc-120 {
    padding: 120px !important;
  }
  .mr-pc-120 {
    margin-right: 120px !important;
  }
  .pr-pc-120 {
    padding-right: 120px !important;
  }
  .ma-pc-120 {
    margin: 120px !important;
  }
  .pa-pc-120 {
    padding: 120px !important;
  }
}

@media (max-width: 768px) {
  .mt-sp-0 {
    margin-top: 0px !important;
  }
  .pt-sp-0 {
    padding-top: 0px !important;
  }
  .ma-sp-0 {
    margin: 0px !important;
  }
  .pa-sp-0 {
    padding: 0px !important;
  }
  .mb-sp-0 {
    margin-bottom: 0px !important;
  }
  .pb-sp-0 {
    padding-bottom: 0px !important;
  }
  .ma-sp-0 {
    margin: 0px !important;
  }
  .pa-sp-0 {
    padding: 0px !important;
  }
  .ml-sp-0 {
    margin-left: 0px !important;
  }
  .pl-sp-0 {
    padding-left: 0px !important;
  }
  .ma-sp-0 {
    margin: 0px !important;
  }
  .pa-sp-0 {
    padding: 0px !important;
  }
  .mr-sp-0 {
    margin-right: 0px !important;
  }
  .pr-sp-0 {
    padding-right: 0px !important;
  }
  .ma-sp-0 {
    margin: 0px !important;
  }
  .pa-sp-0 {
    padding: 0px !important;
  }
  .mt-sp-5 {
    margin-top: 5px !important;
  }
  .pt-sp-5 {
    padding-top: 5px !important;
  }
  .ma-sp-5 {
    margin: 5px !important;
  }
  .pa-sp-5 {
    padding: 5px !important;
  }
  .mb-sp-5 {
    margin-bottom: 5px !important;
  }
  .pb-sp-5 {
    padding-bottom: 5px !important;
  }
  .ma-sp-5 {
    margin: 5px !important;
  }
  .pa-sp-5 {
    padding: 5px !important;
  }
  .ml-sp-5 {
    margin-left: 5px !important;
  }
  .pl-sp-5 {
    padding-left: 5px !important;
  }
  .ma-sp-5 {
    margin: 5px !important;
  }
  .pa-sp-5 {
    padding: 5px !important;
  }
  .mr-sp-5 {
    margin-right: 5px !important;
  }
  .pr-sp-5 {
    padding-right: 5px !important;
  }
  .ma-sp-5 {
    margin: 5px !important;
  }
  .pa-sp-5 {
    padding: 5px !important;
  }
  .mt-sp-8 {
    margin-top: 8px !important;
  }
  .pt-sp-8 {
    padding-top: 8px !important;
  }
  .ma-sp-8 {
    margin: 8px !important;
  }
  .pa-sp-8 {
    padding: 8px !important;
  }
  .mb-sp-8 {
    margin-bottom: 8px !important;
  }
  .pb-sp-8 {
    padding-bottom: 8px !important;
  }
  .ma-sp-8 {
    margin: 8px !important;
  }
  .pa-sp-8 {
    padding: 8px !important;
  }
  .ml-sp-8 {
    margin-left: 8px !important;
  }
  .pl-sp-8 {
    padding-left: 8px !important;
  }
  .ma-sp-8 {
    margin: 8px !important;
  }
  .pa-sp-8 {
    padding: 8px !important;
  }
  .mr-sp-8 {
    margin-right: 8px !important;
  }
  .pr-sp-8 {
    padding-right: 8px !important;
  }
  .ma-sp-8 {
    margin: 8px !important;
  }
  .pa-sp-8 {
    padding: 8px !important;
  }
  .mt-sp-10 {
    margin-top: 10px !important;
  }
  .pt-sp-10 {
    padding-top: 10px !important;
  }
  .ma-sp-10 {
    margin: 10px !important;
  }
  .pa-sp-10 {
    padding: 10px !important;
  }
  .mb-sp-10 {
    margin-bottom: 10px !important;
  }
  .pb-sp-10 {
    padding-bottom: 10px !important;
  }
  .ma-sp-10 {
    margin: 10px !important;
  }
  .pa-sp-10 {
    padding: 10px !important;
  }
  .ml-sp-10 {
    margin-left: 10px !important;
  }
  .pl-sp-10 {
    padding-left: 10px !important;
  }
  .ma-sp-10 {
    margin: 10px !important;
  }
  .pa-sp-10 {
    padding: 10px !important;
  }
  .mr-sp-10 {
    margin-right: 10px !important;
  }
  .pr-sp-10 {
    padding-right: 10px !important;
  }
  .ma-sp-10 {
    margin: 10px !important;
  }
  .pa-sp-10 {
    padding: 10px !important;
  }
  .mt-sp-12 {
    margin-top: 12px !important;
  }
  .pt-sp-12 {
    padding-top: 12px !important;
  }
  .ma-sp-12 {
    margin: 12px !important;
  }
  .pa-sp-12 {
    padding: 12px !important;
  }
  .mb-sp-12 {
    margin-bottom: 12px !important;
  }
  .pb-sp-12 {
    padding-bottom: 12px !important;
  }
  .ma-sp-12 {
    margin: 12px !important;
  }
  .pa-sp-12 {
    padding: 12px !important;
  }
  .ml-sp-12 {
    margin-left: 12px !important;
  }
  .pl-sp-12 {
    padding-left: 12px !important;
  }
  .ma-sp-12 {
    margin: 12px !important;
  }
  .pa-sp-12 {
    padding: 12px !important;
  }
  .mr-sp-12 {
    margin-right: 12px !important;
  }
  .pr-sp-12 {
    padding-right: 12px !important;
  }
  .ma-sp-12 {
    margin: 12px !important;
  }
  .pa-sp-12 {
    padding: 12px !important;
  }
  .mt-sp-13 {
    margin-top: 13px !important;
  }
  .pt-sp-13 {
    padding-top: 13px !important;
  }
  .ma-sp-13 {
    margin: 13px !important;
  }
  .pa-sp-13 {
    padding: 13px !important;
  }
  .mb-sp-13 {
    margin-bottom: 13px !important;
  }
  .pb-sp-13 {
    padding-bottom: 13px !important;
  }
  .ma-sp-13 {
    margin: 13px !important;
  }
  .pa-sp-13 {
    padding: 13px !important;
  }
  .ml-sp-13 {
    margin-left: 13px !important;
  }
  .pl-sp-13 {
    padding-left: 13px !important;
  }
  .ma-sp-13 {
    margin: 13px !important;
  }
  .pa-sp-13 {
    padding: 13px !important;
  }
  .mr-sp-13 {
    margin-right: 13px !important;
  }
  .pr-sp-13 {
    padding-right: 13px !important;
  }
  .ma-sp-13 {
    margin: 13px !important;
  }
  .pa-sp-13 {
    padding: 13px !important;
  }
  .mt-sp-14 {
    margin-top: 14px !important;
  }
  .pt-sp-14 {
    padding-top: 14px !important;
  }
  .ma-sp-14 {
    margin: 14px !important;
  }
  .pa-sp-14 {
    padding: 14px !important;
  }
  .mb-sp-14 {
    margin-bottom: 14px !important;
  }
  .pb-sp-14 {
    padding-bottom: 14px !important;
  }
  .ma-sp-14 {
    margin: 14px !important;
  }
  .pa-sp-14 {
    padding: 14px !important;
  }
  .ml-sp-14 {
    margin-left: 14px !important;
  }
  .pl-sp-14 {
    padding-left: 14px !important;
  }
  .ma-sp-14 {
    margin: 14px !important;
  }
  .pa-sp-14 {
    padding: 14px !important;
  }
  .mr-sp-14 {
    margin-right: 14px !important;
  }
  .pr-sp-14 {
    padding-right: 14px !important;
  }
  .ma-sp-14 {
    margin: 14px !important;
  }
  .pa-sp-14 {
    padding: 14px !important;
  }
  .mt-sp-15 {
    margin-top: 15px !important;
  }
  .pt-sp-15 {
    padding-top: 15px !important;
  }
  .ma-sp-15 {
    margin: 15px !important;
  }
  .pa-sp-15 {
    padding: 15px !important;
  }
  .mb-sp-15 {
    margin-bottom: 15px !important;
  }
  .pb-sp-15 {
    padding-bottom: 15px !important;
  }
  .ma-sp-15 {
    margin: 15px !important;
  }
  .pa-sp-15 {
    padding: 15px !important;
  }
  .ml-sp-15 {
    margin-left: 15px !important;
  }
  .pl-sp-15 {
    padding-left: 15px !important;
  }
  .ma-sp-15 {
    margin: 15px !important;
  }
  .pa-sp-15 {
    padding: 15px !important;
  }
  .mr-sp-15 {
    margin-right: 15px !important;
  }
  .pr-sp-15 {
    padding-right: 15px !important;
  }
  .ma-sp-15 {
    margin: 15px !important;
  }
  .pa-sp-15 {
    padding: 15px !important;
  }
  .mt-sp-17 {
    margin-top: 17px !important;
  }
  .pt-sp-17 {
    padding-top: 17px !important;
  }
  .ma-sp-17 {
    margin: 17px !important;
  }
  .pa-sp-17 {
    padding: 17px !important;
  }
  .mb-sp-17 {
    margin-bottom: 17px !important;
  }
  .pb-sp-17 {
    padding-bottom: 17px !important;
  }
  .ma-sp-17 {
    margin: 17px !important;
  }
  .pa-sp-17 {
    padding: 17px !important;
  }
  .ml-sp-17 {
    margin-left: 17px !important;
  }
  .pl-sp-17 {
    padding-left: 17px !important;
  }
  .ma-sp-17 {
    margin: 17px !important;
  }
  .pa-sp-17 {
    padding: 17px !important;
  }
  .mr-sp-17 {
    margin-right: 17px !important;
  }
  .pr-sp-17 {
    padding-right: 17px !important;
  }
  .ma-sp-17 {
    margin: 17px !important;
  }
  .pa-sp-17 {
    padding: 17px !important;
  }
  .mt-sp-20 {
    margin-top: 20px !important;
  }
  .pt-sp-20 {
    padding-top: 20px !important;
  }
  .ma-sp-20 {
    margin: 20px !important;
  }
  .pa-sp-20 {
    padding: 20px !important;
  }
  .mb-sp-20 {
    margin-bottom: 20px !important;
  }
  .pb-sp-20 {
    padding-bottom: 20px !important;
  }
  .ma-sp-20 {
    margin: 20px !important;
  }
  .pa-sp-20 {
    padding: 20px !important;
  }
  .ml-sp-20 {
    margin-left: 20px !important;
  }
  .pl-sp-20 {
    padding-left: 20px !important;
  }
  .ma-sp-20 {
    margin: 20px !important;
  }
  .pa-sp-20 {
    padding: 20px !important;
  }
  .mr-sp-20 {
    margin-right: 20px !important;
  }
  .pr-sp-20 {
    padding-right: 20px !important;
  }
  .ma-sp-20 {
    margin: 20px !important;
  }
  .pa-sp-20 {
    padding: 20px !important;
  }
  .mt-sp-22 {
    margin-top: 22px !important;
  }
  .pt-sp-22 {
    padding-top: 22px !important;
  }
  .ma-sp-22 {
    margin: 22px !important;
  }
  .pa-sp-22 {
    padding: 22px !important;
  }
  .mb-sp-22 {
    margin-bottom: 22px !important;
  }
  .pb-sp-22 {
    padding-bottom: 22px !important;
  }
  .ma-sp-22 {
    margin: 22px !important;
  }
  .pa-sp-22 {
    padding: 22px !important;
  }
  .ml-sp-22 {
    margin-left: 22px !important;
  }
  .pl-sp-22 {
    padding-left: 22px !important;
  }
  .ma-sp-22 {
    margin: 22px !important;
  }
  .pa-sp-22 {
    padding: 22px !important;
  }
  .mr-sp-22 {
    margin-right: 22px !important;
  }
  .pr-sp-22 {
    padding-right: 22px !important;
  }
  .ma-sp-22 {
    margin: 22px !important;
  }
  .pa-sp-22 {
    padding: 22px !important;
  }
  .mt-sp-25 {
    margin-top: 25px !important;
  }
  .pt-sp-25 {
    padding-top: 25px !important;
  }
  .ma-sp-25 {
    margin: 25px !important;
  }
  .pa-sp-25 {
    padding: 25px !important;
  }
  .mb-sp-25 {
    margin-bottom: 25px !important;
  }
  .pb-sp-25 {
    padding-bottom: 25px !important;
  }
  .ma-sp-25 {
    margin: 25px !important;
  }
  .pa-sp-25 {
    padding: 25px !important;
  }
  .ml-sp-25 {
    margin-left: 25px !important;
  }
  .pl-sp-25 {
    padding-left: 25px !important;
  }
  .ma-sp-25 {
    margin: 25px !important;
  }
  .pa-sp-25 {
    padding: 25px !important;
  }
  .mr-sp-25 {
    margin-right: 25px !important;
  }
  .pr-sp-25 {
    padding-right: 25px !important;
  }
  .ma-sp-25 {
    margin: 25px !important;
  }
  .pa-sp-25 {
    padding: 25px !important;
  }
  .mt-sp-30 {
    margin-top: 30px !important;
  }
  .pt-sp-30 {
    padding-top: 30px !important;
  }
  .ma-sp-30 {
    margin: 30px !important;
  }
  .pa-sp-30 {
    padding: 30px !important;
  }
  .mb-sp-30 {
    margin-bottom: 30px !important;
  }
  .pb-sp-30 {
    padding-bottom: 30px !important;
  }
  .ma-sp-30 {
    margin: 30px !important;
  }
  .pa-sp-30 {
    padding: 30px !important;
  }
  .ml-sp-30 {
    margin-left: 30px !important;
  }
  .pl-sp-30 {
    padding-left: 30px !important;
  }
  .ma-sp-30 {
    margin: 30px !important;
  }
  .pa-sp-30 {
    padding: 30px !important;
  }
  .mr-sp-30 {
    margin-right: 30px !important;
  }
  .pr-sp-30 {
    padding-right: 30px !important;
  }
  .ma-sp-30 {
    margin: 30px !important;
  }
  .pa-sp-30 {
    padding: 30px !important;
  }
  .mt-sp-35 {
    margin-top: 35px !important;
  }
  .pt-sp-35 {
    padding-top: 35px !important;
  }
  .ma-sp-35 {
    margin: 35px !important;
  }
  .pa-sp-35 {
    padding: 35px !important;
  }
  .mb-sp-35 {
    margin-bottom: 35px !important;
  }
  .pb-sp-35 {
    padding-bottom: 35px !important;
  }
  .ma-sp-35 {
    margin: 35px !important;
  }
  .pa-sp-35 {
    padding: 35px !important;
  }
  .ml-sp-35 {
    margin-left: 35px !important;
  }
  .pl-sp-35 {
    padding-left: 35px !important;
  }
  .ma-sp-35 {
    margin: 35px !important;
  }
  .pa-sp-35 {
    padding: 35px !important;
  }
  .mr-sp-35 {
    margin-right: 35px !important;
  }
  .pr-sp-35 {
    padding-right: 35px !important;
  }
  .ma-sp-35 {
    margin: 35px !important;
  }
  .pa-sp-35 {
    padding: 35px !important;
  }
  .mt-sp-40 {
    margin-top: 40px !important;
  }
  .pt-sp-40 {
    padding-top: 40px !important;
  }
  .ma-sp-40 {
    margin: 40px !important;
  }
  .pa-sp-40 {
    padding: 40px !important;
  }
  .mb-sp-40 {
    margin-bottom: 40px !important;
  }
  .pb-sp-40 {
    padding-bottom: 40px !important;
  }
  .ma-sp-40 {
    margin: 40px !important;
  }
  .pa-sp-40 {
    padding: 40px !important;
  }
  .ml-sp-40 {
    margin-left: 40px !important;
  }
  .pl-sp-40 {
    padding-left: 40px !important;
  }
  .ma-sp-40 {
    margin: 40px !important;
  }
  .pa-sp-40 {
    padding: 40px !important;
  }
  .mr-sp-40 {
    margin-right: 40px !important;
  }
  .pr-sp-40 {
    padding-right: 40px !important;
  }
  .ma-sp-40 {
    margin: 40px !important;
  }
  .pa-sp-40 {
    padding: 40px !important;
  }
  .mt-sp-45 {
    margin-top: 45px !important;
  }
  .pt-sp-45 {
    padding-top: 45px !important;
  }
  .ma-sp-45 {
    margin: 45px !important;
  }
  .pa-sp-45 {
    padding: 45px !important;
  }
  .mb-sp-45 {
    margin-bottom: 45px !important;
  }
  .pb-sp-45 {
    padding-bottom: 45px !important;
  }
  .ma-sp-45 {
    margin: 45px !important;
  }
  .pa-sp-45 {
    padding: 45px !important;
  }
  .ml-sp-45 {
    margin-left: 45px !important;
  }
  .pl-sp-45 {
    padding-left: 45px !important;
  }
  .ma-sp-45 {
    margin: 45px !important;
  }
  .pa-sp-45 {
    padding: 45px !important;
  }
  .mr-sp-45 {
    margin-right: 45px !important;
  }
  .pr-sp-45 {
    padding-right: 45px !important;
  }
  .ma-sp-45 {
    margin: 45px !important;
  }
  .pa-sp-45 {
    padding: 45px !important;
  }
  .mt-sp-50 {
    margin-top: 50px !important;
  }
  .pt-sp-50 {
    padding-top: 50px !important;
  }
  .ma-sp-50 {
    margin: 50px !important;
  }
  .pa-sp-50 {
    padding: 50px !important;
  }
  .mb-sp-50 {
    margin-bottom: 50px !important;
  }
  .pb-sp-50 {
    padding-bottom: 50px !important;
  }
  .ma-sp-50 {
    margin: 50px !important;
  }
  .pa-sp-50 {
    padding: 50px !important;
  }
  .ml-sp-50 {
    margin-left: 50px !important;
  }
  .pl-sp-50 {
    padding-left: 50px !important;
  }
  .ma-sp-50 {
    margin: 50px !important;
  }
  .pa-sp-50 {
    padding: 50px !important;
  }
  .mr-sp-50 {
    margin-right: 50px !important;
  }
  .pr-sp-50 {
    padding-right: 50px !important;
  }
  .ma-sp-50 {
    margin: 50px !important;
  }
  .pa-sp-50 {
    padding: 50px !important;
  }
  .mt-sp-55 {
    margin-top: 55px !important;
  }
  .pt-sp-55 {
    padding-top: 55px !important;
  }
  .ma-sp-55 {
    margin: 55px !important;
  }
  .pa-sp-55 {
    padding: 55px !important;
  }
  .mb-sp-55 {
    margin-bottom: 55px !important;
  }
  .pb-sp-55 {
    padding-bottom: 55px !important;
  }
  .ma-sp-55 {
    margin: 55px !important;
  }
  .pa-sp-55 {
    padding: 55px !important;
  }
  .ml-sp-55 {
    margin-left: 55px !important;
  }
  .pl-sp-55 {
    padding-left: 55px !important;
  }
  .ma-sp-55 {
    margin: 55px !important;
  }
  .pa-sp-55 {
    padding: 55px !important;
  }
  .mr-sp-55 {
    margin-right: 55px !important;
  }
  .pr-sp-55 {
    padding-right: 55px !important;
  }
  .ma-sp-55 {
    margin: 55px !important;
  }
  .pa-sp-55 {
    padding: 55px !important;
  }
  .mt-sp-60 {
    margin-top: 60px !important;
  }
  .pt-sp-60 {
    padding-top: 60px !important;
  }
  .ma-sp-60 {
    margin: 60px !important;
  }
  .pa-sp-60 {
    padding: 60px !important;
  }
  .mb-sp-60 {
    margin-bottom: 60px !important;
  }
  .pb-sp-60 {
    padding-bottom: 60px !important;
  }
  .ma-sp-60 {
    margin: 60px !important;
  }
  .pa-sp-60 {
    padding: 60px !important;
  }
  .ml-sp-60 {
    margin-left: 60px !important;
  }
  .pl-sp-60 {
    padding-left: 60px !important;
  }
  .ma-sp-60 {
    margin: 60px !important;
  }
  .pa-sp-60 {
    padding: 60px !important;
  }
  .mr-sp-60 {
    margin-right: 60px !important;
  }
  .pr-sp-60 {
    padding-right: 60px !important;
  }
  .ma-sp-60 {
    margin: 60px !important;
  }
  .pa-sp-60 {
    padding: 60px !important;
  }
  .mt-sp-65 {
    margin-top: 65px !important;
  }
  .pt-sp-65 {
    padding-top: 65px !important;
  }
  .ma-sp-65 {
    margin: 65px !important;
  }
  .pa-sp-65 {
    padding: 65px !important;
  }
  .mb-sp-65 {
    margin-bottom: 65px !important;
  }
  .pb-sp-65 {
    padding-bottom: 65px !important;
  }
  .ma-sp-65 {
    margin: 65px !important;
  }
  .pa-sp-65 {
    padding: 65px !important;
  }
  .ml-sp-65 {
    margin-left: 65px !important;
  }
  .pl-sp-65 {
    padding-left: 65px !important;
  }
  .ma-sp-65 {
    margin: 65px !important;
  }
  .pa-sp-65 {
    padding: 65px !important;
  }
  .mr-sp-65 {
    margin-right: 65px !important;
  }
  .pr-sp-65 {
    padding-right: 65px !important;
  }
  .ma-sp-65 {
    margin: 65px !important;
  }
  .pa-sp-65 {
    padding: 65px !important;
  }
  .mt-sp-70 {
    margin-top: 70px !important;
  }
  .pt-sp-70 {
    padding-top: 70px !important;
  }
  .ma-sp-70 {
    margin: 70px !important;
  }
  .pa-sp-70 {
    padding: 70px !important;
  }
  .mb-sp-70 {
    margin-bottom: 70px !important;
  }
  .pb-sp-70 {
    padding-bottom: 70px !important;
  }
  .ma-sp-70 {
    margin: 70px !important;
  }
  .pa-sp-70 {
    padding: 70px !important;
  }
  .ml-sp-70 {
    margin-left: 70px !important;
  }
  .pl-sp-70 {
    padding-left: 70px !important;
  }
  .ma-sp-70 {
    margin: 70px !important;
  }
  .pa-sp-70 {
    padding: 70px !important;
  }
  .mr-sp-70 {
    margin-right: 70px !important;
  }
  .pr-sp-70 {
    padding-right: 70px !important;
  }
  .ma-sp-70 {
    margin: 70px !important;
  }
  .pa-sp-70 {
    padding: 70px !important;
  }
  .mt-sp-75 {
    margin-top: 75px !important;
  }
  .pt-sp-75 {
    padding-top: 75px !important;
  }
  .ma-sp-75 {
    margin: 75px !important;
  }
  .pa-sp-75 {
    padding: 75px !important;
  }
  .mb-sp-75 {
    margin-bottom: 75px !important;
  }
  .pb-sp-75 {
    padding-bottom: 75px !important;
  }
  .ma-sp-75 {
    margin: 75px !important;
  }
  .pa-sp-75 {
    padding: 75px !important;
  }
  .ml-sp-75 {
    margin-left: 75px !important;
  }
  .pl-sp-75 {
    padding-left: 75px !important;
  }
  .ma-sp-75 {
    margin: 75px !important;
  }
  .pa-sp-75 {
    padding: 75px !important;
  }
  .mr-sp-75 {
    margin-right: 75px !important;
  }
  .pr-sp-75 {
    padding-right: 75px !important;
  }
  .ma-sp-75 {
    margin: 75px !important;
  }
  .pa-sp-75 {
    padding: 75px !important;
  }
  .mt-sp-80 {
    margin-top: 80px !important;
  }
  .pt-sp-80 {
    padding-top: 80px !important;
  }
  .ma-sp-80 {
    margin: 80px !important;
  }
  .pa-sp-80 {
    padding: 80px !important;
  }
  .mb-sp-80 {
    margin-bottom: 80px !important;
  }
  .pb-sp-80 {
    padding-bottom: 80px !important;
  }
  .ma-sp-80 {
    margin: 80px !important;
  }
  .pa-sp-80 {
    padding: 80px !important;
  }
  .ml-sp-80 {
    margin-left: 80px !important;
  }
  .pl-sp-80 {
    padding-left: 80px !important;
  }
  .ma-sp-80 {
    margin: 80px !important;
  }
  .pa-sp-80 {
    padding: 80px !important;
  }
  .mr-sp-80 {
    margin-right: 80px !important;
  }
  .pr-sp-80 {
    padding-right: 80px !important;
  }
  .ma-sp-80 {
    margin: 80px !important;
  }
  .pa-sp-80 {
    padding: 80px !important;
  }
  .mt-sp-85 {
    margin-top: 85px !important;
  }
  .pt-sp-85 {
    padding-top: 85px !important;
  }
  .ma-sp-85 {
    margin: 85px !important;
  }
  .pa-sp-85 {
    padding: 85px !important;
  }
  .mb-sp-85 {
    margin-bottom: 85px !important;
  }
  .pb-sp-85 {
    padding-bottom: 85px !important;
  }
  .ma-sp-85 {
    margin: 85px !important;
  }
  .pa-sp-85 {
    padding: 85px !important;
  }
  .ml-sp-85 {
    margin-left: 85px !important;
  }
  .pl-sp-85 {
    padding-left: 85px !important;
  }
  .ma-sp-85 {
    margin: 85px !important;
  }
  .pa-sp-85 {
    padding: 85px !important;
  }
  .mr-sp-85 {
    margin-right: 85px !important;
  }
  .pr-sp-85 {
    padding-right: 85px !important;
  }
  .ma-sp-85 {
    margin: 85px !important;
  }
  .pa-sp-85 {
    padding: 85px !important;
  }
  .mt-sp-90 {
    margin-top: 90px !important;
  }
  .pt-sp-90 {
    padding-top: 90px !important;
  }
  .ma-sp-90 {
    margin: 90px !important;
  }
  .pa-sp-90 {
    padding: 90px !important;
  }
  .mb-sp-90 {
    margin-bottom: 90px !important;
  }
  .pb-sp-90 {
    padding-bottom: 90px !important;
  }
  .ma-sp-90 {
    margin: 90px !important;
  }
  .pa-sp-90 {
    padding: 90px !important;
  }
  .ml-sp-90 {
    margin-left: 90px !important;
  }
  .pl-sp-90 {
    padding-left: 90px !important;
  }
  .ma-sp-90 {
    margin: 90px !important;
  }
  .pa-sp-90 {
    padding: 90px !important;
  }
  .mr-sp-90 {
    margin-right: 90px !important;
  }
  .pr-sp-90 {
    padding-right: 90px !important;
  }
  .ma-sp-90 {
    margin: 90px !important;
  }
  .pa-sp-90 {
    padding: 90px !important;
  }
  .mt-sp-95 {
    margin-top: 95px !important;
  }
  .pt-sp-95 {
    padding-top: 95px !important;
  }
  .ma-sp-95 {
    margin: 95px !important;
  }
  .pa-sp-95 {
    padding: 95px !important;
  }
  .mb-sp-95 {
    margin-bottom: 95px !important;
  }
  .pb-sp-95 {
    padding-bottom: 95px !important;
  }
  .ma-sp-95 {
    margin: 95px !important;
  }
  .pa-sp-95 {
    padding: 95px !important;
  }
  .ml-sp-95 {
    margin-left: 95px !important;
  }
  .pl-sp-95 {
    padding-left: 95px !important;
  }
  .ma-sp-95 {
    margin: 95px !important;
  }
  .pa-sp-95 {
    padding: 95px !important;
  }
  .mr-sp-95 {
    margin-right: 95px !important;
  }
  .pr-sp-95 {
    padding-right: 95px !important;
  }
  .ma-sp-95 {
    margin: 95px !important;
  }
  .pa-sp-95 {
    padding: 95px !important;
  }
  .mt-sp-100 {
    margin-top: 100px !important;
  }
  .pt-sp-100 {
    padding-top: 100px !important;
  }
  .ma-sp-100 {
    margin: 100px !important;
  }
  .pa-sp-100 {
    padding: 100px !important;
  }
  .mb-sp-100 {
    margin-bottom: 100px !important;
  }
  .pb-sp-100 {
    padding-bottom: 100px !important;
  }
  .ma-sp-100 {
    margin: 100px !important;
  }
  .pa-sp-100 {
    padding: 100px !important;
  }
  .ml-sp-100 {
    margin-left: 100px !important;
  }
  .pl-sp-100 {
    padding-left: 100px !important;
  }
  .ma-sp-100 {
    margin: 100px !important;
  }
  .pa-sp-100 {
    padding: 100px !important;
  }
  .mr-sp-100 {
    margin-right: 100px !important;
  }
  .pr-sp-100 {
    padding-right: 100px !important;
  }
  .ma-sp-100 {
    margin: 100px !important;
  }
  .pa-sp-100 {
    padding: 100px !important;
  }
  .mt-sp-110 {
    margin-top: 110px !important;
  }
  .pt-sp-110 {
    padding-top: 110px !important;
  }
  .ma-sp-110 {
    margin: 110px !important;
  }
  .pa-sp-110 {
    padding: 110px !important;
  }
  .mb-sp-110 {
    margin-bottom: 110px !important;
  }
  .pb-sp-110 {
    padding-bottom: 110px !important;
  }
  .ma-sp-110 {
    margin: 110px !important;
  }
  .pa-sp-110 {
    padding: 110px !important;
  }
  .ml-sp-110 {
    margin-left: 110px !important;
  }
  .pl-sp-110 {
    padding-left: 110px !important;
  }
  .ma-sp-110 {
    margin: 110px !important;
  }
  .pa-sp-110 {
    padding: 110px !important;
  }
  .mr-sp-110 {
    margin-right: 110px !important;
  }
  .pr-sp-110 {
    padding-right: 110px !important;
  }
  .ma-sp-110 {
    margin: 110px !important;
  }
  .pa-sp-110 {
    padding: 110px !important;
  }
  .mt-sp-120 {
    margin-top: 120px !important;
  }
  .pt-sp-120 {
    padding-top: 120px !important;
  }
  .ma-sp-120 {
    margin: 120px !important;
  }
  .pa-sp-120 {
    padding: 120px !important;
  }
  .mb-sp-120 {
    margin-bottom: 120px !important;
  }
  .pb-sp-120 {
    padding-bottom: 120px !important;
  }
  .ma-sp-120 {
    margin: 120px !important;
  }
  .pa-sp-120 {
    padding: 120px !important;
  }
  .ml-sp-120 {
    margin-left: 120px !important;
  }
  .pl-sp-120 {
    padding-left: 120px !important;
  }
  .ma-sp-120 {
    margin: 120px !important;
  }
  .pa-sp-120 {
    padding: 120px !important;
  }
  .mr-sp-120 {
    margin-right: 120px !important;
  }
  .pr-sp-120 {
    padding-right: 120px !important;
  }
  .ma-sp-120 {
    margin: 120px !important;
  }
  .pa-sp-120 {
    padding: 120px !important;
  }
}

.ma-0 {
  margin: 0 auto !important;
}

@media (max-width: 768px) {
  .ma-sp-0 {
    margin: 0 auto !important;
  }
}

.txt-light {
  font-weight: 300;
}

.txt-normal {
  font-weight: 400;
}

.txt-semi-bold {
  font-weight: 500;
}

.txt-bold {
  font-weight: 700;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}
