/* ==========================================================
 mainvisual
========================================================== */
/* c-mainvisual
---------------------------------------------------------- */
.c-mainvisual {
  position: relative;

  .bg {
    height: 300px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
      opacity: 0;
    }

    @include mq-sp {
      height: 105px;
    }
  }

  &.-short {
    @include mq-pc {
      .bg {
        height: 200px;
      }
    }
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .jp {
      display: block;
      margin-bottom: 15px;
      font-family: $yumin;
      font-size: 3.2rem;
      font-weight: bold;
      color: #fff;
      line-height: 1;
      letter-spacing: 0.08em;
      text-align: center;

      &.-white {
        color: #fff;
      }

      @include mq-sp {
        margin-bottom: 8px;
        font-size: 2.2rem;
      }
    }

    .en {
      display: block;
      font-family: $roboto;
      font-size: 24px;
      color: #009dd3;
      line-height: 1;
      letter-spacing: 0.08em;
      text-align: center;

      &.-white {
        color: #fff;
      }

      @include mq-sp {
        font-size: 1.2rem;
      }
    }
  }

  @include mq-pc {
    
  }
  
  @include mq-sp {
    
  }
}