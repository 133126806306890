/* nav-global
---------------------------------------------------------- */
.nav-global {
  background-color: $color-primary;

  @include mq-sp {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: -1;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    transition: all .2s linear;

    &.is-visible {
      z-index: 98;
      visibility: visible;
      opacity: 1;
    }
  }

  .list-lang {
    display: none;

    @include mq-sp {
      display: flex;

      .item {
        position: relative;
        width: 50%;
        border-bottom: 1px solid #008dbd;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 35px;
          background-color: #008dbd;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 55px;
        font-size: 1.5rem;
        font-weight: 500;
        color: #fff;
        text-align: center;
        letter-spacing: 0.1em;

        &.is-current {
          opacity: 0.5;
        }
      }
    }
  }

  .c-link-banner {

    @include mq-pc {
      a {
        position: relative;
        background-size: auto 100%;
        
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.2);
          transition: opacity .2s;
          opacity: 0;
        }

        &:hover {
          opacity: 1;

          &:before {
            opacity: 1;
          }
        }
      }

      small {
        font-size: 1.7rem;
        letter-spacing: 0.2em;
      }
    }
  }

  &.-expand {
    .list-gnav-sub-01, .list-gnav-sub-02 {
      display: block !important;
    }
  }
}

//list-nav-global
.list-nav-global {
  > .item {
    > a, > .btn-accd {
      display: flex;
      align-items: center;
      height: 65px;
      padding: 15px 0 15px 60px;
      background-position: 15px center;
      background-repeat: no-repeat;
      border-bottom: 1px solid #008dbd;
      font-size: 1.4rem;
      line-height: 1.4;
      color: #fff;
      transition: background-color 0.2s;

      &.ico-home {
        background-image: url('/img/common/ico_home.png');
      }
      &.ico-strength {
        background-image: url('/img/common/ico_strength.png');
      }
      &.ico-products {
        background-image: url('/img/common/ico_products.png');
      }
      &.ico-case {
        background-image: url('/img/common/ico_case.png');
      }
      &.ico-company {
        background-image: url('/img/common/ico_company.png');
      }
      &.ico-mail {
        background-image: url('/img/common/ico_mail.png');
      }
      &.ico-tel {
        background-image: url('/img/common/ico_tel.png');
        background-size: 27px auto;
      }

      // hover and current
      @include mq-pc {
        &:hover, &.is-current {     
          background-color: #007faa;
          border-color:#007299;

          &.ico-home {
            background-image: url('/img/common/ico_home_on.png');
          }
          &.ico-strength {
            background-image: url('/img/common/ico_strength_on.png');
          }
          &.ico-products {
            background-image: url('/img/common/ico_products_on.png');
          }
          &.ico-case {
            background-image: url('/img/common/ico_case_on.png');
          }
          &.ico-company {
            background-image: url('/img/common/ico_company_on.png');
          }
          &.ico-mail {
            background-image: url('/img/common/ico_mail_on.png');
          }
        }
      }

      @include mq-sp {
        display: flex;
        align-items: center;
        height: 80px;
        padding: 10px 0 10px 65px;
        background-position: 20px center;
        border-bottom: 2px solid #008dbd;
        font-size: 1.5rem;
        font-weight: 500;

        .tel {
          display: block;
          margin-bottom: 2px;
          font-family: $roboto;
          font-size: 2.6rem;
          font-weight: bold;
          letter-spacing: 0.08em;
        }

        .time {
          display: block;
          font-size: 1.3rem;
        }

        &.is-current {
          background-color: #fff;
          border-color: #e5e5e5;
          color: $color-primary;
        }
      }
    }
  }


  .list-gnav-sub-01 {
    display: none;
    position: relative;
    padding: 10px;

    @include mq-pc {
      margin-top: -1px; 
      background-color: #007faa;
      border-bottom: 1px solid #007299;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        width: 180px;
        height: 1px;
        background-color: #00739a;
      }
    }

    @include mq-sp {
      padding: 0;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 30px;
        height: 100%;
        background-color: #007faa;
      }
    }

    &.is-show {
      display: block;
    }

    > .item {
      position: relative;
      margin-bottom: 1px;

      @include mq-sp {
        margin-bottom: 0;
      }

      > a, > .btn-accd {
        position: relative;
        display: block;
        padding: 7px 10px;
        border-radius: 6px;
        font-size: 1.4rem;
        line-height: 1.3;
        color: #fff;
        transition: background-color .2s linear;

        @include mq-sp {
          display: flex;
          align-items: center;
          height: 80px;
          padding: 10px 0 10px 65px;
          background-position: 20px center;
          border-bottom: 2px solid #008dbd;
          border-radius: 0;
          font-size: 1.5rem;
          font-weight: 500;
        }

        @include mq-pc {
          &:hover, &.is-current {
            background-color: #005f80;
          }
        }

        &.is-current {
          @include mq-sp {
            background-color: #fff;
            border-color: #e5e5e5;
            color: $color-primary;
          }
        }
      }
    }
  }

  .list-gnav-sub-02 {
    display: none;
    margin: 1px 0;

    @include mq-sp {
      margin: 0;
      padding-left: 30px;
    }

    &.is-show {
      display: block;
    }

    .item {
      margin-bottom: 1px;

      &:last-child {
        margin-bottom: 0;
      }

      @include mq-sp {
        margin-bottom: 0;
      }
      a, .btn-accd {
        position: relative;
        display: block;
        padding: 7px 10px 7px 40px;
        border-radius: 6px;
        font-size: 1.4rem;
        line-height: 1.3;
        color: #fff;
        transition: background-color .2s linear;

        @include mq-sp {
          display: flex;
          align-items: center;
          height: 55px;
          padding-left: 45px;
          background-color: #32abda;
          border-radius: 0;
          border-bottom: 2px solid #199ed1;
          font-size: 1.5rem;
        }

        &:before {
          content: "";
          position: absolute;
          top: 10px;
          left: 20px;
          width: 13px;
          height: 13px;
          background: url('/img/common/ico_sub_lv2.png') 0 0 no-repeat;
          background-size: 100%;

          @include mq-sp {
            top: 20px;
            left: 20px;
          }
        }

        @include mq-pc {
          &:hover, &.is-current {
            background-color: #005f80;
          }
        }
      }

      @include mq-sp {
        &:last-child {
          a {
            border-bottom: 0;
          }
        }
      }
    }
  }
}