@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 button
========================================================== */
/* c-btn
---------------------------------------------------------- */
.c-btn {
  display: inline-block;
  position: relative;
  min-width: 200px;
  padding: 7px;
  border-radius: 5px;
  background-color: #009dd3;
  box-shadow: inset 0 -1px rgba(0,0,0,.35);
  color: #fff;
  text-align: center;
  line-height: 1.86;
  transition: background-color .2s linear;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 6px solid #fff;
  }

  &:hover {
    background-color: #2ac0f3;
    box-shadow: inset 0 -1px rgba(30, 176, 239, 1);
  }

  // gray
  &.-gray {
    background-color: #96a1a5;

    &:hover {
      background-color: #a2b6bd;
      box-shadow: inset 0 -1px #8b9fa6;
    }
  }

  // expanding
  &.-expanding {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
      border: 0;
      background: url('/img/common/ico_plus.png') 0 0 no-repeat;
      background-size: 100%;
    }
  }
}


/* c-btn-download
---------------------------------------------------------- */
.c-btn-download {
  a {
    display: block;
    padding: 15px;
    background-color: #2e3434;
    font-weight: bold;
    font-size: 1.4rem;
    color: #fff;
    @include is-opacity;

    @include mq-sp {
      padding: 25px 20px;
      border-bottom: 2px solid #232627;
      font-size: 1.5rem;
    }

    .text {
      position: relative;
      display: inline-block;
      padding-left: 20px;

      @include mq-sp {
        padding-left: 40px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: 0;
        width: 12px;
        height: 15px;
        background-image: url('/img/common/ico_document.png');
        background-position: center;
        background-size: 100% auto;
        background-repeat: no-repeat;

        @include mq-sp {
          top: 0;
          width: 20px;
          height: 26px;
        }
      }
    }
  }

  // -footer
  &.-footer {
    @include mq-sp {
      a {
        padding: 14px 20px;
        border-bottom: 1px solid #444849;
        font-size: 1.2rem;
        text-align: center;

        .text {
          padding-left: 18px;

          &:before {
            top: 4px;
            width: 12px;
            height: 15px;
          }
        }
      }
    }
  }
}