@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 text comp
========================================================== */
/* c-txt-read
---------------------------------------------------------- */
.c-txt-read {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
  
  @include mq-sp {
    margin-bottom: 20px;
  }
}

/* c-txt-small
---------------------------------------------------------- */
.c-txt-small {
  font-size: 1.2rem;
  line-height: 1.83;
}

/* c-txt-note
---------------------------------------------------------- */
.c-txt-note {
  position: relative;
  padding-left: 15px;
  font-size: 1.2rem;
  line-height: 1.83;

  &:before {
    position: absolute;
    content: "※";
    font-size: 12px;
    line-height: 1.83;
    top: 0;
    left: 0;
  }
}
