@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 footer
========================================================== */
.footer {
  position: relative;
  padding-bottom: 40px;
  background-color: #2f3334;
  overflow: hidden;

  @include mq-pc {
    min-width: 1280px;
  }

  @include mq-sp {
    padding-bottom: 20px;
  }
  
  // btn-pagetop
  .btn-pagetop {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 97;
    display: block;
    width: 80px;
    height: 80px;
    background-color: $color-primary;
    background-image: url('/img/common/ico_pagetop_01.png');
    background-position: center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    overflow: hidden;
    transition: all .2s linear;
    opacity: 0;
    visibility: hidden;

    &:hover {
      opacity: 0.7;
    }

    &.is-visible {
      visibility: visible;
      opacity: 1;
    }

    &.is-lock {
      position: absolute;
      bottom: inherit;
      top: 0;

      @include mq-sp {
        top: inherit;
        bottom: 0;
      }
    }

    @include mq-sp {
      width: 40px;
      height: 40px;
      background-size: 22px auto;
    }
  }

  // breakpoint
  .breakpoint {
    position: absolute;
    top: 80px;
    right: 1px;
    width: 1px;
    height: 1px;

    @include mq-sp {
      top: inherit;
      bottom: 1px;
    }
  }

  // footer-link
  .footer-link {
    padding: 28px 0 25px;
    border-bottom: 1px solid #3a3d3e;

    @include mq-sp {
      padding: 0;
      margin: 0;
      border-bottom: 0;
    }
  }

  // list-links
  .list-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;

    @include mq-sp {
      margin: 0;
    }

    .item {
      margin-top: 10px;
      margin-left: 40px;

      &:first-child {
        margin-left: 0;
      }

      @include mq-sp {
        width: 50%;
        margin: 0;
        border-bottom: 1px solid #3a3d3e;

        &:nth-child(odd) {
          border-right: 1px solid #3a3d3e;
        }

        &.-wfull {
          width: 100%;
          border-right: 0;
        }
      }
    }

    a {
      line-height: 1.9;
      color: #fff;
      transition: opacity .2s linear;

      &:hover {
        opacity: 0.7;
      }

      @include mq-sp {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: 1.2rem;
        text-align: center;
      }
    }
  }

  .c-link-banner {
    @include mq-pc {
      display: none;
    }
  }

  // logo
  .logo {
    padding-top: 35px;
    margin-bottom: 25px;
    line-height: 1;
    text-align: center;

    a {
      display: inline-block;
    }

    img {
      width: 230px;
    }

    @include mq-sp {
      padding-top: 30px;

      img {
        width: 208px;
      }
    }
  }

  // copyright
  .copyright {
    font-size: 1.2rem;
    line-height: 1;
    color: #fff;
    text-align: center;
    letter-spacing: 0.07em;

    @include mq-sp {
      padding: 0 40px;
      font-size: 1.2rem;
      line-height: 2.2;
    }
  }
}