@import '../_variables.scss';
@import '../_mixin.scss';

/* ==========================================================
 Products
========================================================== */
/* list-products-nav
---------------------------------------------------------- */
.list-products-nav {
  @include mq-pc {
    display: flex;
    flex-wrap: wrap;
    margin: -40px -20px 0;
  }
  
  .item {
    @include mq-pc {
      width: 33.33%;
      padding: 0 20px;
      margin-top: 40px;
    }

    @include mq-sp {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .image {
    img {
      width: 100%;
    }
  }

  .button {
    margin-top: -20px;
    margin-bottom: 15px;
    text-align: center;

    @include mq-sp {
      padding: 0 20px;
    }
  }
}

/* list-images-forgings
---------------------------------------------------------- */
.list-images-forgings {
  display: flex;
  justify-content: center;
  margin: -30px -20px 30px;

  &:last-child {
    margin-bottom: 0;
  }
  
  @include mq-sp {
    flex-wrap: wrap;
    margin: -16px -7px 16px;
  }

  .item {
    margin-top: 30px;
    padding: 0 20px;

    @include mq-sp {
      width: 50%;
      margin-top: 16px;
      padding: 0 7px;

      &:nth-child(1) {
        width: 68.7%;
      }

      &:nth-child(2) {
        width: 31.3%;
      }

      .c-box-image {
        img {
          width: 100%;
        }
      }

    }
  }

  .c-box-image {
    @include mq-pc {
      display: table;

      .caption {
        display: table-caption;
        caption-side: bottom;
      }
    }
  }
}

/* table-powders
---------------------------------------------------------- */
.table-powders {
  @include mq-sp {
    padding-bottom: 10px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 12px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: #dadada;
      border-radius: 10px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: #b8b8b8;
      border-radius: 10px;
    }
  }

  table {
    width: 100%;

    @include mq-sp {
      width: 980px;
    }

    thead {
      th {
        &:first-child {
          //width: 6%;
        }
      }
    }

    th, td {
      padding: 10px 14px;
      border: 1px solid #ccc;
      vertical-align: middle;
      text-align: center;
    }

    th {
      background-color: #eee;
    }

  }
}