@import '../../_variables.scss';
@import '../../_mixin.scss';

/* ==========================================================
 link
========================================================== */
/* c-link-banner
---------------------------------------------------------- */
.c-link-banner {
  a {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 7px;
    background-color: #e2e6eb;
    background-image: url('/img/common/bg_bnr.jpg');
    background-position: right center;
    background-repeat: no-repeat;
    transition: opacity .2s linear;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }

  .image {
    width: 64px;
    margin-right: 20px;

    img {
      width: 100%;
    }
  }
  .text {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.5;
    color: $color-primary;
  }
  @include mq-pc {
    
  }
  
  @include mq-sp {
    
  }
}

/* c-link-text
---------------------------------------------------------- */
.c-link-text {
  position: relative;
  color: #009dd3;
  transition: opacity .2s;

  &:hover {
    opacity: 0.7;
  }

  &.-newwindow {
    padding-right: 22px;

    &:before {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      width: 15px;
      height: 15px;
      background-image: url('/img/common/ico_new_window.png');
      background-size: 100% auto;
      background-position: 0 0;
      background-repeat: no-repeat;
    }
  }

  &.-underline {
    text-decoration: underline;
    
    &:hover {
      opacity: 1;
      text-decoration: none;
    }
  }
}
